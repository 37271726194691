<script lang="ts" setup>
withDefaults(
	defineProps<{
		type?: "slider_vertical" | "slider_horizontal";
		sectionName?: string;
		row?: number;
		title: string;
		path?: string;
		shadowEffect?: boolean;
	}>(),
	{
		sectionName: "",
		row: 1,
		shadowEffect: true
	}
);

defineEmits<{ (e: "viewAllAction"): void }>();

const { t } = useT();
</script>

<template>
	<div class="list-items-wrapper">
		<AText :modifiers="['bold']" :size="{ full: 72, md: 48 }">
			{{ title }}
		</AText>
		<AHorizontalScroll :shadowEffect="shadowEffect">
			<div :class="['box-items', sectionName]">
				<slot />
			</div>
		</AHorizontalScroll>
		<div v-if="path" class="action-wrapper">
			<NuxtLink :to="`${path}`" class="action-link">
				<AButton variant="secondary" class="btn" size="lg" :modifiers="['wide']">
					<AText :size="16" :modifiers="['medium']">
						{{ t("See All Games") }}
					</AText>
				</AButton>
			</NuxtLink>
		</div>
	</div>
</template>

<style lang="scss" scoped>
div.list-items-wrapper {
	display: flex;
	flex-direction: column;
	gap: 12px;
	overflow: hidden;
	padding: 0 16px;
	margin-left: -16px;
	margin-right: -16px;

	@include media-breakpoint-up(lg) {
		gap: 16px;
	}
}

.box-items {
	display: grid;
	grid-auto-flow: column dense;
	gap: 8px;
	grid-template-rows: repeat(v-bind(row), 1fr);

	@include media-breakpoint-up(md) {
		gap: 12px;
	}

	&:deep(img) {
		pointer-events: none;
	}
}

.action-wrapper {
	margin-top: 32px;
	display: flex;

	@include media-breakpoint-down(md) {
		margin-top: 24px;
	}

	button {
		@include media-breakpoint-up(sm) {
			max-width: 145px;
		}
	}

	.action-link {
		@include media-breakpoint-down(sm) {
			display: flex;
			width: 100%;
		}
	}
}
div.scroll-container {
	width: calc(100% + 32px);
	margin-left: -16px;
	margin-bottom: -16px;
	padding: 16px;
}
</style>
