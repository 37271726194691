<script setup lang="ts">
const rewardsRef = ref();
const { t } = useT();
const { isMobile } = useDevice();
const [isShowRewards, toggleRewards] = useToggle(false);
const { orientation } = useScreenOrientation();
const {
	inviteData,
	depositStreakEndTime,
	showDepositStreak,
	showInviteFriends,
	showTournaments,
	showGameOfWeek,
	showBingo,
	counterNumber,
	showDailyWheel,
	showPrizeDrops,
	showDailyLogin,
	activeOffers,
	showRewards,
	playerFreeSpin
} = useRewardsCenter();

const useMobilePortraitAnimation = computed(() =>
	isMobile && !orientation.value?.includes("landscape") ? "slide-mobile" : "slide"
);

onClickOutside(rewardsRef, () => {
	if (!isShowRewards.value) {
		return;
	}
	toggleRewards(false);
});
</script>

<template>
	<div class="rewards-action" @click="toggleRewards(true)">
		<ABadge variant="default" :size="20" class="gift-counter" background="var(--primary-500)">
			<AText :size="12" class="text-carabanchel" :modifiers="['semibold', 'condensed']">{{ counterNumber }}</AText>
		</ABadge>
		<NuxtImg
			src="/nuxt-img/rewards/screen-img.png"
			class="reward-img"
			alt="reward-cup"
			format="avif"
			loading="lazy"
			width="144"
			height="144"
		/>
		<AText :size="11" :modifiers="['center', 'semibold', 'nowrap', 'uppercase']">
			{{ t("rewardCenter.sidebar.title") }}
		</AText>
	</div>
	<AOverlay v-if="isShowRewards" :modifiers="['fixed']" :class="{ show: isShowRewards }" :hideOverflow="true">
		<Transition :name="useMobilePortraitAnimation" appear>
			<div ref="rewardsRef" class="rewards-aside">
				<div class="head">
					<AText :size="24" :modifiers="['semibold', 'capitalize']">
						{{ t("rewardCenter.sidebar.title") }}
						<AText :size="24" :modifiers="['semibold']" class="text-сirebon">{{ counterNumber }}</AText>
					</AText>
					<AIconButton class="close-btn" variant="ghost" @click="toggleRewards(false)">
						<NuxtIcon name="20/close-pop" filled />
					</AIconButton>
				</div>

				<div class="rewards-grid">
					<MRewardsOnboarding v-if="showRewards" @close-popper="toggleRewards(false)" />
					<template v-for="offer in activeOffers" :key="offer?.preset?.id">
						<MRewardsPromoOffer v-if="offer" :data="offer" @close-popper="toggleRewards(false)" />
					</template>
					<MRewardsDailyWheel v-if="showDailyWheel" @close-popper="toggleRewards(false)" />
					<MRewardsBingo v-if="showBingo" @close-popper="toggleRewards(false)" />
					<MRewardsGameOfWeek v-if="showGameOfWeek" @close-popper="toggleRewards(false)" />
					<MRewardsTournament v-if="showTournaments" @close-popper="toggleRewards(false)" />
					<MRewardsInviteFriends v-if="showInviteFriends" :data="inviteData" @close-popper="toggleRewards(false)" />
					<MRewardsDepositStreak
						v-if="showDepositStreak"
						:timeLeft="depositStreakEndTime"
						@close-popper="toggleRewards(false)"
					/>
					<MRewardsDailyLogin v-if="showDailyLogin" @close-popper="toggleRewards(false)" />
					<MRewardsFreeSpins v-if="playerFreeSpin" @close-popper="toggleRewards(false)" />
					<MRewardsPrizeDrops v-if="showPrizeDrops" @close-popper="toggleRewards(false)" />
				</div>
			</div>
		</Transition>
	</AOverlay>
</template>

<style lang="scss" scoped>
.rewards-action {
	position: fixed;
	padding: 4px;
	z-index: 20;
	right: 0;
	top: 50vh;
	border-radius: 16px 0px 0px 16px;
	width: 72px;
	height: 72px;
	background: var(--gradient-g-12);
	display: flex;
	align-items: flex-end;
	justify-content: center;

	@include media-breakpoint-down(lg) {
		@media (orientation: landscape) {
			top: initial;
			bottom: 135px;
		}
	}

	@include media-breakpoint-down(sm) {
		top: initial;
		bottom: 194px;
	}

	&:hover {
		cursor: pointer;
	}

	.gift-counter {
		position: absolute;
		top: -8px;
		left: 8px;
		z-index: 1;
	}

	.reward-img {
		position: absolute;
		left: calc(50% - 31px);
		bottom: 12px;
		width: 62px;
		height: auto;
		transition: all 0.5s ease;
		animation: scaleCup 1s ease-in-out infinite;
	}
}

.overlay {
	z-index: 101;
	display: none;
	width: 100%;
	background: var(--overlay-200);
	backdrop-filter: blur(6px);

	&.show {
		display: flex;
		justify-content: flex-end;

		@include media-breakpoint-down(sm) {
			align-items: flex-end;
		}
	}
}

.rewards-aside {
	background: var(--neutral-900);
	width: var(--o-sidebar-width);
	max-width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(sm) {
		max-height: 500px;
		height: 100%;
		width: 100%;
		border-radius: 16px 16px 0 0;
	}

	.head {
		display: flex;
		padding: 16px;
		gap: 8px;
		justify-content: space-between;
		align-items: center;
	}

	.rewards-grid {
		display: flex;
		flex-direction: column;
		overflow: auto;

		@include scrollbar(4px, 4px, var(--chifeng), transparent, var(--chifeng));
	}

	.close-btn .nuxt-icon {
		font-size: 24px;

		&:deep(svg) {
			margin-bottom: 0;
		}
	}
}

$transition: all 250ms ease-in-out;

.fade-enter-active,
.fade-leave-active {
	transition: $transition;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.slide-enter-active {
	transition: $transition;
	transform: translateX(100%);
}
.slide-enter-to {
	transform: translateX(0%);
}
.slide-leave-active {
	transition: $transition;
	transform: translateX(0%);
}
.slide-leave-to {
	transform: translateX(100%);
}

.slide-mobile-enter-active {
	transition: $transition;
	transform: translateY(100%);
}
.slide-mobile-enter-to {
	transform: translateY(0%);
}
.slide-mobile-leave-active {
	transition: $transition;
	transform: translateY(0%);
}
.slide-mobile-leave-to {
	transform: translateY(100%);
}

@keyframes scaleCup {
	0%,
	100% {
		transform: scale(1) translateY(0);
	}
	50% {
		transform: scale(1.15) translateY(-3px);
	}
}
</style>
