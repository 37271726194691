<script setup lang="ts">
const emit = defineEmits<{ (event: "closePopper"): void }>();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { t } = useT();
const { open, close } = useAppModals();
const prepareImgUrl = useImage();
const { handleOpenGame } = useOpenGame(open);
const route = useRoute();
const error = useError();
const isGuest = useIsGuest();
const { gameOfWeek } = useHomePage({ immediate: false });
const imagePattern = `url(${prepareImgUrl(
	`${baseImageUrl}${gameOfWeek.value?.image2x || gameOfWeek.value?.image || ""}`,
	{ format: "avif", width: 86, height: 100, loading: "lazy" }
)})`;

const handleClick = async () => {
	dispatchGAEvent({
		event: "click_button",
		location: "reward_cabinet",
		button_name: "play"
	});

	if (route.query.game) {
		navigateTo(route.path, { replace: true });
		close("LazyOModalGame");
	}

	if (error.value && "statusCode" in error.value && error.value?.statusCode === 404) {
		clearError({ redirect: "/issues/all-games/" });
		await navigateTo({
			path: "/issues/all-games/",
			query: { game: gameOfWeek.value?.slug, isMoney: !isGuest.value ? "true" : undefined }
		});
	}

	emit("closePopper");
	handleOpenGame(gameOfWeek.value?.slug);
};
</script>

<template>
	<MRewardsWrapper
		class="game-week-reward"
		:button-text="t('rewardCenter.gameWeek.btn.name')"
		:title="t('rewardCenter.gameWeek.title')"
		:intro="gameOfWeek?.bannerTitle ?? ''"
		@click="handleClick"
	>
		<template #img>
			<div class="image-wrapper" />
		</template>
	</MRewardsWrapper>
</template>

<style lang="scss" scoped>
.game-week-reward {
	&:deep(img) {
		width: 44px;
		height: 48px;
		object-fit: cover;
		object-position: 50% 5%;
		border-radius: 4px;
	}

	.image-wrapper {
		width: 43px;
		height: 48px;
		border-radius: 4px;
		background-image: v-bind(imagePattern);
		background-size: cover;
		background-position: center top;
		background-repeat: no-repeat;
	}
}
</style>
