<script lang="ts" setup>
const { t } = useT();
const { open } = useAppModals();
const loginGuard = useLoginGuard();
const isGuest = useIsGuest();
const { isAvailable } = useDailyLogin({ immediate: false });

const handleClick = () => {
	loginGuard({
		success: () => {
			open("LazyOModalDailyLogin");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<div class="wrapper">
		<NuxtImg
			class="background"
			src="/nuxt-img/daily-login/banner-daily-login.png"
			alt="banner-wheel"
			quality="100"
			format="webp"
			loading="lazy"
		/>
		<div class="content">
			<AText variant="tempe" :modifiers="['semibold']">
				<span>{{ t("dailyLogin.banner.name") }}</span>
			</AText>
			<AText variant="taipei" :modifiers="['semibold']" class="label">
				<span>{{ t("dailyLogin.banner.subtitle") }}</span>
			</AText>
			<AAnimationCustomByScroll
				v-if="isAvailable && !isGuest"
				animationClass="blink"
				transitionDelay="2s"
				class="btn-wrapper"
			>
				<AButton variant="primary" class="btn" size="lg" @click="handleClick">
					<AText variant="toledo" :modifiers="['medium']">
						{{ t("dailyLogin.banner.action") }}
					</AText>
				</AButton>
			</AAnimationCustomByScroll>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.wrapper {
	width: 100%;
	height: 50%;
	border-radius: 24px;
	border: 2px solid var(--cordoba);
	display: flex;
	padding: 24px;
	position: relative;
	overflow: hidden;
}

.content {
	max-width: 320px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	position: relative;
	z-index: 1;
}

.label {
	margin-top: 4px;
}

.background {
	height: 100%;
	min-width: 100%;
	position: absolute;
	right: 0;
	bottom: 0;
}

.wheel-container {
	position: absolute;
	top: 0;
	right: 0;
	width: 190px;
	height: 100%;
}

.btn-wrapper {
	margin-top: auto;
}

.btn {
	width: 165px;
}

.quest-link {
	margin: 0 auto;

	&:hover {
		cursor: pointer;
	}
}
</style>
