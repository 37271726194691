<script lang="ts" setup>
import type { HomeBanners } from "~/types";

const { t } = useT();
const { promotions } = useHomePage();
const { open } = useAppModals();

defineProps<{ banner: HomeBanners[number] }>();

const invitePromotion = computed(() => promotions.value?.filter((item) => item.type === "invite")?.[0] || {});
const handleClick = () => {
	const referralUrl = invitePromotion.value.data?.referralUrl;
	if (referralUrl) {
		open("LazyOModalInviteFriends", { referralUrl });
	}
	dispatchGAEvent({ event: "click_button", location: "promotions", buttonName: "copy" });
};
</script>
<template>
	<MBanner
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:bg-color="banner.background"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		:type="banner.type"
		@click="handleClick"
	>
		<template #default>
			<AText class="banner-title" variant="tuzla" :modifiers="['uppercase']">
				<p v-html="banner.title" />
			</AText>
		</template>
		<template #description>
			<div v-if="invitePromotion?.data?.prizeCoins" class="app-banner__desc-money app-banner__desc-money--coins">
				<NuxtImg class="app-banner__desc-ico app-banner__desc-ico--coins" src="/nuxt-img/banners/coins.png" />
				<div class="app-banner__sum app-banner__sum--coins">
					<AText variant="torreon" :modifiers="['bold']">{{
						shortNumberFormatter(invitePromotion?.data?.prizeCoins as number, { withPrefix: true })
					}}</AText>
				</div>
			</div>
			<div v-if="invitePromotion?.data?.prizeEntries" class="app-banner__desc-money app-banner__desc-money--entries">
				<NuxtImg class="app-banner__desc-ico app-banner__desc-ico--entries" src="/nuxt-img/banners/entries.png" />
				<div class="app-banner__sum app-banner__sum--entries">
					<AText variant="torreon" :modifiers="['bold', 'uppercase']">
						{{ shortNumberFormatter(invitePromotion?.data?.prizeEntries as number, { fractionDigits: 2 }) }}
					</AText>
				</div>
			</div>
		</template>
		<template #actions>
			<AButton variant="primary" class="app-banner__btn">
				<NuxtIcon name="20/share-icon" filled class="share-icon" />
				<AText variant="tempe" :modifiers="['bold', 'uppercase']">{{ t("refer a friend") }}</AText>
			</AButton>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped>
.share-icon {
	font-size: 20px;
}
</style>
