<script lang="ts" setup>
import {
	OBannersRefferal,
	OBannersQuest,
	OBannersWeekGame,
	OBannersPresetPackage,
	OBannersBox,
	OBannersFlipTheCoin,
	OBannersPrizeDrops
} from "#components";

import type { KeenSliderOptions, KeenSliderInstance } from "keen-slider";

import type { HomeBanner } from "@/types";

defineProps<{
	isLoading?: boolean;
}>();

const bannersList: Record<string, unknown> = {
	bannerPresetPackage: OBannersPresetPackage,
	weekGame: OBannersWeekGame,
	referral: OBannersRefferal,
	quest: OBannersQuest,
	magicBox: OBannersBox,
	flipTheCoin: OBannersFlipTheCoin,
	prizeDrops: OBannersPrizeDrops
} as const;

const { banners } = useHomePage({ immediate: false });

const interval = ref();
const sliderActiveSlide = ref(0);
const opacities = ref<number[]>([]);

const sliderRefHome = ref();

const autoplay = (slider: KeenSliderInstance) => {
	clearInterval(interval.value);
	interval.value = setInterval(() => {
		if (slider.track.details) {
			slider.track.details.maxIdx === slider.track.details.abs ? slider.moveToIdx(0) : slider.next();
		}
	}, 10000);
};

const sliderOptions: KeenSliderOptions<{ autoplay?: { delay: number } }> = {
	loop: true,
	selector: ".fader__slide",
	defaultAnimation: {
		duration: 1000
	},
	created(slider: KeenSliderInstance) {
		autoplay(slider);
	},
	slideChanged(slider: KeenSliderInstance) {
		autoplay(slider);
		sliderActiveSlide.value = slider.track.details.rel;
	},
	detailsChanged: (slider: KeenSliderInstance) => {
		opacities.value = slider?.track?.details?.slides.map((slide) => slide.portion) || [1, 0, 0, 0];
	}
};

watch(
	() => banners.value.length,
	() => {
		nextTick(() => {
			setTimeout(() => {
				if (sliderRefHome.value && sliderRefHome.value.slider) {
					sliderRefHome.value.slider.update();
					sliderRefHome.value.slider.moveToIdx(0);
				}
			}, 50);
		});
	}
);

onBeforeUnmount(() => {
	clearInterval(interval.value);
});
</script>
<template>
	<div class="slider__wrapper">
		<ASlider ref="sliderRefHome" fade-mode :options="sliderOptions">
			<template #default>
				<div
					v-for="(banner, index) in banners"
					:key="index"
					:class="['fader__slide', { single: banners.length < 2, hide: sliderActiveSlide !== index }]"
					:style="{ opacity: opacities[index] }"
				>
					<component
						:is="bannersList[banner?.type]"
						v-if="banner?.type in bannersList"
						:banner="banner"
						:class="{ 'event-none': opacities[index] <= 0 }"
					/>
					<OBannersHero v-else :banner="banner as HomeBanner" />
				</div>
			</template>
		</ASlider>
		<div v-if="banners.length > 1" class="pagination">
			<div
				v-for="(_slide, idx) in banners.length"
				:key="idx"
				:class="['pagination-item', { 'is-active': sliderActiveSlide === idx }]"
				@click="sliderRefHome.slider?.moveToIdx(idx)"
			/>
		</div>
	</div>
</template>
<style scoped lang="scss">
.event-none {
	pointer-events: none;
}
.slider__wrapper {
	height: 751px;
	width: 100%;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 60px;
		left: 0;
		bottom: 0;
		background: var(--gombe);
		z-index: 2;
	}

	@include media-breakpoint-down(sm) {
		margin-bottom: gutter(3.2);
		height: 232px;
	}

	&:deep {
		.fader {
			height: 100%;

			@include media-breakpoint-down(sm) {
				overflow: visible;
			}

			.app-banner {
				height: 100%;
			}
		}
	}
}
.keen-slider__slide {
	width: 100%;
}

.fader__slide {
	flex-shrink: 0;
	width: 100%;
	position: absolute;
	top: 0;
	height: 100%;
	transform: none !important;

	&.single {
		opacity: 1 !important;
		height: 100%;
	}

	&.hide {
		display: none;
	}

	@include media-breakpoint-down(sm) {
		height: 100%;

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			display: block;
			width: 100%;
			height: 50px;
			background: linear-gradient(0deg, var(--carabanchel) 0%, var(--chengzihe) 100%);
			z-index: 1;
		}
	}
}

.pagination {
	display: flex;
	justify-content: center;
	gap: gutter(1);
	margin-top: gutter(1);
	padding: 0 gutter(2);

	@include media-breakpoint-down(sm) {
		margin-top: 230px;
	}

	&-item {
		width: 28px;
		height: 4px;
		border-radius: 12px;
		background: var(--celaya);
		cursor: pointer;

		&.is-active {
			background: var(--сirebon);
		}
	}
}
</style>
