<script lang="ts" setup>
import type { WidgetFeature } from "~/types";

const isGuest = useIsGuest();
const { data: appInitData } = useAppInitData();
const { data: bonusesData } = useBonusesData();
const { isAvailable, isActiveDailyLogin } = useDailyLogin();
const { tournamentData, tournamentExist } = useTournamentData();
const { moneyBoxIsActive, depositStreakIsActive, prizeDropsIsActive, bingoLotteryIsActive, dailyLoginIsActive } =
	useFeaturesIsActive();

const featuresPriority = computed(() => appInitData.value?.featuresPriority?.onMainPage);
const bannerPromo = computed(() => appInitData.value?.popupPresetPackage);
const isShowWheel = computed(
	() =>
		appInitData.value?.isShowDailyWheelForGuest &&
		bonusesData.value?.dailyWheel?.available &&
		bonusesData.value?.dailyWheel?.reasonType !== "time"
);
const isShowDailyLogin = computed(
	() => (isActiveDailyLogin.value && isAvailable.value) || (isGuest.value && dailyLoginIsActive.value)
);

const widgetList = computed<Record<string, WidgetFeature>>(() => ({
	dailyWheel: { component: "MWheelMobNavBar", isActive: isShowWheel.value },
	moneyBox: { component: "MPiggyBankMobNavBar", isActive: moneyBoxIsActive.value },
	dailyLogin: { component: "ODailyLoginMobNavBar", isActive: isShowDailyLogin.value },
	depositStreak: { component: "ODepositStreakMobNavBar", isActive: depositStreakIsActive.value },
	prizeDrops: { component: "OPrizeDropsMobNavBar", isActive: prizeDropsIsActive.value },
	bingo: { component: "OBingoMobNavBar", isActive: bingoLotteryIsActive.value },
	tournament: {
		component: "MTournamentsMobNavBar",
		isActive: tournamentExist.value,
		props: { title: tournamentData.value?.title || "" }
	},
	bestDeal: {
		component: "MPromotionMobNavBar",
		isActive: !!bannerPromo.value && bannerPromo.value.promoOfferPreset?.bestDeal,
		props: { offer: bannerPromo.value }
	},
	mostPopular: {
		component: "MPromotionMobNavBar",
		isActive: !!bannerPromo.value && bannerPromo.value.promoOfferPreset?.mostPopular,
		props: { offer: bannerPromo.value }
	}
}));

const currenComponent = computed(
	() => featuresPriority.value?.find((item) => !!widgetList.value[item] && widgetList.value[item].isActive) || ""
);
</script>
<template>
	<component
		:is="widgetList[currenComponent].component"
		v-if="currenComponent in widgetList"
		v-bind="widgetList[currenComponent]?.props || {}"
	/>
</template>
