import type { RewardsData } from "@/types";

const useCheckRewards = () => {
	const { emailRewards, phoneRewards, profileRewards } = useCheckRewardsData();

	const rewardsData = computed(() => ({ email: emailRewards, phone: phoneRewards, profile: profileRewards }));
	const rewardsAmount = computed(() => {
		const value = Object.keys(rewardsData.value).reduce(
			(acc, key) => {
				const rewardKey = key as keyof RewardsData;
				const reward = rewardsData.value[rewardKey];

				if (!reward.value?.completed && ((reward.value?.coins || 0) > 0 || (reward.value?.entries || 0) > 0)) {
					return {
						coins: acc.coins + (rewardsData.value[rewardKey]?.value?.coins || 0),
						entries: acc.entries + (rewardsData.value[rewardKey]?.value?.entries || 0),
						activeCount: (acc.activeCount += 1)
					};
				}
				return acc;
			},
			{ coins: 0, entries: 0, activeCount: 0 }
		);

		return {
			coins: numberFormatWithPrefix(value.coins),
			entries: shortNumberFormatter(value.entries, { fractionDigits: 2 }),
			activeCount: value.activeCount
		};
	});

	return { rewardsData, rewardsAmount };
};

export default useCheckRewards;
