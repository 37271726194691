<script setup lang="ts">
const emit = defineEmits<{ (event: "closePopper"): void }>();

const localPath = useLocalePath();
const { t } = useT();
const { durationLeftNextStarted, prizesLeftStill } = usePrizeDrop();

const navigateToPrizeDrops = () => {
	navigateTo(localPath("/prize-drops/"));

	emit("closePopper");
};
</script>

<template>
	<MRewardsWrapper
		:button-text="'Open'"
		:title="'Prize Drops'"
		:intro="t('rewardCenter.prizeDrops.prizesCount', { prizes: numberFormat(prizesLeftStill || 0) })"
		image="/nuxt-img/rewards/prize-drops.png"
		@click="navigateToPrizeDrops"
	>
		<template #timeBox>
			<i18n-t keypath="rewardCenter.prizeDrops.timer">
				<template #timer>
					{{ durationLeftNextStarted }}
				</template>
			</i18n-t>
		</template>
	</MRewardsWrapper>
</template>
