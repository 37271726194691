const prepareRandomColor = () => {
	const baseColors = [
		"#5CFDFD",
		"#FD5C8C",
		"#F3FF6F",
		"#FF29C4",
		"#46FF6E",
		"#BC0303",
		"#008079",
		"#D2A400",
		"#640066"
	];
	const shuffledColors = baseColors.slice(0, baseColors.length - 1).sort(() => Math.random() - 0.5);
	return shuffledColors[0];
};

export default prepareRandomColor;
