<script lang="ts" setup>
import type { WidgetFeature } from "~/types";

const isGuest = useIsGuest();
const { item } = useRecentGamesWinners();
const { data: appInitData } = useAppInitData();
const { isMobile } = useDevice();
const { pageData, recentWinners, jackpotGames, lastGames, yourPick, seoData } = useHomePage();

useHead({
	title: seoData.value?.seoTitle || "Jackpot Rabbit",
	meta: [
		{
			name: "description",
			content: seoData.value?.seoDescription
		},
		{
			name: "keywords",
			content: seoData.value?.seoKeywords || ""
		}
	]
});

const refFavorites = ref(recentWinners);

const featuresPriority = computed(() => appInitData.value?.featuresPriority?.onMainPage);
const isShowBingo = computed(() => !isGuest.value && appInitData.value?.bingo?.isActive);

const widgetList = computed<Record<string, WidgetFeature>>(() => ({
	dailyLogin: { component: "OHomeBannerDailyLogin", isActive: true },
	depositStreak: {
		component: "OHomeBannerDepositStreak",
		isActive: !isGuest.value && appInitData.value?.depositStreak?.isActive
	},
	dailyWheel: { component: "OHomeBannerWheel", isActive: false },
	prizeDrops: {
		component: "OHomeBannerPrizeDrops",
		isActive: !isGuest.value && appInitData.value?.prizeDrops?.isActive
	},
	bingo: { component: "OHomeBannerBingo", isActive: isShowBingo.value },
	tournament: {
		component: "OHomeBannerTournament",
		isActive: true
	}
}));
const activeFeatures = computed(() =>
	featuresPriority.value?.filter((item) => !!widgetList.value[item] && widgetList.value[item].isActive).slice(0, 2)
);

if (process.client) {
	watch(
		item,
		(value) => {
			refFavorites.value?.data?.unshift(value);
		},
		{ deep: true }
	);
}
</script>

<template>
	<NuxtLayout>
		<div class="box-banners">
			<OBanners />

			<div class="banners-wrapper">
				<template v-for="(feature, index) in activeFeatures" :key="index">
					<component :is="widgetList[feature].component" />
				</template>
			</div>
		</div>

		<LazyOOpportunity class="box-opportunity" />

		<LazyOHomeSectionGames :itemSection="0" />

		<OLazyComponent
			v-if="!isGuest"
			id="box-rewards"
			class="box-rewards"
			:width="isMobile ? 191 : 209"
			:items-count="isMobile ? 4 : 6"
		>
			<template #skeleton-title>
				<ASkeleton width="100%" :height="isMobile ? '112px' : '194px'" class="skeleton-section-title" />
			</template>
			<LazyOHomeBannerRewards />
			<template #skeleton-actions>
				<ASkeleton width="182px" height="48px" class="skeleton-section-actions" />
			</template>
		</OLazyComponent>

		<LazyOHomeSectionGames v-if="(lastGames && lastGames.length) || (yourPick && yourPick.length)" :itemSection="1" />

		<OLazyComponent
			id="box-all-games"
			class="box-all-games"
			:width="isMobile ? 191 : 209"
			:items-count="isMobile ? 12 : 24"
			:rows="isMobile ? 6 : 4"
		>
			<template #skeleton-title>
				<ASkeleton width="100%" :height="isMobile ? '210px' : '314px'" class="skeleton-section-title" />
			</template>
			<LazyOHomeOurGames />
			<template #skeleton-actions>
				<ASkeleton width="182px" height="48px" class="skeleton-section-actions" />
			</template>
		</OLazyComponent>

		<LazyOHomeSectionGames :itemSection="2" />

		<OLazyComponent
			v-if="refFavorites.data && refFavorites.data.length"
			id="box-recent-winners"
			class="box-recent-winners"
			:class="{ 'box-recent-winners-logged-in': !isGuest }"
			prerender
		>
			<template #skeleton-title>
				<ASkeleton width="100%" :height="isMobile ? '112px' : '194px'" class="skeleton-section-title" />
			</template>
			<LazyORecentGames :games="refFavorites.data || []" />
			<template #skeleton-actions>
				<ASkeleton width="182px" height="48px" class="skeleton-section-actions" />
			</template>
		</OLazyComponent>

		<OLazyComponent
			v-if="!isGuest"
			id="box-promotions"
			class="box-promotions"
			:height="420"
			:width="isMobile ? 400 : 319"
			:items-count="4"
			:rows="isMobile ? 4 : 1"
		>
			<template #skeleton-title>
				<ASkeleton width="100%" :height="isMobile ? '112px' : '194px'" class="skeleton-section-title" />
			</template>
			<LazyOHomePromotions />
			<template #skeleton-actions>
				<ASkeleton width="182px" height="48px" class="skeleton-section-actions" />
			</template>
		</OLazyComponent>

		<LazyOHomeSectionGames v-if="!isGuest" :itemSection="3" />

		<OLazyComponent
			id="box-collections"
			class="box-collections"
			:width="isMobile ? 400 : 319"
			:height="isMobile ? 348 : 348"
			:items-count="4"
			:rows="isMobile ? 4 : 1"
		>
			<template #skeleton-title>
				<ASkeleton width="100%" :height="isMobile ? '112px' : '194px'" class="skeleton-section-title" />
			</template>
			<OHomeCollections />
			<template #skeleton-actions>
				<ASkeleton width="182px" height="48px" class="skeleton-section-actions" />
			</template>
		</OLazyComponent>

		<LazyOHomeSectionGames :itemSection="4" />

		<div class="box-jackpot-games">
			<OLazyComponent id="box-jackpot-games" class="jackpot-widget" :height="isMobile ? 671 : 458">
				<LazyOHomeJackpot :game-mode="appInitData?.gameMode" :jackpots="pageData?.jackpots" :games="jackpotGames" />
			</OLazyComponent>
			<OLazyComponent
				v-if="!!appInitData?.moneyBox?.isActive"
				id="box-piggy-bank"
				:height="458"
				:width="!isMobile && 328"
			>
				<LazyOHomeBannerPiggyBank />
			</OLazyComponent>
		</div>

		<LazyOHomeSectionGames :itemSection="5" />

		<div class="delimiter" />
	</NuxtLayout>
</template>

<style lang="scss" scoped>
.skeleton-section-title {
	margin-bottom: 32px;

	@include media-breakpoint-down(sm) {
		margin-bottom: 22px;
	}
}

.skeleton-section-actions {
	margin-top: 32px;
}

.swiper-slide {
	width: 207px;

	@include media-breakpoint-down(md) {
		width: 164px;
		padding-right: gutter(1.25);
	}
}

.section-title {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: gutter(4);
	gap: 0 gutter(2);
	position: relative;
	padding: 0 gutter(2);

	@include media-breakpoint-down(md) {
		margin-bottom: gutter(2);
	}
}

.games-list__item {
	width: 193px;
	min-height: 134px;
}

.slider {
	&-mobile {
		display: none;
		@include media-breakpoint-down(md) {
			display: block;
		}
	}
	&-desktop {
		display: block;
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}

.box-banners {
	width: 1312px;
	max-width: calc(100% - 32px);
	margin: gutter(2) auto 0;
	display: flex;
	justify-content: space-between;
	gap: gutter(2);

	@media (orientation: landscape) and (max-width: 992px) {
		flex-direction: column;
	}

	@include media-breakpoint-down(sm) {
		flex-direction: column;
		max-width: 100%;
		margin-top: 0;
	}

	&:deep {
		.slider__wrapper {
			margin-bottom: 0;
			border-radius: 24px;
			border: 2px solid var(--cordoba);
			width: 800px;
			height: 458px;

			.fader__slide {
				border-radius: 24px;
			}

			@include media-breakpoint-down(lg) {
				width: 100%;
			}

			&::before {
				content: none;
			}

			@include media-breakpoint-down(sm) {
				border: none;
				border-radius: 0;
				height: 316px;
				margin-bottom: 248px;
				overflow: visible;
			}

			.app-banner {
				@include media-breakpoint-down(sm) {
					width: 100%;
					height: 100%;
					background-position: 0 50%;
					border-radius: 0;
				}
			}
		}
	}
}

.banners-wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
	flex-grow: 2;

	@include media-breakpoint-down(lg) {
		display: none;
	}
}

.box-favorites {
	width: 1312px;
	max-width: calc(100% - 32px);
	margin: 96px auto 0;
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(md) {
		margin-top: 60px;
	}
}

.box-all-games {
	width: 1312px;
	max-width: calc(100% - 32px);
	margin: 64px auto;
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(md) {
		margin-top: gutter(5);
	}
}

.box-jackpot-games {
	width: 1312px;
	max-width: calc(100% - 32px);
	display: flex;
	gap: 16px;
	margin: 64px auto;

	@include media-breakpoint-down(lg) {
		@media (orientation: landscape) {
			flex-direction: column;
			margin: 32px auto;
		}
	}

	@include media-breakpoint-down(md) {
		flex-direction: column;
		margin: 32px auto;
	}

	.jackpot-widget {
		flex-grow: 1;
	}
}

.box-promotions {
	width: 1312px;
	max-width: calc(100% - 32px);
	margin: 64px auto;
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(md) {
		margin: 32px auto;
	}
}

.box-collections {
	width: 1312px;
	max-width: calc(100% - 32px);
	margin: 64px auto;

	@include media-breakpoint-down(md) {
		margin: 32px auto;
	}
}

.box-rewards {
	width: 1312px;
	max-width: calc(100% - 32px);
	display: flex;
	flex-direction: column;
	margin: 0 auto;
}

.delimiter {
	display: block;
	width: 100%;
	height: 64px;
}
</style>
