<script setup lang="ts">
import type { Promotion } from "@/types";

defineProps<{
	finishedAt: string;
	image: string;
	type?: Promotion["type"];
}>();

const { t } = useT();
const localPath = useLocalePath();
const { open } = useAppModals();

const handleClick = () => {
	navigateTo(localPath("/issues/all-games/"));
};

const handleHowItWorksModal = () => {
	open("LazyOModalPrizeDropsRules");
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 632,
			height: 784,
			src: image,
			loading: 'lazy'
		}"
		width="632px"
		height="784px"
		class="card-prize-drops"
		@click="handleClick"
	>
		<template #top>
			<div class="badge-wrapper">
				<ABadge class="free" background="var(--carabanchel)" variant="info" autosize>
					<AText variant="topeka" class="badge text-coro" :modifiers="['semibold', 'uppercase']">
						{{ t("prizeDrops.promo.badge.name") }}
					</AText>
				</ABadge>

				<ABadge class="label-time" background="var(--carabanchel)" variant="info" autosize>
					<AText class="badge text-cannes" :size="12" :modifiers="['semibold', 'uppercase', 'nowrap']">
						{{ t("prizeDrops.promo.timer.end", { time: finishedAt }) }}
					</AText>
				</ABadge>
			</div>
		</template>
		<template #bottom>
			<div class="box">
				<span class="btn-info" @click.stop="handleHowItWorksModal">
					<NuxtIcon name="20/info-pop" class="icon" filled />
				</span>
				<AText class="title" :size="16" :modifiers="['semibold']">
					{{ t("prizeDrops.name") }}
				</AText>
				<AText class="text-neutral-100">
					{{ t("prizeDrops.promo.subtitle") }}
				</AText>
				<AButton class="action" variant="primary" size="lg">
					<AText :size="16" :modifiers="['capitalize', 'medium']">
						{{ t("prizeDrops.banner.btn.choose") }}
					</AText>
				</AButton>
			</div>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.badge-wrapper {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	gap: 8px;
}

.card-prize-drops {
	color: var(--coro);
	background: var(--graz);
	border: 2px solid var(--celaya);
	border-radius: 16px;
	overflow: visible;
	width: 316px;
	height: 392px;

	&:deep(> img) {
		margin: auto;
		border-radius: 14px;
	}

	.badge {
		height: 26px;
		display: flex;
		align-items: center;
		padding-right: 4px;
		padding-left: 4px;
		box-shadow: none;
		overflow: hidden;

		&.free {
			border: 2px solid var(--success-500);
			& > span {
				color: var(--success-500);
			}
		}
	}

	.title {
		padding: 2px 0;
	}
}

.badge.label-time {
	border: 2px solid var(--neutral-800);
}

.box {
	position: absolute;
	left: calc(50% - 142px);
	bottom: -28px;
	border-radius: 16px;
	border: 2px solid var(--cordoba);
	background: var(--overlay-200);
	backdrop-filter: blur(45px);
	padding: 12px 11px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
	width: 284px;
	height: auto;
}

.action {
	margin-top: 8px;
}

.btn-info {
	position: absolute;
	z-index: 2;
	cursor: pointer;
	top: 10px;
	right: 10px;

	.icon {
		font-size: 20px;

		&:deep(svg path) {
			fill: var(--ciampea);
		}
	}
}
</style>
