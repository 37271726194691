const useWindowClient = () => {
	const isMobileClient = useMediaQuery("(max-width: 767px)");
	const isDesktopClient = useMediaQuery("(min-width: 768px)");
	const isLandscape = useMediaQuery("(orientation: landscape)");
	const isLgDesktopClient = useMediaQuery("(min-width: 1024px)");

	return {
		isMobileClient,
		isDesktopClient,
		isLandscape,
		isLgDesktopClient
	};
};

export default useWindowClient;
