import type { PromoData, Promo } from "@/types";

const useRewardsCenter = () => {
	const { data: appInitData } = useAppInitData();
	const { data: bonusesData } = useBonusesData();
	const { tournamentData } = useRace();
	const { rewardsAmount } = useCheckRewards();
	const { promotionsData, gameOfWeek } = usePromotionsData({ immediate: false });
	const { isAvailable } = useDailyLogin({ immediate: false });
	const { durationLeft: depositStreakEndTime } = useRabbitDepositStreak({ immediate: false });

	const activeOffers = computed(() => {
		const filteredOffers = promotionsData.value.promotions?.reduce<PromoData[]>((acc, { data }) => {
			if (
				(data?.promoOfferPreset?.subType || data?.promoOfferPreset?.mostPopular || data?.promoOfferPreset?.bestDeal) &&
				data
			) {
				acc.push(data);
			}
			return acc;
		}, []);

		return filteredOffers?.sort((paymentA, paymentB) => Number(paymentB?.money) - Number(paymentA?.money));
	});

	const inviteData = computed(() => promotionsData.value?.promotions?.find((data) => data?.type === "invite") as Promo);

	const showDailyWheel = computed(() => !!bonusesData.value?.dailyWheel?.visible);
	const showBingo = computed(() => !!appInitData.value?.bingo?.isActive);
	const showGameOfWeek = computed(() => !!gameOfWeek.value?.id);
	const showTournaments = computed(() => !!tournamentData.value?.data?.isActive);
	const showInviteFriends = computed(() => !!inviteData.value?.available);
	const showPrizeDrops = computed(() => !!appInitData.value?.prizeDrops?.isActive);
	const showDailyLogin = computed(() => !!appInitData.value?.dailyLogin?.isActive && !!isAvailable.value);
	const showDepositStreak = computed(() => !!appInitData.value?.depositStreak?.isActive);
	const showRewards = computed(() => !!rewardsAmount.value.activeCount);
	const playerFreeSpin = computed(() =>
		promotionsData.value?.promotions?.find((promoItem) => promoItem?.type === "playerFreeSpin")
	);

	const counterNumber = computed(() => {
		const activeFeatureList = [
			showDailyWheel.value,
			showBingo.value,
			showGameOfWeek.value,
			showTournaments.value,
			showInviteFriends.value,
			showPrizeDrops.value,
			showDailyLogin.value,
			showDepositStreak.value,
			rewardsAmount.value.activeCount,
			playerFreeSpin.value
		];
		const activeCount = activeFeatureList?.filter(Boolean)?.length;
		const offerCount = activeOffers.value?.length || 0;

		return activeCount + offerCount;
	});

	return {
		inviteData,
		depositStreakEndTime,
		showInviteFriends,
		showDepositStreak,
		showTournaments,
		showGameOfWeek,
		showBingo,
		activeOffers,
		showDailyWheel,
		showPrizeDrops,
		showDailyLogin,
		counterNumber,
		showRewards,
		playerFreeSpin
	};
};

export default useRewardsCenter;
