import numberFormat from "./numberFormat";

const numberFormatToK = (number: number) => {
	if (number < 1000) {
		return numberFormat(number);
	}

	if (number >= 1000000) {
		const formattedNumber = number / 1000;
		return `${numberFormat(formattedNumber)}K`;
	}

	return numberFormat(number);
};

export default numberFormatToK;
