<script setup lang="ts">
const emit = defineEmits<{ (event: "closePopper"): void }>();

const { t } = useT();
const { open } = useAppModals();
const { all } = useGamesState();
const freeSpins = useFreeSpins();
const { handleOpenGame } = useOpenGame(open);

const freeSpinGame = computed(() => all.value?.[Number(freeSpins.value.gameId ?? 0)]);

const handleClickReward = () => {
	handleOpenGame(freeSpinGame.value.slug);
	emit("closePopper");
};
</script>

<template>
	<MRewardsWrapper
		:button-text="t('depositFreeSpinsBonus.rewards.btn')"
		:title="t('depositFreeSpinsBonus.rewards.title', { amount: freeSpins.spinsRemain })"
		:intro="t('depositFreeSpinsBonus.rewards.intro', { game: freeSpinGame?.title })"
		image="/nuxt-img/rewards/freespins.webp"
		@click="handleClickReward"
	/>
</template>
