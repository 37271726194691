<script setup lang="ts">
const emit = defineEmits<{
	(event: "togglePiggyBankBar"): void;
	(event: "close"): void;
}>();

defineProps<{
	isActive?: boolean;
}>();

const { open } = useAppModals();
const { state, piggyData, resetState } = usePiggy({
	open
});

const vipPiggybankRef = ref();

const progressValue = computed(() => {
	const curr = Number(piggyData.value?.moneyBoxAmount ?? 0);
	const max = Number(piggyData.value?.maxAmount ?? 0);
	if (curr >= max) {
		return 0;
	}

	if (curr < max) {
		return 314.159 - ((100 / max) * curr || 2) * Math.PI;
	}

	return 307;
});

const togglePiggyBank = () => {
	emit("togglePiggyBankBar");
};

const closePiggyBank = () => {
	emit("close");
};

onClickOutside(
	vipPiggybankRef,
	() => {
		emit("close");
	},
	{ detectIframe: true }
);

onUnmounted(() => {
	resetState();
});
</script>

<template>
	<div ref="vipPiggybankRef" :class="['piggy-dropdown', { 'is-active': isActive }]">
		<div class="piggybank-task-progress" @click="togglePiggyBank">
			<NuxtImg
				src="/nuxt-img/piggy-bank/piggy-bank-logo.png"
				class="piggybank-icon"
				width="80"
				height="80"
				loading="lazy"
				format="webp"
			/>
			<NuxtIcon name="40/progress" filled />

			<AAnimationJittery class="gift-animation">
				<ABadge
					v-if="state?.betAnimation && state?.prizeAmount"
					variant="info"
					autosize
					class="gift-label"
					background="var(--curico)"
				>
					<AText :size="10" :modifiers="['uppercase', 'semibold', 'nowrap']">
						+ SC {{ Number(state?.prizeAmount) }}
					</AText>
				</ABadge>
			</AAnimationJittery>
		</div>

		<Transition name="slide">
			<div v-show="isActive" class="dropdown">
				<OPiggyBankProgressCard @close="closePiggyBank" />
			</div>
		</Transition>
	</div>
</template>

<style lang="scss" scoped>
$color-1: #1c1f00;

.piggy-dropdown {
	position: relative;
	display: inline-flex;

	.piggybank-task-progress {
		position: relative;
		border-radius: 50%;
		width: 38px;
		height: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: var(--neutral-950);
		box-shadow: 0px 1px 2px 0px var(--cukai);
		cursor: pointer;

		.nuxt-icon {
			display: inline-flex;
			position: absolute;
			top: -1px;
			left: -1px;
			font-size: 40px;

			&:deep(svg) {
				margin: 0;

				.progress-circle {
					stroke-dashoffset: v-bind(progressValue);
					stroke: var(--curico);
				}
			}
		}

		.gift-animation {
			position: absolute;
			bottom: -4px;
		}

		.gift-label {
			color: $color-1;
			display: inline-flex;
			border-radius: 30px;
			min-height: 20px;
		}
	}

	.piggybank-icon {
		width: 20px;
		height: 20px;
		object-fit: cover;
	}

	.dropdown {
		display: flex;
		width: 346px;
		gap: 8px;
		position: absolute;
		top: 56px;
		left: calc(50% - 173px);
		z-index: 1001;

		@include media-breakpoint-down(lg) {
			@media (orientation: landscape) {
				position: fixed;
				left: 70px;
				top: 10px;
			}
		}

		@include media-breakpoint-down(md) {
			position: fixed;
			top: 60px;
			left: 16px;
		}
	}
}

.slide-enter-active,
.slide-leave-active {
	transition: all 0.3s ease-out;
}
.slide-enter-from,
.slide-leave-to {
	opacity: 0;
	transform: translateY(-40px);
}
</style>
