<script setup lang="ts">
import dayjs, { Dayjs } from "dayjs";

import type { Promotion } from "@/types";

const { t } = useT();
const loginGuard = useLoginGuard();
const isGuest = useIsGuest();
const { open } = useAppModals();

const props = defineProps<{
	isAvailable: boolean;
	type?: Promotion["type"];
	image: string;
	title: string;
	complete?: number;
	goal?: number;
	minPromoCost?: string;
	date: string | number | Dayjs;
	isFullStatus: boolean;
}>();

const buttonContent = computed(() => {
	if (isGuest.value) {
		return t("Try now");
	}
	return props.isAvailable ? t("Collect Now for $", { msg: props.minPromoCost }) : t("Play & Feed Piggy");
});

const subTitleContent = computed(() =>
	props.isFullStatus ? t("It's full - you can collect free SC!") : t("Earn extra coins, easy!")
);
const titleContent = computed(() => (props.isFullStatus ? t("Break Your Piggy Bank!") : props.title));

const emit = defineEmits<{ (event: "onCountDownStop"): void; (event: "chooseOffer"): void }>();

const onCountDownStop = () => {
	emit("onCountDownStop");
};

const onChooseOffer = () => {
	loginGuard({
		success: () => {
			if (props.isAvailable && props.date) {
				return;
			}
			emit("chooseOffer");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleHowItWorksModal = () => {
	loginGuard({
		success: () => {
			open("LazyOModalPromoHowItWorks", { type: props.type });
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const { durationLeft, reset } = useCountdown({
	timestamp: props.date ?? "",
	formatToken: dayjs(props.date ?? "").diff(dayjs(), "d") >= 1 ? "DD[ d ] HH[ h ]mm[ m ]" : "HH[ h ]mm[ m ]ss[ s ]",
	isOptimized: true,
	onCountdownStop: onCountDownStop
});

watch(
	() => props.date,
	(value) => {
		if (value) {
			reset(value);
		}
	}
);
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 316,
			height: 392,
			src: image
		}"
		width="316px"
		height="392px"
		class="card-piggy-bank"
		@click="onChooseOffer"
	>
		<template #top>
			<div class="badge-wrapper">
				<ABadge class="free" background="var(--carabanchel)" variant="info" autosize>
					<AText variant="topeka" class="badge text-coro" :modifiers="['semibold', 'uppercase']">
						{{ t("Free activity") }}
					</AText>
				</ABadge>

				<ABadge v-if="date" class="label-time" background="var(--carabanchel)" variant="info" autosize>
					<AText variant="topeka" class="badge text-cannes" :modifiers="['semibold', 'uppercase']">
						{{ t("Break In:") }} {{ durationLeft }}
					</AText>
				</ABadge>
			</div>
		</template>
		<template #bottom>
			<div class="box">
				<span class="btn-info" @click.stop="handleHowItWorksModal">
					<NuxtIcon name="20/info-pop" class="icon" filled />
				</span>
				<AText class="title" variant="toledo" :modifiers="['semibold']">
					{{ titleContent }}
				</AText>
				<AText class="info-txt" as="p" variant="tempe" :modifiers="['normal']">
					{{ subTitleContent }}
				</AText>

				<template v-if="!isGuest">
					<MPrizeFund
						v-if="isFullStatus"
						variant="entries"
						img="/nuxt-img/prizes/supercoins@2x.png"
						:icon-size="22"
						:offset="0.5"
					>
						<AText :size="24" as="h4" :modifiers="['bold']">
							{{ shortNumberFormatter(goal ?? 0, { fractionDigits: 2 }) }}
						</AText>
						<AText :size="10" :modifiers="['semibold', 'uppercase']">
							<i18n-t keypath="Free {br} super coins">
								<template #br>
									<br />
								</template>
							</i18n-t>
						</AText>
					</MPrizeFund>
					<MPiggyBankProgressBar
						v-else
						class="progress"
						:complete="shortNumberFormatter(complete, { fractionDigits: 2 }) ?? 0"
						:goal="shortNumberFormatter(goal, { fractionDigits: 2 }) ?? 0"
					/>
				</template>

				<AButton size="lg" variant="primary" class="promo">
					<AText variant="toledo" :modifiers="['medium']">
						{{ buttonContent }}
					</AText>
				</AButton>
			</div>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.badge-wrapper {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	gap: 8px;
}

.card {
	color: var(--coro);
	background: var(--graz);
	border: 2px solid var(--celaya);
	border-radius: 16px;
	overflow: visible;
	&:deep(> img) {
		margin: auto;
		border-radius: 14px;
	}
	.badge {
		height: 26px;
		display: flex;
		align-items: center;
		padding-right: gutter(0.5);
		padding-left: gutter(0.5);
		box-shadow: none;
		white-space: nowrap;
		overflow: hidden;

		&.free {
			border: 2px solid var(--cuautitlan);
			& > span {
				color: var(--cuautitlan);
			}
		}
	}

	&:deep(.prize) {
		justify-content: center;
	}
	.title {
		padding: gutter(0.25) 0;
	}
}

.label-time {
	border: 2px solid var(--chifeng);
}

.box-count {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: gutter(0.5);
	border-radius: 5px 5px 0 0;
	background: var(--cagli);
	height: 45px;
	margin-bottom: gutter(-1.5);
	margin-top: gutter(0.25);

	.nuxt-icon {
		font-size: 20px;
		transform: translateY(-2px);
	}
}

.box {
	position: absolute;
	left: calc(50% - 142px);
	bottom: -28px;
	border-radius: 16px;
	border: 2px solid var(--cordoba);
	background: var(--czestochowa);
	backdrop-filter: blur(45px);
	padding: 12px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
	width: 284px;
	height: auto;
	gap: 8px;
}

.btn-info {
	position: absolute;
	z-index: 2;
	cursor: pointer;
	top: 10px;
	right: 10px;

	.icon {
		font-size: 20px;

		&:deep(svg path) {
			fill: var(--ciampea);
		}
	}
}
</style>
