<script setup lang="ts">
const { t } = useT();

const props = defineProps<{
	gameId?: string;
}>();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { all } = useGamesState();
const freeSpins = useFreeSpins();

const freeSpinGame = computed(() => all.value?.[Number(props.gameId ?? 0)]);
const imageSrcPath = computed(() => `${baseImageUrl}${freeSpinGame.value?.img || "/nuxt-img/card-games/pattern.webp"}`);
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 632,
			height: 784,
			src: '/nuxt-img/daily-login/promo-free.webp',
			loading: 'lazy'
		}"
		width="632px"
		height="784px"
		class="card-daily-login"
	>
		<template #top>
			<div class="badge-wrapper">
				<ABadge class="free" background="var(--neutral-950)" variant="info" autosize>
					<AText class="badge text-coro" :size="12" :modifiers="['semibold', 'uppercase']">
						{{ t("dailyLogin.promo.txt.activity") }}
					</AText>
				</ABadge>
			</div>
			<NuxtImg
				:src="imageSrcPath"
				class="free-game"
				width="420"
				height="468"
				alt="free plays prize"
				format="avif"
				loading="lazy"
			/>
		</template>
		<template #bottom>
			<div class="box">
				<AText class="title" :size="16" :modifiers="['semibold']">
					{{ t("dailyLogin.promoFree.title") }}
				</AText>
				<MPrizeFund
					class="grand"
					variant="entries"
					img="/nuxt-img/daily-login/freespins.png"
					:icon-size="40"
					:offset="0.5"
				>
					<AText :size="32" :modifiers="['bold', 'uppercase']">
						{{ freeSpins.spinsRemain }}
					</AText>
					<AText :modifiers="['semibold', 'uppercase']" class="free-spin">
						{{ t("dailyLogin.promoFree.txt.free") }}
					</AText>
				</MPrizeFund>

				<AText class="game-title" :modifiers="['normal', 'center']">
					<i18n-t keypath="dailyLogin.modal.freeGame">
						<template #name>{{ freeSpinGame?.title }}</template>
					</i18n-t>
				</AText>

				<AButton size="lg" variant="primary" class="btn">
					<AText :size="16" :modifiers="['medium']">
						{{ t("dailyLogin.promoFree.btn.play") }}
					</AText>
				</AButton>
			</div>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.badge-wrapper {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	gap: 8px;
}

.card-daily-login {
	color: var(--neutral-50);
	background: var(--gray-800);
	border: 2px solid var(--cordoba);
	border-radius: 16px;
	overflow: visible;
	width: 316px;
	height: 392px;
	position: relative;

	&:deep(> img) {
		margin: auto;
		border-radius: 14px;
	}

	.free-game {
		width: 210px;
		height: 234px;
		border-radius: 12px;
		border: 2px solid var(--neutral-800);
		position: absolute;
		top: 45px;
		left: 50%;
		transform: translateX(-50%);
	}

	.badge {
		height: 26px;
		display: flex;
		align-items: center;
		padding-right: 4px;
		padding-left: 4px;
		box-shadow: none;
		white-space: nowrap;
		overflow: hidden;

		&.free {
			border: 2px solid var(--success-500);

			& > span {
				color: var(--success-500);
			}
		}
	}

	.title {
		margin-bottom: 8px;
	}
}

.game-title {
	max-width: 260px;
	margin: 4px 0 8px;
}

.grand {
	display: flex;
	align-items: center;
	gap: 4px;
	margin-bottom: 4px;

	.icon {
		width: 24px;
		height: 24px;
	}

	.free-spin {
		max-width: 44px;
		text-shadow: 0 4px 4px var(--coronel);
		line-height: 16px;
	}
}

.box {
	position: absolute;
	left: calc(50% - 142px);
	bottom: -28px;
	border-radius: 16px;
	border: 2px solid var(--cordoba);
	background: var(--overlay-300);
	backdrop-filter: blur(45px);
	padding: 12px 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 284px;
	height: auto;
}

.btn {
	width: 100%;
}
</style>
