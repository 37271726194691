<script setup lang="ts">
import type { InitDataBanner } from "~/types";

const props = defineProps<{ banner: InitDataBanner }>();

const { t } = useT();
const { data: appInit } = useAppInitData();

const textColor = computed(() => props.banner?.promoOfferPreset?.bannerTextColor || "var(--cannes)");

const handleBannerClick = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "deposit",
		location: "Your Personal Offer",
		quantity: appInit.value?.depositsCount ?? 0
	});
	dispatchGAEvent({
		event: "click_button",
		button_name: "deposit",
		location: "slider",
		step: "view_payments_info"
	});
	window?.$cash?.$store?.dispatch?.("cash/setOfferType", "promoOffer");
	window?.$cash?.$store?.dispatch?.("cash/setOfferId", props.banner?.promoOfferPreset?.id);
	window?.$cash?.$router?.push?.(`/cash/deposit-by-money/${props.banner?.promoOfferPreset?.id}/promoOffer/`);
};
</script>

<template>
	<MBanner
		class="offer"
		:image="banner?.promoOfferPreset?.image2x"
		:image-mobile="banner?.promoOfferPreset?.imageMobile"
		:second-image="banner?.promoOfferPreset?.secondImage2x"
		:second-image-mobile="banner?.promoOfferPreset?.secondImageMobile"
		:bg-img="banner?.promoOfferPreset?.imageBanner"
		:bg-img2x="banner?.promoOfferPreset?.imageBannerMobile"
		type="bannerPresetPackage"
		@click="handleBannerClick"
	>
		<template #default>
			<AText class="title" variant="taylor">
				<span v-if="banner?.promoOfferPreset?.title" v-html="banner.promoOfferPreset.title" />
			</AText>
		</template>
		<template #description>
			<div class="info">
				<AText v-if="banner?.promoOfferPreset?.coins" variant="tunja" :modifiers="['bold']" class="money">
					{{ t(" Your Special Offer") }}
				</AText>
				<AText
					v-if="banner?.promoOfferPreset?.coins"
					variant="tunja"
					:modifiers="['bold']"
					class="money text-cheremushki"
				>
					{{ shortNumberFormatter(banner?.promoOfferPreset?.coins, { withPrefix: true }) }}
					<AText
						v-if="banner?.promoOfferPreset?.coins && banner?.promoOfferPreset?.entries"
						variant="tunja"
						:modifiers="['bold']"
						class="money text-cannes"
					>
						{{ t("and") }}
					</AText>
				</AText>
				<AText
					v-if="banner?.promoOfferPreset?.entries"
					variant="tunja"
					:modifiers="['bold', 'uppercase']"
					class="money text-curico"
				>
					{{ shortNumberFormatter(banner?.promoOfferPreset?.entries, { fractionDigits: 2 }) }}
					{{ t("SC") }}
				</AText>
			</div>
		</template>
		<template #actions>
			<div class="actions">
				<AAnimationCustomByScroll animationClass="blink" transitionDelay="1s">
					<AButton variant="primary" size="xl" class="button">
						<AText variant="toledo" :modifiers="['medium']" class="">
							{{ t("Buy now") }}
						</AText>
						<AText v-if="banner?.usualPrice" variant="toledo" :modifiers="['medium']" class="text-coyoacan">
							<s>${{ banner.usualPrice }}</s>
						</AText>

						<AText variant="toledo" :modifiers="['medium']">${{ banner?.money }}</AText>
					</AButton>
				</AAnimationCustomByScroll>
			</div>
		</template>
	</MBanner>
</template>

<style scoped lang="scss">
.value-decimal {
	position: relative;
	font-size: 14px;
	vertical-align: super;
	top: 0;
	left: 2px;

	@include media-breakpoint-down(sm) {
		font-size: 11px;
	}
}
:deep {
	.app-banner {
		&__content {
			width: 100%;
			justify-content: flex-start;
			align-items: flex-start;
			padding: 32px 24px;

			@include media-breakpoint-down(sm) {
				height: 372px;
				top: 200px;
				padding: 24px 12px;
				align-items: flex-start;
				width: calc(100% - 32px);
				margin: 0 auto;
				border-radius: 16px;
				border: 2px solid var(--chifeng);
				background: var(--czestochowa);
				backdrop-filter: blur(10px);
			}

			.app-banner__desc span {
				@include media-breakpoint-down(sm) {
					font-size: 32px;
				}
			}
		}
		&__desc {
			margin-top: gutter(0.5);
		}
	}
}

.title {
	line-height: 28px;
}

.info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: gutter(0.5);
	color: v-bind(textColor);
	text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

	@include media-breakpoint-down(sm) {
		gap: 0;
	}
}

.money {
	display: flex;
	align-items: center;
	gap: gutter(1);
	line-height: 1.16;

	@include media-breakpoint-down(sm) {
		margin-top: 0;
		justify-content: center;
	}

	@media (max-width: 360px) {
		font-size: 20px;
	}
}

.actions {
	display: flex;
	flex-direction: column;
	margin-top: 32px;
	align-items: center;

	@media (max-width: 480px) {
		width: 100%;
	}

	.button {
		padding: 0 24px;
		gap: 4px;

		@media (max-width: 480px) {
			width: 100%;
		}
	}
}

.price {
	display: flex;
	align-items: center;
	color: v-bind(textColor);
}
</style>
