type Prizes = {
	coins: number;
	entries: number;
	freeSpinCount: number;
	gameId: number;
};

const useDailyLogin = ({
	immediate = true,
	formatTimer = "HH[:]mm[:]ss"
}: { immediate?: boolean; formatTimer?: string } = {}) => {
	const { open } = useAppModals();
	const { all } = useGamesState();
	const { handleOpenGame } = useOpenGame(open);
	const { isSweepStakes, handleSwitchGameMode } = useSwitchMode();
	const { data: appInit } = useAppInitData();
	const {
		data: dailyLoginData,
		grandPrize,
		handleApplyPrize,
		refresh
	} = useDailyLoginData({ immediate: immediate && !!appInit.value?.dailyLogin?.isActive });

	const finishedAt = computed(() => dailyLoginData.value?.finishedAt || "");
	const isActiveDailyLogin = computed(() => !!dailyLoginData.value?.isActive);
	const isAvailable = computed(() => !!dailyLoginData.value?.isAvailable);
	const levels = computed(() => dailyLoginData.value?.levels || 7);
	const activeLevel = computed(() => dailyLoginData.value?.activeLevel || 1);
	const prizes = computed(() => dailyLoginData.value?.prizes);
	const isConfirmPhone = computed(() => dailyLoginData.value?.reasonType === "phone");

	const currentPrize = computed(() => prizes.value?.[activeLevel.value - 1] as Prizes);
	const isLastPrize = computed(() => levels.value === activeLevel.value);
	const isCompleted = computed(() => !isAvailable.value && isLastPrize.value);
	const totalPrizes = computed(() => {
		const initialTotals = { coins: 0, entries: 0, freeSpinCount: 0, gameId: 0 };

		return (
			prizes.value?.reduce<Prizes>((acc, item) => {
				for (const key of Object.keys(acc) as (keyof Prizes)[]) {
					acc[key] += Number(item?.[key] ?? 0);
				}

				return acc;
			}, initialTotals) ?? initialTotals
		);
	});
	const freeSpinGame = computed(() => all.value?.[totalPrizes.value.gameId]);

	const handleOpenFreeSpinGame = (gameId: number) => {
		if (!isSweepStakes.value) {
			handleSwitchGameMode();
		}

		const freeSpinGame = all.value?.[gameId];

		handleOpenGame(freeSpinGame?.slug || "");
	};

	const { durationLeft, reset } = useCountdown({
		timestamp: finishedAt.value,
		formatToken: formatTimer,
		isOptimized: true,
		onCountdownStop: () => refresh()
	});

	if (process.client) {
		watch(
			() => finishedAt.value,
			(value) => {
				reset(value || "", formatTimer);
			}
		);
	}

	return {
		dailyLoginData,
		isActiveDailyLogin,
		durationLeft,
		isAvailable,
		levels,
		activeLevel,
		isCompleted,
		grandPrize,
		isLastPrize,
		totalPrizes,
		currentPrize,
		prizes,
		freeSpinGame,
		isConfirmPhone,
		handleApplyPrize,
		handleOpenFreeSpinGame,
		refresh
	};
};

export default useDailyLogin;
