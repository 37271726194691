<script lang="ts" setup>
import type { Opportunity } from "@/types";

const { open } = useAppModals();
const isGuest = useIsGuest();
const router = useRouter();
const { t } = useT();

const { collections } = useHomePage();

const { homePromotions } = usePromotionsData();

const opportunity = computed<Opportunity[]>(() => [
	{
		icon: "oportunity-games",
		type: "games",
		class: "games",
		link: "/issues/all-games/",
		title: t("Games"),
		isNew: true,
		list: [],
		showActiveMark: true
	},
	{
		icon: "oportunity-promotions",
		type: "promotions",
		class: "promotions",
		link: "/promotions/",
		title: t("Promotions"),
		isNew: false,
		list: homePromotions?.value?.promotions || [],
		showActiveMark: true
	},
	{
		icon: "oportunity-tournaments",
		type: "tournaments",
		class: "tournaments",
		link: "/tournaments/",
		title: t("Tournaments"),
		isNew: false,
		list: [],
		showActiveMark: false
	},
	{
		icon: "oportunity-collections",
		type: "collections",
		class: "collections",
		link: "/issues/collections/",
		title: t("Collections"),
		isNew: false,
		list: collections?.value || [],
		showActiveMark: true
	}
]);

const handleClick = (opportunity: Opportunity) => {
	if (isGuest.value && ["promotions", "tournaments"].includes(opportunity.type)) {
		open("LazyOModalSignup");
		return;
	}
	router.push(opportunity.link);
};
</script>

<template>
	<div class="oportunity">
		<div v-for="(item, i) in opportunity" :key="i" :class="`oportunity__item ${item.class}`" @click="handleClick(item)">
			<div :class="`overlay ${item.class}`">
				<div class="oportunity__icon">
					<NuxtIcon :name="`48/${item.icon}`" class="icon" filled />
				</div>
				<AText :size="24" :modifiers="['semibold']">
					<p class="oportunity__title">{{ item.title }}</p>
				</AText>
				<AText v-if="item.showActiveMark" :size="24" :modifiers="['semibold']" class="text-primary-500">
					<p class="oportunity__title">
						{{ item.type === "games" ? t("opportunity.countGames") : item.list.length }}
					</p>
				</AText>
				<ABadge v-if="item.isNew" class="oportunity__badge" variant="info" autosize background="var(--neutral-950)">
					<AText :size="12" :modifiers="['semibold', 'uppercase']" class="text-coro">{{ t("New") }}</AText>
				</ABadge>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.oportunity {
	position: relative;
	z-index: 3;
	width: 1312px;
	max-width: calc(100% - 32px);
	margin: 24px auto 0;
	display: flex;
	justify-content: space-between;
	gap: 8px;

	@media (max-width: 1150px) {
		flex-wrap: wrap;
	}

	@include media-breakpoint-down(md) {
		margin: 50px auto 0;
	}

	&__item {
		position: relative;
		width: 25%;
		display: flex;
		align-items: center;
		height: 72px;
		border-radius: 16px;
		padding: 2px;
		overflow: hidden;

		@media (max-width: 1150px) {
			width: calc(50% - 4px);
		}

		@include media-breakpoint-down(md) {
			width: 100%;
		}

		&:hover {
			cursor: pointer;
		}

		&:before {
			content: "";
			display: block;
			position: absolute;
			left: -15px;
			width: 37px;
			height: 8px;
			transform: rotate(-90deg);
			border-bottom-right-radius: 7px;
			border-bottom-left-radius: 7px;
		}

		&.collections {
			background: linear-gradient(103deg, var(--social-facebook-hover) 0%, #185add00 100%);

			&:before {
				background: var(--social-facebook-hover);
				box-shadow:
					0px 0px 20px var(--social-facebook-hover),
					0px 0px 20px var(--social-facebook-hover);
			}
		}

		&.tournaments {
			background: linear-gradient(103deg, #ffaa00 0%, #ffaa0000 100%);

			&:before {
				background: var(--warning-400);
				box-shadow:
					0px 0px 20px var(--warning-400),
					0px 0px 20px var(--warning-400);
			}
		}

		&.games {
			background: linear-gradient(103deg, var(--primary-500) 0%, #eaff0700 100%);

			&:before {
				background: var(--primary-500);
				box-shadow:
					0px 0px 20px var(--primary-500),
					0px 0px 20px var(--primary-500);
			}
		}

		&.promotions {
			background: linear-gradient(103deg, var(--tertiary-500) 0%, #c20ed200 100%);

			&:before {
				background: var(--tertiary-500);
				box-shadow:
					0px 0px 20px var(--tertiary-500),
					0px 0px 20px var(--tertiary-500);
			}
		}
	}

	.overlay {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 0 12px;
		width: 100%;
		height: 100%;
		border-radius: 15px;

		&.collections {
			background: linear-gradient(103deg, rgba(24, 91, 221, 0.3) 8.13%, rgba(24, 91, 221, 0) 89.81%), #1c141f;
		}

		&.tournaments {
			background: linear-gradient(103deg, rgba(255, 168, 0, 0.3) 8.13%, rgba(255, 168, 0, 0) 89.81%),
				linear-gradient(0deg, #1c141f 0%, #1c141f 100%), #003302;
		}

		&.games {
			background: linear-gradient(103deg, rgba(235, 255, 7, 0.3) 8.13%, rgba(235, 255, 7, 0) 89.81%),
				linear-gradient(0deg, #1c141f 0%, #1c141f 100%), #520e00;
		}

		&.promotions {
			background: linear-gradient(103deg, rgba(194, 14, 210, 0.3) 8.13%, rgba(194, 14, 210, 0) 89.81%), #1c141f;
		}
	}

	&__icon {
		width: 40px;
		height: 40px;

		:deep(svg) {
			font-size: 40px;
		}
	}
	&__badge {
		--a-badge-info-border-width: 2px;
		padding: 8px 12px !important;

		border-radius: 999px;
		border: 2px solid var(--neutral-800) !important;
	}
	&__arrow {
		font-size: 30px;
		margin-left: auto;
	}
}
</style>
