<script setup lang="ts">
import type { OfferPreset } from "@/types";

const props = defineProps<{
	offer: OfferPreset;
}>();

const { t } = useT();
const isGuest = useIsGuest();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { data: appInit } = useAppInitData();

const openCashDeposit = (offer: OfferPreset) => {
	if (isGuest.value) {
		open("LazyOModalSignup");
		return;
	}
	const url = offer?.promoOfferPreset
		? `/cash/deposit-by-money/${offer.promoOfferPreset.id}/promoOffer/`
		: `/cash/deposit-by-money/${offer.preset.id}/preset/`;

	window?.$cash?.$router?.push?.(url);

	window?.$store?.gaCash.clickButton({
		location: "quick_buy",
		buttonName: "Buy"
	});

	if (offer.promoOfferPreset) {
		dispatchGAEvent({
			event: "deposit",
			location: "quick_buy",
			offer: offer?.type || "",
			package: offer?.money || "",
			playerId: appInit.value?.login || "",
			quantity: appInit.value?.depositsCount || 0,
			step: "view_payments_window",
			type: "banner",
			offer_id: offer?.promoOfferPreset?.id || ""
		});
	}
};

const isPresetPackage = computed(() => !!props.offer?.promoOfferPreset);
const isBestDeal = computed(() => props.offer?.promoOfferPreset?.bestDeal);
const isMostPopular = computed(() => props.offer?.promoOfferPreset?.mostPopular);
const badge = computed(() => parseJSON(props.offer?.promoOfferPreset?.badgeLabel || "{}"));
const coins = computed(() =>
	isPresetPackage.value ? props.offer?.promoOfferPreset?.coins : props.offer?.preset?.coins
);
const entries = computed(() =>
	isPresetPackage.value ? props.offer?.promoOfferPreset?.entries : props.offer?.preset?.entries
);
const usualPrice = computed(() => props.offer?.promoOfferPreset?.usualPrice);
const isShowBadgeLabel = computed(
	() => (isBestDeal.value || isMostPopular.value) && badge?.value?.percent && badge?.value?.text
);

const offerWrapper = ref(null);
onMounted(() => {
	if (props.offer?.promoOfferPreset) {
		const { stop } = useIntersectionObserver(
			offerWrapper,
			([{ isIntersecting }]) => {
				if (isIntersecting) {
					dispatchGAEvent({
						event: "offer",
						action: "offer_view",
						offer_name: props.offer?.type || "",
						offer_id: props.offer?.promoOfferPreset?.id || "",
						location: "quick_buy",
						type: "banner",
						playerId: appInit.value?.login || ""
					});
					stop();
				}
			},
			{ rootMargin: "-100px" }
		);
	}
});
</script>

<template>
	<div
		ref="offerWrapper"
		:class="[
			'item',
			{
				'item--best-deal': isBestDeal,
				'item--most-popular': isMostPopular
			}
		]"
		@click="openCashDeposit(offer)"
	>
		<div class="item__data">
			<ABadge
				v-if="isBestDeal || isMostPopular"
				:background="isBestDeal ? 'var(--custom-c2)' : 'var(--cabo)'"
				variant="info"
				autosize
			>
				<AText :size="12" class="text-cannes" :modifiers="['semibold', 'uppercase']">
					{{ isBestDeal ? t("Best deal") : t("Most popular") }}
				</AText>
			</ABadge>

			<div v-if="isShowBadgeLabel" class="item-badge flex-center">
				<NuxtImg
					:src="`${baseImageUrl}${offer?.promoOfferPreset?.imageBadge}`"
					alt="package"
					format="avif"
					width="44"
					height="44"
					loading="lazy"
				/>

				<div class="item-badge-content">
					<AText :size="11" :modifiers="['medium', 'uppercase', 'center']" class="flex-center percent">
						{{ badge?.percent }}
					</AText>
					<AText :size="9" :modifiers="['uppercase', 'center']" class="flex-center">{{ badge?.text }}</AText>
				</div>
			</div>

			<NuxtImg
				v-if="isBestDeal"
				src="nuxt-img/quick-buy/best-deal.png"
				alt="package"
				format="webp"
				width="132"
				height="100"
				loading="lazy"
				class="item__image"
			/>

			<NuxtImg
				v-else-if="isMostPopular"
				src="nuxt-img/quick-buy/most-popular.png"
				alt="package"
				format="webp"
				width="132"
				height="100"
				loading="lazy"
				class="item__image"
			/>

			<NuxtImg
				v-else
				src="nuxt-img/quick-buy/package.png"
				alt="package"
				format="webp"
				width="132"
				height="100"
				loading="lazy"
				class="item__image"
			/>

			<AText v-if="coins" :size="16" :modifiers="['bold']" class="count">
				<NuxtIcon name="12/coins" class="icon" filled />
				{{ numberFormatToK(coins) }}
			</AText>

			<div v-if="entries" class="entries flex-center">
				<AText v-if="entries" :size="16" :modifiers="['bold']" class="count">
					<NuxtIcon name="22/super-coins" class="icon" filled />
					{{ numberFormatToK(entries) }}
				</AText>

				<AText :size="10" class="count-after" :modifiers="['bold', 'uppercase']">
					{{ t("freeSc.label") }}
				</AText>
			</div>
		</div>

		<AButton variant="primary" size="xs" class="buy">
			<AText v-if="usualPrice" as="s" :size="12" :modifiers="['medium']" class="foot">
				${{ usualPrice.toFixed(2) }}
			</AText>
			<AText class="money" :modifiers="['medium']"> ${{ offer.money.toFixed(2) }} </AText>
		</AButton>
	</div>
</template>

<style lang="scss" scoped>
.item {
	position: relative;
	min-width: 153px;
	max-width: 153px;
	min-height: 200px;
	max-height: 200px;
	background: var(--neutral-900);
	border-radius: 12px;
	padding: 0px 8px 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: 0.2s;
	border: 2px solid rgba(255, 255, 255, 0.12);

	@include media-breakpoint-down(md) {
		width: 132px;
	}

	&:hover {
		cursor: pointer;
	}

	.item-badge {
		width: 44px;
		height: 44px;
		position: absolute;
		flex-direction: column;
		top: 59px;
		right: 16px;

		@include media-breakpoint-down(md) {
			right: 8px;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
		}

		&-content {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
		}
	}

	.badge {
		width: max-content;
		box-shadow: none;
		position: absolute;
		top: 8px;
		padding: 4px 8px;
	}

	&__data {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		margin-top: auto;
	}

	&__image {
		pointer-events: none;
		display: block;
		margin: 0 -8px;
	}

	&--best-deal {
		background: url("/nuxt-img/quick-buy/bg-best-deal.webp") no-repeat;

		.badge {
			background: var(--gradient-g-4);
		}
	}

	&--most-popular {
		background: url("/nuxt-img/quick-buy/bg-most-popular.webp") no-repeat;

		.badge {
			background: var(--gradient-g-6);
		}
	}

	&--most-popular,
	&--best-deal {
		background-size: 130%;
		background-position: center;
	}
}

.count {
	display: flex;
	align-items: center;
	line-height: 1;
	gap: 4px;

	.icon :deep(svg) {
		width: 20px;
		height: 20px;
		margin: 0;
	}
}

.entries {
	width: fit-content;
	margin-top: 3px;
	gap: 2px;
	background: linear-gradient(to right, #00ef43 -137%, transparent 78%);
	background-position: 10px 0;
}

.buy {
	min-height: 38px;
	display: flex;
	gap: 5px;
	border-radius: 8px;
	width: 100%;
	margin-top: 8px;

	.money {
		color: var(--neutral-950);
	}

	.foot {
		color: var(--secondary-400);
		line-height: 1;
		vertical-align: middle;
	}
}
</style>
