<script setup lang="ts">
const emit = defineEmits<{ (event: "closePopper"): void }>();

const { t } = useT();
const { open } = useAppModals();
const { isCanPlay } = useBingoData({ immediate: false });

const btnText = computed(() =>
	isCanPlay.value ? t("rewardCenter.bingo.btn.name") : t("rewardCenter.bingo.btn.name2")
);

const handleClick = () => {
	if (isCanPlay.value) {
		open("LazyOModalBingoPlay");
	} else {
		open("LazyOModalRacesGames");
	}
	emit("closePopper");
};
</script>

<template>
	<MRewardsWrapper
		:button-text="btnText"
		:title="t('rewardCenter.bingo.title')"
		:intro="t('rewardCenter.bingo.intro')"
		image="/nuxt-img/rewards/bingo.png"
		@click="handleClick"
	/>
</template>
