import useStatefulCookie from "./useStatefulCookie";

const availableThemes = {
	funrize: "Funrize",
	tao: "TaoFortune",
	nlc: "NolimitCoins",
	fortuneWheelz: "FortuneWheelz",
	funzCity: "FunzCity",
	jackpotRabbit: "Jackpot Rabbit"
};

const useTheme = (applyTheme?: keyof typeof availableThemes) => {
	const {
		public: { name }
	} = useRuntimeConfig();
	const themeMap: Record<string, keyof typeof availableThemes> = {
		TaoFortune: "tao",
		Nolimitcoins: "nlc",
		FortuneWheelz: "fortuneWheelz",
		Funrize: "funrize",
		FunzCity: "funzCity",
		JackpotRabbit: "jackpotRabbit"
	};
	const defaultName = ref(themeMap[name as string]);
	const theme = useStatefulCookie<keyof typeof availableThemes>("theme", { default: () => defaultName.value });

	if (process.client && applyTheme) {
		theme.value = applyTheme;
	}

	return {
		theme,
		availableThemes
	};
};

export default useTheme;
