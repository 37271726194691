<script setup lang="ts">
const { t } = useT();

const emit = defineEmits<{
	(event: "info"): void;
}>();

const props = withDefaults(
	defineProps<{
		goal?: number;
		complete?: number;
		withInfo?: boolean;
	}>(),
	{
		goal: 100,
		complete: 0,
		withInfo: false
	}
);

const pregressValue = computed(() => {
	if (+props?.goal === +props?.complete) {
		return "100%";
	}
	if (+props?.complete < +props?.goal) {
		return `${(100 / props?.goal) * props?.complete}%`;
	}

	return "8px";
});

const handleClose = () => {
	emit("info");
};
</script>

<template>
	<div class="progress">
		<div class="header">
			<AText :size="16" class="text-constanta">
				{{ t("You saved") }}
				<NuxtIcon v-if="withInfo" name="20/info-pop" class="info-icon" filled @click="handleClose" />
			</AText>
			<MPrizeFund class="prize" variant="entries" img="/nuxt-img/prizes/supercoins@2x.png" :icon-size="24">
				<AText :size="16" as="div" class="progress-value" :modifiers="['semibold']">
					{{
						`${shortNumberFormatter(complete, { fractionDigits: 2 })}/${shortNumberFormatter(goal, { fractionDigits: 2 })}`
					}}
				</AText>
			</MPrizeFund>
		</div>
		<div class="progress-wrapper">
			<div class="progress-thumb" :style="{ width: pregressValue }" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
$gradient-1: linear-gradient(108deg, #05711c 0%, #03e635 102.15%);

.progress {
	position: relative;
	display: flex;
	gap: 4px;
	flex-direction: column;
}

.header {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}

.progress-wrapper {
	width: 100%;
	height: 12px;
	border-radius: 10px;
	border: 1px solid var(--celaya);
	background: var(--neutral-950);
	padding: 2px;
}

.progress-value {
	text-align: center;
	width: max-content;
}

.info-icon {
	font-size: 20px;
	cursor: pointer;

	:deep(svg path) {
		fill: var(--ciampea);
	}
}

.progress-thumb {
	transition: 0.3s;
	height: 100%;
	min-width: 8px;
	background: $gradient-1;
	border-radius: 10px;
}
</style>
