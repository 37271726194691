import useAsyncFetch from "./useAsyncFetch";

const useGlobalInfoData = () => {
	const { data, execute } = useAsyncFetch({
		path: "/rest/features-games-info/info/",
		method: "get",
		options: {
			cached: true
		}
	});

	const offer = computed(() => ({
		...data.value?.currentOffer,
		badgeLabelParse: data.value?.currentOffer?.badgeLabel ? parseJSON(data.value?.currentOffer?.badgeLabel) : {}
	}));

	// Додати на бєк в слідучій ітерації
	// status: {..., topEmailNotificationIsActive: appInitData.value?.primaryContact === "email" && !appInitData.value.emailConfirmed},

	return { data, execute, offer };
};

export default useGlobalInfoData;
