<script setup lang="ts">
import type { HTMLProps } from "../types-utils";

const { isDesktop } = useDevice();

const scrollContainer = ref<HTMLElement | null>(null);
const isAtStart = ref(true);
const isAtEnd = ref(false);
const isBounce = ref(false);
const isScroll = ref(false);

const props = withDefaults(
	defineProps<
		{
			speed?: number;
			friction?: number;
			clickThreshold?: number;
			shadowEffect?: boolean;
			activeIndex?: number;
			isFocusCenter?: boolean;
			isEdgeBounce?: boolean;
			onScrollEnd?: () => void;
		} & HTMLProps
	>(),
	{
		speed: 16,
		friction: 0.95,
		clickThreshold: 5,
		activeIndex: -1,
		isFocusCenter: false,
		isEdgeBounce: true
	}
);

const state = reactive({
	isDragging: false,
	startX: 0,
	scrollLeft: 0,
	distanceMoved: 0,
	velocity: 0,
	lastTime: 0,
	lastPos: 0,
	isClickDisabled: false,
	animationFrameId: 0
});

const stopMomentum = () => {
	cancelAnimationFrame(state.animationFrameId);
	state.velocity = 0;
};

const onMouseDown = (e: MouseEvent) => {
	if (!scrollContainer.value) {
		return;
	}

	state.isDragging = true;
	state.startX = e.pageX;
	state.scrollLeft = scrollContainer.value.scrollLeft;
	state.lastPos = state.scrollLeft;
	state.lastTime = Date.now();
	state.distanceMoved = 0;
	stopMomentum();
};

const onMouseMove = (e: MouseEvent) => {
	if (!state.isDragging || !scrollContainer.value) {
		return;
	}

	if (e.pageX === 0 || e.pageX < 0) {
		return;
	}

	const x = e.pageX;
	const delta = x - state.startX;
	scrollContainer.value.scrollLeft = state.scrollLeft - delta;

	state.distanceMoved = Math.abs(delta);

	const now = Date.now();
	const elapsed = now - state.lastTime;
	if (elapsed > 0) {
		const distance = scrollContainer.value.scrollLeft - state.lastPos;
		state.velocity = distance / elapsed;
	}

	state.lastTime = now;
	state.lastPos = scrollContainer.value.scrollLeft;
};

const animateMomentum = () => {
	if (!scrollContainer.value) {
		return;
	}

	const animate = () => {
		if (!scrollContainer.value) {
			stopMomentum();
			return;
		}

		state.velocity *= props.friction;
		scrollContainer.value!.scrollLeft += state.velocity * props.speed;

		if (Math.abs(state.velocity) > 0.05) {
			state.animationFrameId = requestAnimationFrame(animate);
		} else {
			stopMomentum();
		}
	};

	state.animationFrameId = requestAnimationFrame(animate);
};

const onMouseUp = () => {
	if (!state.isDragging || !scrollContainer.value) {
		return;
	}

	state.isDragging = false;

	if (state.distanceMoved > props.clickThreshold) {
		state.isClickDisabled = true;
		setTimeout(() => {
			state.isClickDisabled = false;
		}, 200);
	}

	animateMomentum();
};

const onCardClick = (e: MouseEvent) => {
	if (state.isClickDisabled) {
		e.preventDefault();
		e.stopPropagation();
	}
};

const checkScrollPosition = () => {
	if (!scrollContainer.value) {
		return;
	}

	const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.value;

	isAtStart.value = scrollLeft <= 0;
	isAtEnd.value = Math.ceil(scrollLeft + clientWidth) >= Math.ceil(scrollWidth);

	if (isDesktop && !isBounce.value && props.isEdgeBounce) {
		isBounce.value = true;
	}

	if (isAtEnd.value && props.onScrollEnd) {
		props.onScrollEnd();
	}
};

const checkIfScrollable = () => {
	if (!scrollContainer.value) {
		return;
	}

	const { scrollWidth, clientWidth } = scrollContainer.value;
	isScroll.value = scrollWidth > clientWidth;
};

const scrollToActiveIndex = async () => {
	if (props.activeIndex === -1 || !scrollContainer.value) {
		return;
	}

	await nextTick();
	const item = scrollContainer.value?.children?.[props.activeIndex] as HTMLElement;
	if (item) {
		let offset = item?.offsetLeft - scrollContainer.value?.offsetLeft;
		if (props.isFocusCenter) {
			const containerCenter = scrollContainer.value?.clientWidth / 2;
			const itemCenter = item.offsetWidth / 2;
			offset = offset - containerCenter + itemCenter;
		}
		scrollContainer.value?.scrollTo({ left: offset, behavior: "smooth" });
	}
};

useEventListener(scrollContainer, "click", onCardClick, true);
useEventListener(scrollContainer, "mousedown", onMouseDown);
useEventListener(scrollContainer, "scroll", checkScrollPosition);

useEventListener(window, "mousemove", onMouseMove);
useEventListener(window, "mouseup", onMouseUp);

onMounted(() => {
	checkIfScrollable();

	if (props.activeIndex !== -1) {
		scrollToActiveIndex();
	}

	if (process.client) {
		watch(
			() => props.activeIndex,
			(newIndex) => {
				if (newIndex !== -1) {
					scrollToActiveIndex();
				}
			}
		);
	}
});
onUnmounted(stopMomentum);
</script>

<template>
	<div
		ref="scrollContainer"
		:class="[
			'scroll-container',
			{
				'shadow-effect': shadowEffect && isScroll,
				'start-scroll': isAtStart,
				'finish-scroll': isAtEnd,
				'bounce-ready': isBounce,
				'can-scroll': isScroll
			}
		]"
	>
		<slot />
	</div>
</template>

<style scoped lang="scss">
.scroll-container {
	display: flex;
	width: 100%;
	overflow: auto hidden;
	user-select: none;
	white-space: nowrap;
	scrollbar-width: none;
	transition: transform 0.3s ease;

	&.can-scroll {
		cursor: grab !important;

		&:active {
			cursor: grabbing !important;
		}
	}

	&::-webkit-scrollbar {
		display: none;
	}
}

.shadow-effect {
	mask-image: linear-gradient(
		to right,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 1) 5%,
		rgba(0, 0, 0, 1) 95%,
		rgba(0, 0, 0, 0) 100%
	);
	transition: mask-image 0.3s;

	&.start-scroll {
		mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 95%, rgba(0, 0, 0, 0) 100%);
	}

	&.finish-scroll {
		mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 5%);
	}
}

.bounce-ready {
	&.start-scroll:not(.finish-scroll) {
		animation: bounceRight 0.6s ease forwards;
	}

	&.finish-scroll:not(.start-scroll) {
		animation: bounceLeft 0.6s ease forwards;
	}
}

@keyframes bounceRight {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(8%);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes bounceLeft {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(-8%);
	}
	100% {
		transform: translateX(0);
	}
}
</style>
<style lang="scss">
.scroll-container > * {
	white-space: normal;
}
</style>
