<script lang="ts" setup>
import type { HomeBanner } from "~/types";

const props = defineProps<{ banner: HomeBanner }>();

const { open } = useAppModals();
const { data: appInit } = useAppInitData();
const localPath = useLocalePath();

const buttonClick = () => {
	if (appInit.value?.isGuest) {
		open("LazyOModalSignup");
		return;
	}

	if (props.banner?.buttonLink) {
		navigateTo(localPath(props.banner.buttonLink));
		return;
	}

	navigateTo(localPath("/issues/all-games/"));
};
</script>
<template>
	<MBanner
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:bg-color="banner.background"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		:type="banner.type"
		@click="buttonClick"
	>
		<template #default>
			<AText :size="{ full: 24, md: 16 }">
				<span class="slide-item__header" v-html="banner?.title" />
			</AText>
		</template>
		<template #description>
			<AText :size="{ full: 48, md: 32 }" :modifiers="['bold']">
				<span class="slide-item__description" v-html="banner?.smallTitle" /> </AText
		></template>
		<template #actions>
			<div class="actions">
				<AAnimationCustomByScroll animationClass="blink" transitionDelay="1s">
					<AButton variant="primary" class="app-banner__btn" size="xl">
						<AText :size="18" :modifiers="['medium']">{{ banner.buttonText }}</AText>
					</AButton>
				</AAnimationCustomByScroll>
			</div>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped>
:deep(.app-banner__content) {
	justify-content: flex-start;
	padding: 32px 24px;

	@include media-breakpoint-down(sm) {
		height: 372px;
		top: 200px;
		padding: 24px 12px;
		align-items: flex-start;
		width: calc(100% - 32px);
		margin: 0 auto;
		border-radius: 16px;
		border: 2px solid var(--chifeng);
		background: var(--czestochowa);
		backdrop-filter: blur(10px);
	}

	.app-banner__desc span {
		@include media-breakpoint-down(sm) {
			font-size: 32px;
		}
	}
}
.logo {
	display: flex;
	justify-content: center;
	margin-bottom: gutter(2);

	@include media-breakpoint-down(sm) {
		margin-bottom: gutter(0.5);
	}

	&:deep(svg) {
		width: 273px;
		height: 150px;

		@include media-breakpoint-down(sm) {
			width: 102px;
			height: 56px;
		}
	}
}

.rate-wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 34px;

	@include media-breakpoint-down(sm) {
		margin-top: 24px;
	}
}

.actions {
	margin-top: 34px;
	width: 100%;

	.app-banner__btn {
		box-shadow: 0px 3px 32px 0px var(--chhatarpur);
		width: 100%;

		@include media-breakpoint-up(md) {
			max-width: 165px;
		}
	}
}
</style>
