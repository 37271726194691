<script lang="ts" setup>
import OHeaderBalance from "~/organizms/OHeaderBalance.vue";
import ONotificationCenter from "~/organizms/ONotificationCenter.vue";

const { t } = useT();
const { isDesktop, isMobile } = useDevice();
const route = useRoute();
const { open, isOpen } = useAppModals();
const { data: appInitData } = useAppInitData();
const { isFinOperationAvailable } = useCashHelper();
const { openDefault } = usePresetPackageController();
const [isShow, toggleSidebar] = useToggle(false);
const [isPiggyBankActive, togglePiggyBank] = useToggle();
const { isActivePiggyBank } = usePiggy({ open });

const isLoggedIn = computed(() => appInitData.value?.isGuest === false);
const showMoneyBoxWidget = computed(() => isDesktop && isActivePiggyBank.value);

const handleOpenCash = () => {
	window?.$store?.gaCash?.deposit?.({
		location: "header",
		step: "view_payments_info"
	});
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
	if (!isFinOperationAvailable.value) {
		return;
	}
	openDefault();
};

const handleToggleSidebar = () => {
	toggleSidebar();
};

const handleLogin = () => {
	open("LazyOModalLogin");

	dispatchGAEvent({
		event: "login",
		location: "header",
		step: "start"
	});
};

const handleSignup = () => {
	open("LazyOModalSignup");

	dispatchGAEvent({
		event: "registration",
		location: "header",
		step: "start"
	});
};
</script>
<template>
	<AHeader :class="{ 'logged-in': isLoggedIn, 'game-popup-mode': isOpen('LazyOModalGame') }">
		<div class="header-left">
			<NuxtLink class="logo" to="/">
				<NuxtImg src="/nuxt-img/logo-icon.svg" class="desk" alt="logo" width="146" height="80" />
				<NuxtImg src="/nuxt-img/logo-icon-mob.svg" class="mob" alt="logo" width="38" height="38" />
			</NuxtLink>
		</div>

		<div v-if="isLoggedIn" class="header-right">
			<OPiggyBankProgressBar
				v-if="showMoneyBoxWidget"
				:isActive="isPiggyBankActive"
				@close="togglePiggyBank(false)"
				@toggle-piggy-bank-bar="togglePiggyBank"
			/>
			<div v-if="isDesktop" class="notification">
				<ONotificationCenter :close-by-click-outside="true" />
			</div>
			<OHeaderBalance />
			<AAnimationPulse color="var(--cairo)" :width="12" active>
				<AButton variant="primary" size="md" @click="handleOpenCash">
					<AText variant="tempe" :modifiers="['medium']">{{ t("Buy") }}</AText>
				</AButton>
			</AAnimationPulse>
		</div>

		<div v-else class="buttons-auth">
			<AButton variant="secondary" size="md" class="login-btn" @click="handleLogin">
				<AText :modifiers="['medium']" class="text-tlalnepantla">{{ t("Sign In") }}</AText>
			</AButton>
			<AButton variant="primary" size="md" class="signup-btn" @click="handleSignup">
				<AText :modifiers="['medium']" class="text-tlalnepantla">{{ t("Sign Up") }}</AText>
			</AButton>
		</div>

		<AIconButton variant="ghost" class="menu-btn" @click.prevent="handleToggleSidebar">
			<NuxtIcon name="20/burger" filled />

			<AText :modifiers="['uppercase', 'semibold']">
				{{ t("menu") }}
			</AText>
		</AIconButton>
	</AHeader>

	<OSidebar class="sidebar" :show="isShow" @toggle-sidebar="handleToggleSidebar" />
	<LazyOMobNavigation v-if="isMobile && !Boolean(route.query.game)" @toggle-sidebar="handleToggleSidebar" />
</template>

<style scoped lang="scss">
header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 102px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 64px;
	z-index: 22;
	backdrop-filter: blur(20px);

	&.logged-in {
		background-color: var(--carabanchel);
		position: fixed;
	}

	&.game-popup-mode {
		background-color: transparent;
	}

	@include media-breakpoint-down(lg) {
		padding: 0 gutter(2);
		min-height: 56px;
		&:not(.logged-in) {
			display: none;
		}
		&.logged-in {
			&:deep {
				.menu-btn,
				.account-link,
				.account-link__user,
				.separator {
					display: none;
				}
			}
		}
	}

	.complete {
		&:hover {
			cursor: pointer;
			text-decoration: none;
		}
	}

	.back-home {
		display: flex;
		gap: gutter(1);
		align-items: center;
	}

	.pulse button {
		--a-button-primary-before-content: "";

		&::before {
			opacity: 1;
			background-color: transparent;
		}
	}
}
.header-left,
.header-right,
.buttons-auth {
	display: flex;
	align-items: center;
	gap: 8px;

	@include media-breakpoint-down(md) {
		gap: gutter(1);
	}

	button {
		width: 72px;
	}

	.login-btn {
		border-radius: 8px;
	}
}

.buttons-auth {
	margin-left: auto;
	margin-right: 32px;
}

.header-right {
	flex-grow: 1;
	justify-content: flex-end;
	margin-right: 32px;

	@include media-breakpoint-down(lg) {
		margin-right: 0;
	}
}

.logo {
	display: inline-flex;
	@include media-breakpoint-down(lg) {
		display: block !important;
		margin-left: gutter(1);
	}

	.desk {
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	.mob {
		display: none;
		@include media-breakpoint-down(lg) {
			display: block;
		}
	}

	.img-logged {
		margin-top: gutter(-0.75);
	}
}

.menu-btn {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 0 16px;
	&:hover {
		cursor: pointer;
	}
	.nuxt-icon {
		font-size: 20px;
	}
}

.account-link {
	display: flex;
	justify-content: center;
	gap: gutter(2);

	&__user {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.separator {
	height: 40px;
	width: 2px;
	background: var(--garoua);
}

.notification {
	@include media-breakpoint-down(lg) {
		display: none;
	}
	//border-radius: 999px;
	//width: 54px;
	//height: 38px;
	//margin-right: 10px;
	//display: flex;
	//justify-content: center;
	//align-items: center;
	//border: 2px solid var(--carletonville);
}
</style>
