<script setup lang="ts">
import type { Game, GameType } from "@/types";

const emit = defineEmits<{ (event: "toggleFavorite", game: Game): void; (event: "close"): void }>();

const props = defineProps<{
	game: Game & { skeleton?: boolean };
	type?: GameType;
	gameIndex?: number;
	isActiveCard?: boolean;
}>();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { data: appInit } = useAppInitData();
const loginGuard = useLoginGuard();
const { isOpen, open, close } = useAppModals();
const { handleOpenGame } = useOpenGame(open);
const { isDesktop } = useDevice();
const [isShowVideo, switchShowVideo] = useToggle();
const isShowVideoCompute = computed(() => props.isActiveCard && isShowVideo.value);

const DEFAULT_BG = "/nuxt-img/card-games/default-bg.png";
const randomColor = computed(() => prepareRandomColor());
const isGameFavorite = computed(() => props.game?.isFavorite);

const handleToggleToFavorite = () => {
	emit("toggleFavorite", { ...props.game, isFavorite: isGameFavorite.value });
};

const handleOpenGameClick = () => {
	loginGuard({
		success() {
			const lastGames = appInit.value?.lastGames || [];
			if (lastGames.includes(props.game?.id)) {
				appInit.value?.lastGames &&
					(appInit.value.lastGames = [props.game?.id as number, ...lastGames.filter((el) => el !== props.game?.id)]);
			} else {
				appInit?.value?.lastGames && (appInit.value.lastGames = [props.game?.id as number, ...lastGames]);
			}

			if (isOpen("LazyOModalGameSearch")) {
				close("LazyOModalGameSearch");
			}

			if (props.game?.gamePlayInfo) {
				open("LazyOModalGamePreview", {
					game: props.game
				});
				return;
			}

			handleOpenGame(props.game?.slug);
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<div class="card-wrapper">
		<div v-if="type === 'slider_carousel_view'" class="game-card-wrapper slider-carousel">
			<MGameCard
				:game="game"
				:gameImg="`${baseImageUrl}${props.game?.logo || props.game?.img}`"
				:gameImgIncrease="2"
				:defaultBg="DEFAULT_BG"
				:width="{ full: 316, lg: 196 }"
				:height="{ full: 352, lg: 216 }"
				:isFavorite="isGameFavorite"
				@toggle-favorite="handleToggleToFavorite"
				@click.prevent="handleOpenGameClick"
			>
				<template #top>
					<MGameBadges :game="game" />
				</template>
			</MGameCard>
		</div>
		<div
			v-else-if="type === 'slider_carousel_vertical_video'"
			:class="['game-card-wrapper video-view', { 'is-active': isActiveCard }]"
		>
			<MGameCard
				:game="game"
				:gameImg="`${baseImageUrl}${props.game?.logo1x2 || props.game?.logo || props.game?.img}`"
				class="video-card"
				:gameImgIncrease="2"
				:defaultBg="DEFAULT_BG"
				:width="324"
				:height="486"
				:isFavorite="isGameFavorite"
				@toggle-favorite="handleToggleToFavorite"
				@click.prevent="handleOpenGameClick"
				@mouseenter="switchShowVideo(true)"
				@mouseleave="switchShowVideo(false)"
			>
				<template #top>
					<MGameBadges :game="game" />
				</template>
				<template #body>
					<div v-if="(isDesktop ? isShowVideoCompute : isActiveCard) && game?.videoPreview" class="box-video-overlay">
						<video loop autoplay muted playsinline :controls="false">
							<source :src="`${baseImageUrl}${game?.videoPreview}`" type="video/mp4" />
						</video>
					</div>
				</template>
			</MGameCard>
		</div>
		<MGameTopTen v-else-if="type === 'slider_top_rating'" :indexGame="gameIndex">
			<template #game-card>
				<div class="game-card-wrapper">
					<MGameCard
						:game="game"
						:gameImg="`${baseImageUrl}${props.game?.logo1x2 || props.game?.logo}`"
						:gameImgIncrease="2"
						:defaultBg="DEFAULT_BG"
						:width="{ full: 173, lg: 196 }"
						:height="{ full: 306, lg: 322 }"
						:isFavorite="isGameFavorite"
						@toggle-favorite="handleToggleToFavorite"
						@click.prevent="handleOpenGameClick"
					>
						<template #top>
							<MGameBadges :game="game" />
						</template>
					</MGameCard>
				</div>
			</template>
		</MGameTopTen>
		<div v-else-if="type === 'slider_gradientfull'" class="game-card-wrapper gradientfull">
			<MGameCard
				:game="game"
				:gameImg="`${baseImageUrl}${props.game?.logo || props.game?.img}`"
				:gameImgIncrease="2"
				:defaultBg="DEFAULT_BG"
				:width="{ full: 198, lg: 154 }"
				:height="{ full: 222, lg: 174 }"
				:isFavorite="isGameFavorite"
				@toggle-favorite="handleToggleToFavorite"
				@click.prevent="handleOpenGameClick"
			/>
		</div>
		<div v-else-if="type === 'slider_vertical'" class="game-card-wrapper">
			<MGameCard
				:game="game"
				:gameImg="`${baseImageUrl}${props.game?.logo1x2 || props.game?.logo}`"
				:gameImgIncrease="2"
				:defaultBg="DEFAULT_BG"
				:width="{ full: 177, lg: 160 }"
				:height="{ full: 310, lg: 261 }"
				:isFavorite="isGameFavorite"
				@toggle-favorite="handleToggleToFavorite"
				@click.prevent="handleOpenGameClick"
			>
				<template #top>
					<MGameBadges :game="game" />
				</template>
			</MGameCard>
		</div>
		<div v-else class="game-card-wrapper">
			<MGameCard
				:game="game"
				:gameImg="`${baseImageUrl}${props.game?.logo || props.game?.img}`"
				:gameImgIncrease="2"
				:defaultBg="DEFAULT_BG"
				:width="{ full: 208, lg: 175 }"
				:height="{ full: 237, lg: 200 }"
				:isFavorite="isGameFavorite"
				@toggle-favorite="handleToggleToFavorite"
				@click.prevent="handleOpenGameClick"
			>
				<template #top>
					<MGameBadges :game="game" />
				</template>
			</MGameCard>
		</div>
	</div>
</template>

<style scoped lang="scss">
.card-wrapper {
	&,
	.slider-carousel video,
	.video-view video {
		will-change: transform, opacity;
	}
}

.game-card-wrapper {
	padding: 2px;
	border-radius: 16px;
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
	background: var(--celaya);
	&.gradientfull {
		background: var(--gradient-g-4);
		box-shadow: 0px 0px 15px 0px v-bind(randomColor);
	}

	&.video-view.is-active {
		background: var(--primary-500);

		:deep(.game-overlay) {
			opacity: 1;
			z-index: 3;
			background-color: transparent;
			@include media-breakpoint-down(lg) {
				display: block;
			}
		}

		:deep(.btn-favorite) {
			display: flex;
		}
	}
}

:deep(.game-card) {
	background: var(--celaya);
	border-radius: 14px;
	&.video-card {
		.box-video-overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			video {
				object-fit: cover;
				width: 100%;
				height: 100%;

				@include media-breakpoint-up(lg) {
					display: none;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			&:hover video {
				display: block;
			}
		}

		.game-overlay .nuxt-icon,
		.btn-favorite {
			top: auto;
			transform: translate(-50%, 0%);
		}
		.game-overlay .nuxt-icon {
			height: 78px;
			width: 78px;
			bottom: 16px;
			svg {
				margin-bottom: 0;
				width: 34px;
				height: 34px;
			}
		}

		.btn-favorite {
			height: 58px;
			width: 58px;
			align-items: center;
			justify-content: center;
			bottom: 26px;

			@include media-breakpoint-down(lg) {
				left: calc(50% - 80px);
			}
			svg {
				margin-bottom: 0;
				width: 33px;
				height: 33px;
			}
		}
	}
	.image-block {
		border-radius: 14px;
		background: var(--a-overlay);
	}
	.game-overlay {
		background: var(--a-overlay);
		.nuxt-icon {
			width: 58px;
			height: 58px;
			background: var(--сirebon);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			svg {
				font-size: 28px;
				margin-bottom: 0;
			}
		}
	}
	.btn-favorite {
		display: none;
		width: 38px;
		height: 38px;
		border-radius: 50%;
		top: 50%;
		left: calc(50% - 76px);
		transform: translateY(-50%);
		background: var(--chifeng);
		border-radius: 50%;
		border: 1px solid var(--celaya);
		cursor: pointer;
		svg {
			width: 22px;
			height: 22px;
		}
		&.active {
			border: 2px solid var(--cuernavaca);
			background: var(--chitungwiza);
		}
		@include media-breakpoint-down(lg) {
			min-width: 28px;
			min-height: 28px;
			width: 28px;
			height: 28px;
			left: calc(50% - 52px);

			&:deep(svg) {
				width: 14px;
				height: 14px;
			}
		}
	}
	&:hover {
		.btn-favorite {
			display: flex;
			@include media-breakpoint-down(lg) {
				display: none;
			}
		}
		@include media-breakpoint-down(lg) {
			img {
				transform: none;
			}
		}
	}
}
</style>
