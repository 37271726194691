<script lang="ts" setup>
import type { GameModes } from "@/types";

const { t } = useT();
const { data: appInitData } = useAppInitData();
const { tourPoints, entries } = useBalance({
	options: { cached: true, immediate: appInitData.value?.isGuest === false, watch: [() => appInitData.value?.isGuest] }
});
const { isSweepStakes, activeGameMode, gameMode, handleSwitchGameMode } = useSwitchMode();

const emit = defineEmits(["clickToHiw"]);

const balance = ref(+entries.value || 0);

const handleSwitchToMode = async (mode: GameModes) => {
	if (mode === gameMode.value) {
		return;
	}

	dispatchGAEvent({
		event: "click_button",
		location: "header",
		button_name: isSweepStakes.value ? "switch_tourney" : "switch_promotional"
	});
	await handleSwitchGameMode(mode);
};

const handleHiwClick = () => {
	navigateTo("/how-it-works/");
	emit("clickToHiw");
};

const currentIcon = computed(() =>
	isSweepStakes.value ? "/nuxt-img/prizes/supercoins@2x.png" : "/nuxt-img/prizes/rabbitcoins.png"
);

const showAnimation = useState<boolean>("animationCoinPouring", () => false);

const popperState = ref(false);

const triggerAnimation = () => {
	showAnimation.value = true;
	setTimeout(() => {
		showAnimation.value = false;
	}, 3000);
};

watch(entries, (value) => {
	triggerAnimation();
	balance.value = +value;
});

watch(tourPoints, () => {
	triggerAnimation();
});
</script>
<template>
	<APopper placement="bottom" @open="popperState = true" @close="popperState = false">
		<div class="balance-switcher" :class="{ opened: popperState }" data-tid="header-balances">
			<MPrizeFund :variant="activeGameMode" :iconSize="22" :img="currentIcon">
				<AText
					v-if="isSweepStakes"
					:size="14"
					:modifiers="['semibold', 'ellipsis']"
					as="div"
					class="text-currency text-currency-sc"
				>
					<AAnimationNumberCounter :number="balance" useThousandSeparator :decimals="2" />
				</AText>
				<AText
					v-else
					:size="14"
					class="text-currency text-currency-gc"
					:modifiers="['semibold', 'ellipsis']"
					as="div"
					data-tid="show-balance"
				>
					<AAnimationNumberCounter :number="Number(tourPoints)" useThousandSeparator />
				</AText>
				<NuxtIcon class="arrow-icon" name="20/arrow-down" data-tid="open-balances-list" filled />
			</MPrizeFund>
		</div>

		<template #content="{ close }">
			<div :class="['item', { selected: !isSweepStakes }]" @click="handleSwitchToMode('TournamentPoints'), close()">
				<MPrizeFund variant="coins" :iconSize="22" img="/nuxt-img/prizes/rabbitcoins.png" :offset="0.5">
					<AText>{{ t("Game Coins") }}</AText>
				</MPrizeFund>
				<AText
					class="item-balance text-currency-gc"
					:modifiers="['semibold', 'right']"
					as="div"
					data-tid="points-coins"
				>
					{{ shortNumberFormatter(tourPoints) }}
				</AText>
			</div>

			<div :class="['item', { selected: isSweepStakes }]" @click="handleSwitchToMode('SweepStakes'), close()">
				<MPrizeFund variant="entries" :iconSize="22" img="/nuxt-img/prizes/supercoins@2x.png" :offset="0.5">
					<AText>{{ t("Super Coins") }}</AText>
				</MPrizeFund>
				<div class="item-balance">
					<AText :modifiers="['semibold', 'right']" as="div" data-tid="entries-coins" class="text-currency-sc">
						{{ shortNumberFormatter(entries, { fractionDigits: 2 }) }}
					</AText>
				</div>
			</div>

			<AText
				:modifiers="['link', 'underline', 'center']"
				as="div"
				:size="12"
				class="info-link"
				data-tid="info-link"
				@click="handleHiwClick"
			>
				{{ t("How it works") }}?
			</AText>
		</template>
	</APopper>
</template>

<style scoped lang="scss">
.balance-switcher {
	width: 160px;
	height: 38px;
	background: var(--neutral-900);
	padding: 4px 8px;
	border: 1px solid var(--neutral-400);
	border-radius: 8px;
	display: flex;
	align-items: center;
	cursor: pointer;

	&.opened {
		.text-currency {
			display: none;
		}

		.arrow-icon {
			transform: rotate3d(1, 0, 0, 180deg);
		}
	}

	@include media-breakpoint-down(lg) {
		margin-right: 0;
	}

	.arrow-icon {
		font-size: 20px;
		margin-left: auto;
		transition: 0.6s;

		&:deep(svg) {
			margin-bottom: 3px;
		}
	}

	.ellipsis {
		min-width: 50px;
		max-width: 110px;
	}

	&:deep(.prize) {
		width: 100%;
	}
}

.text-balance {
	display: flex;
	gap: gutter(0.25);
}

:deep(.popper) {
	--popper-theme-background-color: var(--neutral-900);
	--popper-theme-background-color-hover: var(--neutral-900);
	--popper-theme-border-width: 0;
	--popper-theme-border-radius: 12px;
	--popper-theme-box-shadow: 0px 12px 24px 0px rgba(11, 8, 12, 0.6);
	--popper-theme-padding: 4px;

	width: 220px;
	max-width: 100vw;
	display: flex;
	flex-direction: column;
	gap: 2px;
	overflow: hidden;

	.item {
		padding: 12px 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 2px;
		border-radius: 8px;
		transition: all 200ms ease-in-out;
		background: var(--neutral-900);

		&.selected {
			background: var(--neutral-800);
		}

		&:not(.selected) {
			cursor: pointer;

			&:hover {
				background: var(--custom-rgb-16);
			}
		}
	}
}

.info-link {
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	color: var(--primary-500);
	background: var(--neutral-900);
	border-top: 1px solid var(--neutral-800);

	&:deep(.nuxt-icon) {
		font-size: 18px;
	}
}

.is-blurred {
	transition: 0.2s filter 8s ease-in-out;
	filter: blur(4px);

	&:hover {
		filter: blur(0);
		transition-delay: 0s;

		@include media-breakpoint-down(lg) {
			filter: blur(4px);
			transition-delay: 8s;
		}
	}

	&.out {
		filter: blur(0);
		transition-delay: 0s;
	}
}
</style>
