<script lang="ts" setup>
import type { KeenSliderOptions } from "keen-slider";

import type { GamesMenu } from "@/types";
const emit = defineEmits<{ (event: "clickTab", tab: string): void }>();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const props = withDefaults(
	defineProps<{
		menu: GamesMenu;
		isTabs: boolean;
		activeTab: string | null;
	}>(),
	{ isTabs: false, activeTab: null }
);
const { t } = useT();
const route = useRoute();
const router = useRouter();
const { open } = useAppModals();

const isSvgCode = (icon: string): boolean => icon.trim().startsWith("<svg");

const navigationContainer = ref();

const sliderActiveSlide = ref(0);
const isLast = ref(false);

const sliderOptions: KeenSliderOptions = {
	loop: false,
	mode: "snap",
	slides: { perView: "auto", spacing: 0 },
	created(slider) {
		sliderActiveSlide.value = slider.track.details.abs;
		setTimeout(() => {
			slider.update();
		}, 1500);
	},
	slideChanged(slider) {
		sliderActiveSlide.value = slider.track.details.abs;
	},
	dragged(slider) {
		isLast.value = slider.track.details.slides[props.menu.length - 1].portion >= 0.98;
	},
	detailsChanged(slider) {
		isLast.value = slider.track.details.slides[props.menu.length - 1].portion >= 0.98;
	}
};

const handleClick = (item) => {
	if (!props.isTabs || item.slug === "collections") {
		router.push(item.url);
	} else {
		emit("clickTab", item.slug);
	}
};

watch(
	() => route.path,
	() => {
		if (props.isTabs) {
			return;
		}
		setTimeout(() => {
			const index = props.menu?.findIndex((item) => item.url.includes(route.path)) ?? 0;
			const maxIdx = navigationContainer.value?.slider?.track?.details?.maxIdx;

			if (maxIdx) {
				const difference = props.menu.length - index;

				if (index > maxIdx) {
					navigationContainer.value?.slider?.moveToIdx(maxIdx - difference + 1);
				}
			}
		}, 1000);
	},
	{ immediate: true }
);
</script>

<template>
	<div
		class="navigation-wrapper"
		:class="{
			'move-to-start': sliderActiveSlide !== 0,
			'move-to-end': !isLast,
			'move-to-center': sliderActiveSlide !== 0 && !isLast
		}"
	>
		<ASlider ref="navigationContainer" class="game-navigation" :options="sliderOptions">
			<div v-for="(slide, idx) of menu" :key="`${slide.title}-${idx}`" class="keen-slider__slide">
				<NuxtLink
					:class="{ active: slide.url?.includes(route.path) || activeTab === slide.slug }"
					@click="handleClick(slide)"
				>
					<div v-if="slide?.img && isSvgCode(slide?.img ?? '')" class="icon" v-html="slide.img" />

					<NuxtImg
						v-else-if="slide?.img"
						:src="`${baseImageUrl}${slide.img}`"
						width="24"
						height="24"
						loading="lazy"
						alt="category"
						class="icon icon-img"
					/>

					<AText variant="toledo" :modifiers="['semibold']" class="text-tlalnepantla">{{ slide.title }}</AText>
				</NuxtLink>
			</div>
		</ASlider>

		<div class="prev" :class="{ hidden: sliderActiveSlide === 0 }" @click="navigationContainer.slider?.prev()">
			<NuxtIcon name="40/left-slider" />
		</div>
		<div class="next" :class="{ hidden: isLast }" @click="navigationContainer.slider?.next()">
			<NuxtIcon name="40/right-slider" />
		</div>

		<NuxtLink class="search" @click="open('LazyOModalGameSearch')">
			<NuxtIcon name="24/search" class="icon" filled />
			<AText variant="toledo" :modifiers="['semibold']" class="text-tlalnepantla">{{ t("Find Your Game") }}</AText>
		</NuxtLink>
	</div>
</template>

<style scoped lang="scss">
@keyframes scaleDown {
	0% {
		transform: scale(1.2);
	}
	50% {
		transform: scale(0.8);
	}
	100% {
		transform: scale(1.2);
	}
}
@keyframes pulse {
	0% {
		width: 0;
		height: 0;
	}
	100% {
		width: 100%;
		height: 44px;
	}
}
.move-to-start {
	:deep(.keen-slider) {
		mask-image: linear-gradient(
			260deg,
			rgba(11, 8, 12) 0,
			rgba(11, 8, 12, 1) calc(100% - 180px),
			rgba(11, 8, 12, 0) calc(100% - 30px),
			rgba(11, 8, 12, 0) 100%
		);

		@include media-breakpoint-down(sm) {
			mask-image: none;
		}
	}
}

.move-to-end {
	:deep(.keen-slider) {
		mask-image: linear-gradient(
			90deg,
			rgba(11, 8, 12) 0,
			rgba(11, 8, 12, 1) calc(100% - 180px),
			rgba(11, 8, 12, 0) calc(100% - 30px),
			rgba(11, 8, 12, 0) 100%
		);

		@include media-breakpoint-down(sm) {
			mask-image: none;
		}
	}
}

.move-to-center {
	:deep(.keen-slider) {
		mask-image: linear-gradient(
			90deg,
			rgba(11, 8, 12, 0) 0,
			rgba(11, 8, 12, 0) 30px,
			rgba(11, 8, 12, 1) 180px,
			rgba(11, 8, 12, 1) calc(100% - 180px),
			rgba(11, 8, 12, 0) calc(100% - 30px),
			rgba(11, 8, 12, 0) 100%
		);

		@include media-breakpoint-down(sm) {
			mask-image: none;
		}
	}
}
.keen-slider__slide {
	overflow: visible;
	padding-right: 8px;

	@include media-breakpoint-down(md) {
		padding-right: 6px;
	}
}

.navigation-wrapper {
	width: 100%;
	margin: 24px auto 0;
	display: flex;
	gap: 60px;
	position: relative;

	@include media-breakpoint-down(md) {
		max-width: 100%;
		flex-direction: column;
		gap: 8px;
		margin: 24px 0 0;
	}
}

a {
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	color: var(--cannes);
	padding: gutter(1.25);
	text-decoration: none;
	border-radius: 999px;
	background: var(--carletonville);
	border: 2px solid var(--celaya);
	transition: all 200ms ease-in-out;
	& > * {
		position: relative;
		z-index: 2;
	}
	.icon {
		margin-right: 8px;
		:deep(svg) {
			display: inline-block;
			vertical-align: middle;
		}
	}
	&:hover {
		border: 2px solid var(--сirebon);
		cursor: pointer;
	}
	&::before {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		border-radius: inherit;
		background: var(--carletonville);
	}
	&.active {
		background: var(--celaya);
		border: 2px solid var(--сirebon);
		position: relative;
		color: var(--сirebon);
		&:hover {
			opacity: 1;
		}

		&::before {
			opacity: 1 !important;
			animation: pulse 0.25s ease-out forwards;
		}
		&:deep(.icon) {
			animation: scaleDown 2s ease-out infinite;
		}
		&:deep(svg) {
			path {
				fill: var(--сirebon);
			}
		}
		.icon-img {
			filter: invert(53%) sepia(99%) saturate(382%) hue-rotate(21deg) brightness(130%) contrast(101%);
		}
	}

	&.search {
		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}

	:deep(svg) {
		font-size: 24px;
		margin: 0;
	}
}

.prev,
.next {
	position: absolute;
	top: 4px;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--сirebon);
	border-radius: 4px;
	user-select: none;

	@include media-breakpoint-down(sm) {
		display: none;
	}

	&:hover {
		cursor: pointer;
	}

	&:deep(svg) {
		font-size: 40px;
		margin-bottom: 0;
	}

	&.hidden {
		display: none;
	}
}

.prev {
	left: 4px;
}

.next {
	right: 245px;

	@include media-breakpoint-down(md) {
		right: 4px;
	}
}
</style>
