<script setup lang="ts">
import type { EventData, Promotion } from "~/types";

import type { KeenSliderOptions } from "keen-slider";
const { copy } = useClipboard();
const { t } = useT();
const { open } = useAppModals();
const isGuest = useIsGuest();
const prepareImgUrl = useImage();
const sliderOptions: KeenSliderOptions = {
	loop: false,
	mode: "snap",
	slides: { perView: "auto", spacing: 16 },
	breakpoints: {
		"(max-width: 576)": {
			slides: {
				perView: 1,
				spacing: 0
			}
		}
	},
	created(slider) {
		setTimeout(() => {
			slider.update();
		}, 1500);
	}
};

const { isDesktop } = useDevice();
const router = useRouter();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const { data: appInitData } = useAppInitData();
const {
	refresh: refreshPromotionsData,
	homePromotions,
	isProfileCompleted,
	piggybankPromotion,
	handleSecretOffer,
	openCash,
	refreshHomeData
} = usePromotionsData({ immediate: false });
const { entriesSum, activeStatus, isSubscribed, tournamentData, durationLeft, coinsSum, handleSubscribe } = useRace({
	timerFormat: "H[ h ]mm[ m ]ss[ s]"
});
const { piggyData, isActiveStatus, isFullStatus, readyToBreak, handlePiggyClick, refreshPiggyData } = usePiggy({
	open
});
const { data: bonusesData, refresh: refreshBonusesData } = useBonusesData();
const loginGuard = useLoginGuard();
const { add } = useSockets();
const { depositInfoData, durationLeft: depositDurationLeft } = useRabbitDepositStreak();
const { durationLeftFinished } = usePrizeDrop();
const {
	durationLeft: durationLeftStreak,
	isAvailable,
	levels,
	activeLevel,
	isCompleted,
	isActiveDailyLogin,
	handleOpenFreeSpinGame
} = useDailyLogin();

const sliderRef = ref();

const dailyWheelTimer = computed(() => (bonusesData.value?.dailyWheel?.reasonType as "time") === "time");
const dailyWheelTime = computed(() => (dailyWheelTimer.value ? bonusesData.value?.dailyWheel?.reasonValue : ""));
const showPiggyBank = computed(
	() => !!piggybankPromotion && isActiveStatus.value && !!appInitData.value?.moneyBox?.isActive
);
const secretOffer = computed(() => homePromotions.value?.promotions?.find((promotion) => promotion.secretOffer));

const handleRaceClick = () => {
	loginGuard({
		success: () => {
			if (!activeStatus.value) {
				router.push("/tournaments/");
				return;
			}
			handleSubscribe({
				id: tournamentData.value?.data?.id || 0,
				isSub: isSubscribed.value
			});
			open("LazyOModalRacesGames", { isRaceMode: true });
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handlePiggyBankRefresh = () => {
	setTimeout(() => {
		refreshPiggyData();
	}, 1000);
};

const handleSecretOfferClick = (promotion: Promotion) => {
	loginGuard({
		success() {
			handleSecretOffer(promotion);
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleInviteClick = (promotion: Promotion) => {
	loginGuard({
		success: () => {
			const referralUrl = promotion.data?.referralUrl || "";

			copy(referralUrl);
			toast.success(t("Link copied"), {
				containerId: "referralModal",
				icon: h("i", { class: "toast-icon icon-checked" }),
				closeButton: () => h("i", { class: "toast-icon icon-close" }),
				theme: toast.THEME.DARK,
				position: isDesktop ? toast.POSITION.TOP_RIGHT : toast.POSITION.TOP_CENTER,
				transition: toast.TRANSITIONS.SLIDE,
				autoClose: 5000
			});
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleClick = (promotion: Promotion & { secretOffer?: boolean }) => {
	loginGuard({
		success: () => {
			if (promotion.type === "invite") {
				return handleInviteClick(promotion);
			}
			if (promotion.type === "inviteQualification") {
				window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
			}
			if (promotion.type === "dailyWheel") {
				return open("LazyOModalDailyWheel");
			}
			if (promotion?.data?.enabled) {
				dispatchGAEvent({ event: "click_button", location: "home", buttonName: "make_deposit" });
				window?.$store?.gaCash?.deposit?.({
					location: "home",
					step: "view_payments_window",
					activePackage: promotion?.data?.money
				});

				openCash(promotion);
			}
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handlePromotionsRefresh = async ({ type }: { type?: "dailyWheel" } = {}) => {
	if (type === "dailyWheel") {
		await refreshBonusesData();
	}
	await refreshPromotionsData();
	await nextTick();
	sliderRef.value?.slider.update();
};

const getImgFullPath = (src: string) => {
	const isNuxtImg = src.includes("nuxt-img");
	return isNuxtImg ? src : `${baseImageUrl}${src}`;
};

const goToPromotionPage = () => {
	loginGuard({
		success: () => {
			router.push("/promotions/");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

add("promotionsPage", ({ action }: EventData<Promotion>) => {
	if (action === "update") {
		refreshHomeData();
		refreshPromotionsData();
	}
});

watch(
	[homePromotions, bonusesData],
	async () => {
		await nextTick();
		sliderRef.value?.slider.update();
	},
	{ immediate: true }
);
</script>

<template>
	<div>
		<section class="content">
			<div class="top">
				<AText variant="tianshui" class="text-cannes title" :modifiers="['bold']">
					{{ t("Rabbit’s Promotions") }}
				</AText>
				<div
					v-if="secretOffer || isGuest"
					class="secret-offer"
					:style="{
						backgroundImage:
							'url(' +
							(secretOffer?.data?.promoOfferPreset?.imageMenu ||
								prepareImgUrl(`/nuxt-img/promotions/secret-offer.png`, { format: 'webp' })) +
							')'
					}"
				>
					<AText variant="tempe" class="title" :modifiers="['normal']">
						{{ secretOffer?.data?.promoOfferPreset?.subTitle || t("Secret Offer") }}
					</AText>
					<AText v-if="!isProfileCompleted || isGuest" variant="taipei" class="desc" :modifiers="['semibold']">
						{{ secretOffer?.data?.promoOfferPreset?.title || t("Only one step to unlock the offer") }}
					</AText>
					<div v-else>
						<MPrizeFund
							v-if="secretOffer?.data?.promoOfferPreset?.coins"
							variant="coins"
							img="/nuxt-img/prizes/rabbitcoins.png"
							:icon-size="26"
						>
							<AText variant="tanzay" class="text-count" :modifiers="['semibold', 'uppercase']">
								{{ shortNumberFormatter(secretOffer?.data?.promoOfferPreset?.coins, { withPrefix: true }) }}
							</AText>
						</MPrizeFund>

						<div class="multi-prize-wrapper">
							<AText
								v-if="secretOffer?.data?.promoOfferPreset?.coins && secretOffer?.data?.promoOfferPreset?.entries"
								variant="taipei"
								:modifiers="['semibold', 'uppercase']"
								>+</AText
							>
							<MPrizeFund
								v-if="secretOffer?.data?.promoOfferPreset?.entries"
								variant="entries"
								:class="{
									'multi-prize':
										secretOffer?.data?.promoOfferPreset?.coins && secretOffer?.data?.promoOfferPreset?.entries
								}"
								img="/nuxt-img/prizes/supercoins@2x.png"
								:icon-size="22"
							>
								<AText variant="taipei" :modifiers="['semibold', 'uppercase']" class="text-count">
									{{ shortNumberFormatter(secretOffer?.data?.promoOfferPreset?.entries, { fractionDigits: 2 }) }}
								</AText>
								<AText variant="ternopil" class="count-after" :modifiers="['semibold', 'uppercase']">
									{{ t("Free Super Coins") }}
								</AText>
							</MPrizeFund>
						</div>
					</div>

					<AButton variant="primary" size="lg" class="btn" @click="handleSecretOfferClick(secretOffer)">
						<template v-if="isProfileCompleted">
							<AText variant="toledo" :modifiers="['medium']">
								{{ t("Buy Now") }}
							</AText>
							<AText
								v-if="secretOffer?.data?.promoOfferPreset?.usualPrice"
								variant="toledo"
								:modifiers="['medium']"
								class="text-coyoacan"
							>
								$<s>{{ secretOffer?.data?.promoOfferPreset?.usualPrice.toFixed(2) }}</s>
							</AText>

							<AText variant="toledo" :modifiers="['medium']">${{ secretOffer?.data?.money }}</AText>
						</template>
						<template v-else>
							<AText :modifiers="['medium']" variant="toledo">
								{{ t("Unlock") }}
							</AText>
						</template>
					</AButton>
				</div>
			</div>
			<ASlider v-if="homePromotions?.promotions" ref="sliderRef" :options="sliderOptions" class="row-cards">
				<div v-for="(card, index) in homePromotions.promotions" :key="index" class="keen-slider__slide">
					<OPromotionsCardSkeleton v-if="'skeleton' in card" />
					<template v-else>
						<AAnimationHoverSlide v-if="card.type === 'bingo'" :active="isDesktop">
							<div class="wrapper">
								<MPromotionBingo />
							</div>
						</AAnimationHoverSlide>
						<AAnimationHoverSlide v-else-if="card.type === 'tournament'" :active="isDesktop">
							<div class="wrapper">
								<MPromotionRace
									type="tournament"
									:image="getImgFullPath(card.package?.imagePopup || '')"
									:title="tournamentData?.data?.title || ''"
									:entries="entriesSum"
									:coins="coinsSum"
									:dateFinish="durationLeft"
									:isActive="activeStatus"
									:dateStart="tournamentData?.data?.start"
									:isSubscribed="isSubscribed"
									:subTitle="card.package?.subTitle || ''"
									@click="handleRaceClick"
								/>
							</div>
						</AAnimationHoverSlide>

						<AAnimationHoverSlide v-else-if="card?.secretOffer" :active="isDesktop">
							<div class="wrapper">
								<MPromotionSecretOffer
									:type="card.type"
									:image="getImgFullPath(card.package?.imagePopup || '')"
									:title="card.package?.title || ''"
									:subTitle="card.package?.subTitle || ''"
									:coins="card.package?.coins || 0"
									:entries="card.package?.entries || 0"
									:money="card.data?.money"
									:badge="card.badgeLabel"
									:imageBadge="getImgFullPath(card.data?.promoOfferPreset?.imageBadge || '')"
									currencySymbol="$"
									:promotionInfo="card.hasPromotionInfo"
									:usualPrice="card.useualPrice"
									:profileCompleted="isProfileCompleted"
									:bestDeal="!!card.data?.promoOfferPreset?.bestDeal"
									:mostPopular="!!card.data?.promoOfferPreset?.mostPopular"
									:subType="card.data?.promoOfferPreset?.subType"
									@click="handleSecretOfferClick(card)"
								/>
							</div>
						</AAnimationHoverSlide>

						<AAnimationHoverSlide v-else-if="card.type === 'dailyWheel'" :active="isDesktop">
							<div class="wrapper">
								<MPromotionItem
									:type="card.type"
									:class="card.type"
									:enabled="true"
									:showAvailable="dailyWheelTimer"
									:image="
										isDesktop
											? getImgFullPath(card.package?.imagePopup || '')
											: getImgFullPath(card.package?.imagePopupMobile || '')
									"
									:title="card.package?.title || ''"
									:subTitle="card.package?.subTitle || ''"
									:coins="card.package?.coins || 0"
									:entries="card.package?.entries || 0"
									:badge="card.badgeLabel"
									:date="dailyWheelTime"
									:imgWidth="card.type === 'dailyWheel' && isDesktop ? 648 : 316"
									dateFormat="HH[ h ]mm[ m ]ss[ s ]"
									:imgHeight="392"
									@click="handleClick(card)"
									@on-count-down-stop="handlePromotionsRefresh({ type: 'dailyWheel' })"
								/>
							</div>
						</AAnimationHoverSlide>

						<AAnimationHoverSlide v-else-if="card.type === 'depositStreak'" :active="isDesktop">
							<div class="wrapper">
								<MPromotionDepositStreak
									type="depositStreak"
									:image="getImgFullPath(card.package?.imagePopup || '')"
									:deposit-info="depositInfoData"
									:finished-at="depositDurationLeft"
								/>
							</div>
						</AAnimationHoverSlide>

						<AAnimationHoverSlide v-else-if="card.type === 'prizeDrops'" :active="isDesktop">
							<div class="wrapper">
								<MPromotionPrizeDrops
									type="depositStreak"
									:image="getImgFullPath(card.package?.imagePopup || '')"
									:finished-at="durationLeftFinished || ''"
								/>
							</div>
						</AAnimationHoverSlide>

						<AAnimationHoverSlide v-else-if="card.type === 'dailyLogin' && isActiveDailyLogin" :active="isDesktop">
							<div class="wrapper">
								<MPromotionDailyLogin
									type="dailyLogin"
									:image="getImgFullPath(card.package?.imagePopup || '')"
									:durationLeft="durationLeftStreak"
									:isAvailable="isAvailable"
									:levels="levels"
									:activeLevel="activeLevel"
									:isCompleted="isCompleted"
								/>
							</div>
						</AAnimationHoverSlide>

						<AAnimationHoverSlide v-else-if="card.type === 'playerFreeSpin'" :active="isDesktop">
							<div class="wrapper">
								<MPromotionDailyLoginFreeSpin
									type="playerFreeSpin"
									:gameId="card.data?.gameId"
									@click="handleOpenFreeSpinGame(Number(card.data?.gameId ?? 0))"
								/>
							</div>
						</AAnimationHoverSlide>

						<AAnimationHoverSlide v-else-if="!['dailyLogin'].includes(card.type || '')" :active="isDesktop">
							<div class="wrapper">
								<MPromotionItem
									:type="card.type"
									:showAvailable="!!card.data?.promoOfferPreset?.availableTill"
									:enabled="card.data?.enabled"
									:image="
										isDesktop
											? getImgFullPath(card.package?.imagePopup || '')
											: getImgFullPath(card.package?.imagePopupMobile || '')
									"
									:title="card.package?.title || ''"
									:subTitle="card.package?.subTitle || ''"
									:coins="card.package?.coins || 0"
									:entries="card.package?.entries || 0"
									:piggyCoins="card.data?.promoOfferPreset?.moneyBoxAmount || 0"
									:badge="card.badgeLabel"
									:bestDeal="!!card.data?.promoOfferPreset?.bestDeal"
									:mostPopular="!!card.data?.promoOfferPreset?.mostPopular"
									:money="card.data?.money"
									currencySymbol="$"
									:textTooltip="card.tooltipText"
									:magicBoxAvailable="bonusesData?.isMagicBoxAvailable"
									:magicBoxDate="bonusesData?.dailyChests?.reasonValue || ''"
									:promotionInfo="card.hasPromotionInfo"
									:usualPrice="card.useualPrice"
									:date="card.date"
									:subType="card.data?.promoOfferPreset?.subType"
									:profileCompleted="isProfileCompleted"
									:secretOffer="card?.secretOffer"
									:imgWidth="316"
									:imgHeight="392"
									:imageBadge="getImgFullPath(card.data?.promoOfferPreset?.imageBadge || '')"
									@click="handleClick(card)"
									@on-count-down-stop="handlePromotionsRefresh"
								/>
							</div>
						</AAnimationHoverSlide>
					</template>
				</div>
				<div v-if="showPiggyBank" class="keen-slider__slide">
					<AAnimationHoverSlide>
						<div class="wrapper">
							<MPromotionPiggybank
								type="piggyBank"
								:image="getImgFullPath(piggybankPromotion?.imagePopup || '')"
								:title="piggybankPromotion?.title || ''"
								:date="piggyData?.availableTimeToBreak ?? ''"
								:complete="piggyData?.moneyBoxAmount ?? 0"
								:minPromoCost="piggyData?.promoOffersPresets?.money ?? 0"
								:goal="piggyData?.maxAmount ?? 0"
								:isFullStatus="isFullStatus"
								:isAvailable="readyToBreak"
								@choose-offer="handlePiggyClick"
								@on-count-down-stop="handlePiggyBankRefresh"
							/>
						</div>
					</AAnimationHoverSlide>
				</div>
			</ASlider>
		</section>
		<div class="action">
			<AButton variant="secondary" class="btn" size="lg" @click="goToPromotionPage">
				<AText variant="toledo" :modifiers="['medium']">
					{{ t("See All Promotions") }}
				</AText>
			</AButton>
		</div>
	</div>
</template>

<style scoped lang="scss">
:deep() {
	.keen-slider {
		padding-top: gutter(1.5);

		@include media-breakpoint-down(sm) {
			flex-direction: column;
			gap: 16px;

			.keen-slider__slide {
				transform: none !important;
			}

			.card {
				width: 100%;
			}
		}

		.wrapper {
			width: auto;
			height: 420px;
		}
	}
}

.top {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.secret-offer {
	width: 648px;
	height: 194px;
	padding: 16px 24px;
	border-radius: 24px;
	border: 2px solid var(--cordoba);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	@include media-breakpoint-down(lg) {
		@media (orientation: landscape) {
			display: none;
		}
	}

	@include media-breakpoint-down(md) {
		display: none;
	}

	&:hover {
		cursor: pointer;
	}

	.desc {
		max-width: 300px;
		margin-top: 4px;
	}

	.btn {
		min-width: 165px;
		margin-top: auto;
		gap: 4px;
	}
}

.title {
	max-width: 400px;
	line-height: 1.14;
}

.content {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	margin: auto;
	z-index: 19;

	@include media-breakpoint-down(lg) {
		gap: 16px;
	}
}

.row-cards {
	display: flex;
	flex-wrap: wrap;

	@include media-breakpoint-down(lg) {
		justify-content: center;
	}

	* {
		box-sizing: border-box;
	}
}

.action {
	width: 100%;
	position: relative;
	margin-top: gutter(4);
}

.multi-prize-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
}

.btn {
	padding: 0 16px;

	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}
</style>
