import { toast, type ToastOptions } from "vue3-toastify";

import useAppInitData from "./useAppInitData";
import useGetPrizeDropsInfoData from "./useGetPrizeDropsInfoData";
import useLoginGuard from "./useLoginGuard";
import useSockets from "./useSockets";

type EventData<TData = unknown> = {
	type: string;
	action: "prizeAssigned";
	data: TData;
};

type PrizeDropsSocket = {
	playerId: string;
	playerName: string;
	prizeValue: string;
	prizeLevel: string;
	nextPrizePoolStartedAt: string;
	nextPrizeWillAccrueIn: number;
	board: {
		prizes: [
			{
				prizeValue: number;
				prizeLeft: number;
			}
		];
		lastWinners: [
			{
				playerName: string;
				prizeValue: number;
			}
		];
	};
};

const PRIZE_LEVEL = {
	"1": "bronze",
	"2": "silver",
	"3": "gold"
} as Record<string, string>;

export type PrizeDropsLevel = "1" | "2" | "3";

type ModalOpen<K extends string, P> = (name: K, props?: P) => void;

const DEFAULT_TOAST_OPTIONS = {
	theme: toast.THEME.DARK,
	position: toast.POSITION.TOP_CENTER,
	autoClose: 5000
};

const usePrizeDropSocket = <T extends (key: string, params?: {}) => string, K extends string, P>({
	reversePrizes = true,
	t,
	open,
	showToast,
	showTimeToast,
	resetTimer,
	toNextPrizeDurationLeft,
	toastOptions = {},
	withoutToast = false
}: {
	t: T;
	open: ModalOpen<K, P>;
	reversePrizes?: boolean;
	toNextPrizeDurationLeft?: ComputedRef<string>;
	showToast?: (name: string, prize: string, prizeLevel: PrizeDropsLevel) => void;
	showTimeToast?: (time: number) => void;
	resetTimer?: (time: number) => void;
	toastOptions?: ToastOptions;
	withoutToast?: boolean;
}) => {
	const { data: appInitData } = useAppInitData();
	const { data: prizeDropsData } = useGetPrizeDropsInfoData({
		immediate: !!appInitData.value?.prizeDrops?.isActive
	});
	const { add, remove } = useSockets();
	const loginGuard = useLoginGuard();
	const toastId = ref();
	const { vibrate, isSupported } = useVibrate({ pattern: [200, 100, 200, 100, 200] });

	add("prizeDrops", ({ data: dataSocket, action }: EventData<PrizeDropsSocket>) => {
		loginGuard({
			success() {
				if (action === "prizeAssigned" && prizeDropsData.value && appInitData.value?.prizeDrops?.isActive) {
					prizeDropsData.value.prizes = reversePrizes
						? dataSocket?.board?.prizes?.reverse()
						: dataSocket?.board?.prizes;
					prizeDropsData.value.lastWinners = dataSocket?.board?.lastWinners;
					prizeDropsData.value.nextPrizeWillAccrueIn = dataSocket?.nextPrizeWillAccrueIn;

					if (resetTimer) {
						resetTimer(dataSocket?.nextPrizeWillAccrueIn);
					}

					if (prizeDropsData.value?.general?.nextPrizePoolStartedAt !== undefined) {
						prizeDropsData.value.general.nextPrizePoolStartedAt = dataSocket.nextPrizePoolStartedAt;
					}

					if (dataSocket?.playerId === (appInitData.value?.login || "")) {
						open(
							"LazyOModalPrizeDropsWin" as K,
							{ prizeValue: dataSocket?.prizeValue, prizeLevel: dataSocket?.prizeLevel } as P
						);
						return;
					}

					const name = dataSocket?.playerName;
					const prize = numberFormat(Number(dataSocket?.prizeValue));
					const prizeLevel = dataSocket?.prizeLevel as PrizeDropsLevel;

					if (showToast) {
						if (isSupported.value) {
							vibrate();
						}
						showToast(name, prize, prizeLevel);
						return;
					}

					if (showTimeToast && process.client) {
						if (toNextPrizeDurationLeft?.value) {
							showTimeToast(Number(dataSocket?.nextPrizeWillAccrueIn));
						}
					}

					if (toast.isActive(toastId.value)) {
						toast.remove(toastId.value);
					}

					if (!withoutToast) {
						toastId.value = toast.success(
							`
													<div class="title">${t("Royal Drops")}</div>
													<div class="text"> ${t("User invader won Entries. Keep playing and get gifts.", { name, prize })}</div>
												`,
							{
								icon: h("img", {
									src: `/nuxt-img/prize-drops/royal/crown-${PRIZE_LEVEL[prizeLevel] || "bronze"}.png`,
									width: 40,
									height: 40
								}),
								dangerouslyHTMLString: true,
								containerId: "notification-prize-drops",

								transition: toast.TRANSITIONS.SLIDE,

								pauseOnFocusLoss: false,
								...DEFAULT_TOAST_OPTIONS,
								...toastOptions
							}
						);
					}
				}
			}
		});
	});

	onUnmounted(() => {
		remove("prizeDrops");
	});
};

export default usePrizeDropSocket;
