interface NumberFormatOptions {
	withSeparator?: boolean;
	fractionDigits?: number;
	withPrefix?: boolean;
	ignoreDigitsForInteger?: boolean;
}

const shortNumberFormatter = (value: number | string, options: NumberFormatOptions = {}): string => {
	const { withSeparator = true, fractionDigits = 0, withPrefix = false, ignoreDigitsForInteger = false } = options;

	if (typeof value === "string") {
		value = parseFloat(value);
		if (isNaN(value)) {
			throw new TypeError("Value must be a valid number or numeric string");
		}
	}

	if (typeof value !== "number") {
		throw new TypeError("Value must be a number");
	}

	const isInteger = Number.isInteger(value);

	let formattedValue = value.toFixed(fractionDigits);

	if (ignoreDigitsForInteger && isInteger) {
		formattedValue = value.toFixed(0);
	}

	if (withSeparator) {
		const parts = formattedValue.split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		formattedValue = parts.join(".");
	}

	if (withPrefix) {
		const [integerPartRaw, decimalPart] = formattedValue.split(".");
		let integerPart = integerPartRaw.replace(/,/g, "");

		if (integerPart.length > 9 && integerPart.endsWith("000000")) {
			integerPart = `${integerPart.slice(0, -6)}KK`;
		}

		if (integerPart.length > 6 && integerPart.endsWith("000")) {
			integerPart = `${integerPart.slice(0, -3)}K`;
		}

		formattedValue = integerPart;

		if (withSeparator) {
			formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}

		if (decimalPart && fractionDigits > 0 && (!ignoreDigitsForInteger || !isInteger)) {
			formattedValue += `.${decimalPart}`;
		}
	}

	return formattedValue;
};

export default shortNumberFormatter;
