<script setup lang="ts">
const { t } = useT();

const emit = defineEmits<{ (event: "closePopper"): void }>();

const { rewardsAmount } = useCheckRewards();
const { open } = useAppModals();

const handleClick = () => {
	open("LazyOModalOnboardingRewards");

	emit("closePopper");
};
</script>

<template>
	<MRewardsWrapper
		class="promo-reward"
		:button-text="t('onboarding.rewards.btn.start')"
		:title="t('onboarding.rewards.hub.title')"
		image="/nuxt-img/rewards/onboarding.png"
		@click="handleClick"
	>
		<AText :size="12" class="text-neutral-100" as="div">
			{{ t("banner.rewards.title", { step: rewardsAmount.activeCount }) }}
		</AText>
		<div class="prizes">
			<MPrizeFund v-if="rewardsAmount?.coins" variant="coins" icon="12/coins" :icon-size="20">
				<AText :modifiers="['semibold', 'uppercase']">
					{{ rewardsAmount.coins }}
				</AText>
			</MPrizeFund>
			<MPrizeFund v-if="rewardsAmount?.entries" variant="entries" icon="12/secret-coins" :icon-size="20">
				<AText :modifiers="['semibold', 'uppercase']">
					{{ rewardsAmount.entries }}
				</AText>
			</MPrizeFund>
		</div>
	</MRewardsWrapper>
</template>

<style lang="scss" scoped>
.promo-reward {
	.prizes {
		display: flex;
		gap: 4px;
		align-items: center;

		&:deep(.prize) {
			gap: 4px;
		}
	}
}
</style>
