import type { ExtractFromAPI, v1 } from "@netgame/openapi";
import { camelCase } from "scule";

import useAsyncFetch from "./useAsyncFetch";
import useGamesState from "./useGamesState";
import useIsGuest from "./useIsGuest";

type HomePageResponse = Required<ExtractFromAPI<v1.paths, "/rest/page/home/", "get">>;
type HomePagePayload = NonNullable<HomePageResponse["payload"]["games"]>["issues"];

const useHomeData = ({
	immediate,
	cached,
	server
}: { immediate?: boolean; cached?: boolean; server?: boolean } = {}) => {
	const isGuest = useIsGuest();
	const { add: addGames, select, getInfo } = useGamesState();

	type GamesObject = Record<string, ReturnType<typeof addGames>>;
	type GamesInfoObject = Record<string, ReturnType<typeof getInfo>>;

	const createGamesObjects = (issues?: HomePagePayload): { games: GamesObject; gamesInfo: GamesInfoObject } => {
		if (!issues) {
			return { games: {}, gamesInfo: {} };
		}

		return Object.keys(issues).reduce(
			(acc, category) => {
				const key = camelCase(category);
				const issue = { ...issues[category as keyof HomePagePayload], slug: category };

				if (issue) {
					acc.games[key] = addGames(issue.games || []);
					acc.gamesInfo[key] = getInfo(issue, key);
				}

				return acc;
			},
			{ games: {} as GamesObject, gamesInfo: {} as GamesInfoObject }
		);
	};

	const asyncData = useAsyncFetch({
		path: "/rest/page/home/",
		method: "get",
		options: {
			key: "homePage",
			transform: (data) => {
				const issues = data.payload?.games?.issues || {};
				const { games, gamesInfo } = createGamesObjects(issues);

				const gameOfWeek = data.payload?.games?.issues?.["popular-games"]?.gameOfWeek;

				return {
					collections: data.payload?.games?.collections || [],
					seo: data.seo,
					jackpots: data.payload.jackpots,
					banners: data.payload.banners,
					questData: data.payload.questData,
					infoPlate: data.payload.infoPlate,
					promotions: data.payload.promotions,
					gameOfWeek: addGames(gameOfWeek ? [gameOfWeek] : []),
					promoBanners: data.payload.promoBanners || [],
					schemeSections: data.payload.schemeSections || [],
					lastWinners: data.payload.lastWinners || [],
					packages: data.payload.packages || [],
					games,
					gamesInfo
				};
			},
			watch: [isGuest],
			server,
			cached: cached === false ? undefined : true,
			immediate
		}
	});

	const generateComputedGames = (gamesKey: string) =>
		computed(() => select(asyncData.data.value?.games?.[gamesKey] || []));

	const jackpotGames = generateComputedGames("jackpot");
	const holdNLinksGames = generateComputedGames("holdNLink");
	const gamesWithWeekGame = generateComputedGames("popularGames");
	const hotGames = generateComputedGames("hotGames");
	const newGames = generateComputedGames("newGames");
	const fishingGames = generateComputedGames("fishing");
	const featuredGames = generateComputedGames("featuredGames");
	const bonusBuyGames = generateComputedGames("bonusBuyGames");
	const missedItGames = generateComputedGames("missedItGames");
	const recommendGames = generateComputedGames("recommend");
	const intoTheWildGames = generateComputedGames("intoTheWild");

	return {
		...asyncData,
		jackpotGames,
		holdNLinksGames,
		fishingGames,
		gamesWithWeekGame,
		hotGames,
		newGames,
		featuredGames,
		bonusBuyGames,
		missedItGames,
		recommendGames,
		intoTheWildGames
	};
};

export default useHomeData;
