<script setup lang="ts">
defineProps<{
	timeLeft?: string;
}>();

const { t } = useT();
const { open } = useAppModals();

const handleClick = () => {
	open("LazyOModalDepositStreak");
};
</script>

<template>
	<MRewardsWrapper
		:button-text="t('rewardCenter.depositStreak.btn.name')"
		class="deposit-rewards"
		:title="t('rewardCenter.depositStreak.title')"
		:intro="t('rewardCenter.depositStreak.intro')"
		image="/nuxt-img/rewards/streak.png"
		@click="handleClick"
	>
		<div class="prizes">
			<MPrizeFund variant="coins" img="/nuxt-img/prizes/rabbitcoins.png" :icon-size="20">
				<AText :modifiers="['semibold', 'uppercase']">
					{{ t("rewardCenter.depositStreak.prize.coins") }}
				</AText>
			</MPrizeFund>
			<MPrizeFund variant="entries" img="/nuxt-img/prizes/supercoins@2x.png" :icon-size="20">
				<AText :modifiers="['semibold', 'uppercase']">
					{{ t("rewardCenter.depositStreak.prize.entries") }}
				</AText>
			</MPrizeFund>
		</div>
		<template #timeBox>
			<i18n-t keypath="rewardCenter.depositStreak.timer">
				<template #timer>
					{{ timeLeft }}
				</template>
			</i18n-t>
		</template>
	</MRewardsWrapper>
</template>

<style lang="scss" scoped>
.deposit-rewards .prizes {
	display: flex;
	gap: 4px;
	align-items: center;

	&:deep(.prize) {
		gap: 4px;
	}
}
</style>
