<script setup lang="ts">
const emit = defineEmits<{ (event: "closePopper"): void }>();

const { t } = useT();
const { open } = useAppModals();
const { data: bonusesData, refresh: refreshBonusesData } = useBonusesData();

const dailyWheelTimer = computed(() => {
	const dailyWheel = bonusesData.value?.dailyWheel;
	return !!dailyWheel?.visible && dailyWheel?.reasonType === "time";
});
const dailyWheelTime = computed(() => {
	const dailyWheelTime = bonusesData.value?.dailyWheel?.reasonValue || "";
	return dailyWheelTimer.value ? dailyWheelTime : "";
});

const updateDailyWheelData = () => {
	setTimeout(() => {
		refreshBonusesData();
	}, 1000);
};

const { durationLeft } = useCountdown({
	timestamp: dailyWheelTime.value,
	formatToken: "HH[h ]mm[m ]ss[s ]",
	onCountdownStop: () => updateDailyWheelData()
});

const handleClick = () => {
	if (dailyWheelTimer.value) {
		return;
	}

	dispatchGAEvent({
		event: "click_button",
		location: "reward_cabinet",
		button_name: "spin"
	});

	open("LazyOModalDailyWheel");
	emit("closePopper");
};
</script>

<template>
	<MRewardsWrapper
		:button-text="t('rewardCenter.dailyWheel.btn.name')"
		:title="t('rewardCenter.dailyWheel.title')"
		image="/nuxt-img/rewards/daily-wheel.png"
		:buttonDisabled="!!dailyWheelTimer"
		@click="handleClick"
	>
		<template #timeBox>
			<i18n-t v-if="!!dailyWheelTimer" keypath="rewardCenter.dailyWheel.timer">
				<template #timer>
					{{ durationLeft }}
				</template>
			</i18n-t>
		</template>
	</MRewardsWrapper>
</template>
