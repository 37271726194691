import { useThrottleFn, useStorage } from "@vueuse/core";

import useAppInitData from "./useAppInitData";
import useAsyncFetch from "./useAsyncFetch";
import useCountdown from "./useCountdown";
import useEvent from "./useEvent";
import useHomeData from "./useHomeData";
import useIsGuest from "./useIsGuest";
import useModals from "./useModals";
import type { EventData } from "./useNotificationCenter";
import useSockets from "./useSockets";

type ModalOpen<K extends string, P> = (name: K, props?: P) => void;
type ModalClose<K extends string> = (name: K) => void;
type ModalIsOpen<K extends string> = (name: K) => boolean;

const DELTA_SCROLL = 1500;

const useExitOffer = <K extends string, P>(
	open: ModalOpen<K, P>,
	close: ModalClose<K>,
	isOpen: ModalIsOpen<K>,
	isCheckOtherModals?: boolean
) => {
	const endTime = useState("endTimeState", () => 0);
	const { data: appInitData } = useAppInitData();
	const { refresh: refreshPageHomeData } = useHomeData();
	const { isMobile } = useDevice();
	const route = useRoute();
	const isGuest = useIsGuest();
	const { add } = useSockets();
	const exitPopupTime = useStorage<string | null>("exitPopupTime", null);
	const isShowExitPopup = useStorage<boolean | null>("isShowExitPopup", null);
	const { modals } = useModals();

	const lastScrollTop = ref(0);

	const { data, refresh: refreshExitPromoData } = useAsyncFetch({
		path: "/rest/cash/promo-offer-preset/exit/",
		method: "get",
		options: { cached: true, immediate: !isGuest.value }
	});

	const { refresh: refreshPromotionsPageData } = useAsyncFetch({
		path: "/rest/page/promotions/",
		method: "get",
		options: {
			immediate: false
		}
	});

	const userDepositsCount = computed(() => appInitData.value?.depositsCount);
	const promoOffer = computed(() => data.value?.exitPopupPromoOfferPreset);
	const isShowExitModal = computed(() => isClient && exitPopupTime.value && isShowExitPopup.value && promoOffer.value);
	const isActiveUser = computed(() => !isGuest.value && promoOffer.value && !isOpen("LazyOModalGame" as K));
	const isNotHomePage = computed(() => !["/", "/en", "/en/"].includes(route.path));

	const handleOpenCash = (gaLocation?: string) => {
		if (!promoOffer.value) {
			return;
		}

		const url = promoOffer.value?.promoOfferPreset
			? `/cash/deposit-by-money/${promoOffer.value?.promoOfferPreset.id}/promoOffer/`
			: `/cash/deposit-by-money/${promoOffer.value?.preset.id}/preset/`;

		dispatchGAEvent({
			event: "click_button",
			button_name: "open_cash",
			location: gaLocation || ""
		});

		window?.$cash?.$router?.push?.(url);
	};

	const resetTimer = () => {
		endTime.value = 0;
	};

	const { durationLeft, durationExceeded, reset } = useCountdown({
		timestamp: endTime.value,
		formatToken: "mm[:]ss",
		onCountdownStop: resetTimer
	});

	const startTimer = () => {
		if (isCheckOtherModals && (modals.value?.items.size > 0 || window?.$cash?.$store?.getters?.["cash/isOpen"])) {
			return false;
		}

		if (isClient && !isShowExitPopup.value) {
			const time = Date.now() + 10 * 60 * 1000;
			exitPopupTime.value = time.toString();
			isShowExitPopup.value = true;
			endTime.value = time;
			open("LazyOModalExitOffer" as K);
		}
	};

	const onBackButtonPress = () => {
		if (isActiveUser.value && isMobile) {
			startTimer();
		}
	};

	const handleMouseLeave = () => {
		if (!isActiveUser.value || (isNotHomePage.value && !route.path.includes("how-it-works"))) {
			return;
		}
		startTimer();
	};

	const handleScroll = useThrottleFn(() => {
		if (!isActiveUser.value || (isNotHomePage.value && !route.path.includes("promotions")) || !isMobile) {
			return;
		}

		const currentScrollTop = document.documentElement.scrollTop;
		const scrollDirection = currentScrollTop > lastScrollTop.value ? "down" : "up";
		const scrollDifference = Math.abs(currentScrollTop - lastScrollTop.value);

		if (scrollDifference > DELTA_SCROLL) {
			lastScrollTop.value = currentScrollTop;

			if (scrollDirection === "up") {
				startTimer();
			}
		}
	}, 200);

	useEvent("scroll", handleScroll);
	useEvent("popstate", onBackButtonPress, { capture: true });

	if (process.client) {
		watch(endTime, (value) => {
			reset(value || 0);

			if (!value) {
				exitPopupTime.value = null;
				close("LazyOModalExitOffer" as K);
			}
		});

		watch(userDepositsCount, () => {
			exitPopupTime.value = null;
			resetTimer();

			setTimeout(() => {
				refreshExitPromoData();
			}, 2000);
		});

		watch(promoOffer, (value) => {
			if (!value && isOpen("LazyOModalExitOffer" as K)) {
				close("LazyOModalExitOffer" as K);
			}
		});
	}

	add("promotionsPage", ({ action }: EventData<unknown>) => {
		if (action === "update") {
			refreshPageHomeData();
			refreshPromotionsPageData();
			refreshExitPromoData();
		}
	});

	add("activity", ({ action }: EventData<unknown>) => {
		if (action === "day_24h") {
			if (isClient && !exitPopupTime.value) {
				isShowExitPopup.value = null;
			}
		}
	});

	onMounted(() => {
		if (isClient && exitPopupTime.value) {
			endTime.value = Number(exitPopupTime.value);
		}
		document.addEventListener("mouseleave", handleMouseLeave);
	});

	onUnmounted(() => {
		document.removeEventListener("mouseleave", handleMouseLeave);
	});

	return {
		endTime,
		durationLeft,
		durationExceeded,
		isShowExitModal,
		promoOffer,
		handleOpenCash
	};
};

export default useExitOffer;
