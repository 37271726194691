<script lang="ts" setup>
const isGuest = useIsGuest();

const { t } = useT();

const { packages } = useHomePage({ immediate: !isGuest.value });

const paymentMethods = [
	"/nuxt-img/quick-buy/mastercard.png",
	"/nuxt-img/quick-buy/visa.png",
	"/nuxt-img/quick-buy/ApplePay.png",
	"/nuxt-img/quick-buy/discover.png"
];

const scrollComponent = ref();

const allPackages = computed(() => {
	const promoPackages = [];

	const { promoOffers, packages: allPackages } = packages.value || {};

	if (promoOffers) {
		if (promoOffers.bestDeal) {
			promoPackages.push({
				money: promoOffers.bestDeal.money,
				promoOfferPreset: promoOffers.bestDeal,
				preset: null,
				type: "bestDeal"
			});
		}

		if (promoOffers.mostPopular) {
			promoPackages.push({
				money: promoOffers.mostPopular.money,
				promoOfferPreset: promoOffers.mostPopular,
				preset: null,
				type: "mostPopular"
			});
		}
	}

	const combinedPackages = [...(allPackages || []), ...promoPackages];

	return combinedPackages.sort((a, b) => {
		if (a.type === "bestDeal" && b.type !== "bestDeal") {
			return -1;
		}
		if (a.type !== "bestDeal" && b.type === "bestDeal") {
			return 1;
		}
		if (a.type === "mostPopular" && b.type !== "mostPopular") {
			return -1;
		}
		if (a.type !== "mostPopular" && b.type === "mostPopular") {
			return 1;
		}
		return 1;
	});
});
</script>

<template>
	<div class="quick-buy">
		<header class="header">
			<AText class="title text-cannes" :size="32" as="h3" :modifiers="['bold']">
				<span>{{ t("quickBuy.title") }}</span>
			</AText>
		</header>

		<AHorizontalScroll ref="scrollComponent" shadowEffect>
			<OHomeQuickBuyItem v-for="(offer, i) in allPackages" :key="i" :offer="offer" />
		</AHorizontalScroll>

		<footer class="footer">
			<AText :size="24" :modifiers="['semibold']">{{ t("quickBuy.payments.title") }}</AText>

			<div class="payment-methods">
				<NuxtImg
					v-for="(paymentMethod, index) in paymentMethods"
					:key="index"
					:src="paymentMethod"
					alt="payments"
					format="webp"
					width="70"
					height="48"
					loading="lazy"
				/>
			</div>
		</footer>
	</div>
</template>

<style lang="scss" scoped>
.quick-buy {
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;

	@include media-breakpoint-down(md) {
		margin: 15px 0;
	}

	&:deep(.scroll-container) {
		gap: 12px;

		@include media-breakpoint-down(md) {
			gap: 8px;
		}
	}

	.navigation-holder {
		position: absolute;
		right: 0;
		top: 0;

		@include media-breakpoint-down(md) {
			top: -6px;
		}
	}

	.header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 32px;
		.title {
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}

	.footer {
		--border-width: 2px;
		--sideElementWidth: 14px;

		margin-top: 32px;
		padding: 0 20px 0 calc(var(--sideElementWidth) + 20px);
		height: 112px;
		display: flex;
		align-items: center;
		gap: 16px;
		position: relative;
		background: linear-gradient(to right, rgba(235, 255, 7, 0.3) -100%, var(--neutral-900));

		background-clip: padding-box;
		border: var(--border-width) solid transparent;
		border-radius: 1em;

		@include media-breakpoint-down(md) {
			--sideElementWidth: 8px;

			margin-top: 24px;
			padding: 24px 16px 24px calc(var(--sideElementWidth) + 16px);
			height: auto;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
		}

		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -1;
			margin: calc(-1 * var(--border-width));
			border-radius: inherit;
			background: linear-gradient(to right, var(--primary-500) -30%, var(--neutral-900));
			background-size: 101% 100%;
		}

		&:after {
			content: "";
			position: absolute;
			width: var(--sideElementWidth);
			background: var(--primary-500);
			top: 0;
			bottom: 0;
			left: 0;
			margin: auto 0;
			height: 65%;
			border-radius: 0 50px 50px 0;
			box-shadow: 0px 0px 10px var(--primary-500);
		}

		.payment-methods {
			display: flex;
			flex-wrap: wrap;
			gap: 18px;

			@include media-breakpoint-down(md) {
				gap: 8px;
			}
		}
	}
}
</style>
