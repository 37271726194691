<script setup lang="ts">
import type { Promotion } from "~/types";

import { Dayjs } from "dayjs";

type PromotionType = Promotion["type"];

const { t } = useT();
const { isDesktop } = useDevice();

const props = withDefaults(
	defineProps<{
		showAvailable?: boolean;
		enabled?: boolean;
		image: string;
		subTitle: string;
		title: string;
		coins: number;
		entries: number;
		piggyCoins?: number;
		date: string | number | Dayjs;
		type?: PromotionType;
		bestDeal: boolean;
		mostPopular: boolean;
		badge?: Record<string | number, string | number | boolean | null | undefined>;
		currencySymbol?: string;
		money?: string;
		textTooltip?: string;
		magicBoxDate?: string;
		magicBoxAvailable?: boolean;
		promotionInfo?: boolean;
		usualPrice?: string;
		applePayEnabled?: boolean;
		lastPayProvider?: object | null;
		subType?: string | null;
		vipClubEnabled?: boolean;
		vipSpinEnabled?: boolean;
		imgWidth?: number;
		imageBadge?: string;
		questTitle?: string;
		imgHeight?: number;
		dateFormat?: string;
	}>(),
	{
		imgWidth: 80,
		imgHeight: 100,
		dateFormat: "H[ h ]mm[ m ]ss[ s]"
	}
);

const emit = defineEmits<{ (event: "click"): void; (event: "onCountDownStop"): void }>();
const { start: handleStartAnimation } = useTimeout(2000, { controls: true, immediate: false });
const { start: handleStartPopper } = useTimeout(2000, { controls: true, immediate: false });
const { hasDeposits, isVerificationCompleted } = useProfileData();
const isAnimated = ref(false);

const stopAnimationState = () => {
	isAnimated.value = false;
};

const handleClickWithDelay = () => {
	emit("click");
};

const handleClick = () => {
	handleStartAnimation();
	handleStartPopper();

	if ((props.applePayEnabled || props.lastPayProvider) && hasDeposits.value && isVerificationCompleted.value) {
		isAnimated.value = true;

		return;
	}

	emit("click");
};

useEvent(["LEAVE_CASH"], () => {
	stopAnimationState();
});

const onCountDownStop = () => {
	if (props.enabled && props.showAvailable) {
		emit("onCountDownStop");
	}
};

const { durationLeft: promotionTime, reset } = useCountdown({
	timestamp: props.date,
	formatToken: props.dateFormat,
	onCountdownStop: onCountDownStop
});

watch(
	() => props.date,
	(value) => {
		if (value) {
			reset(value);
		}
	}
);
</script>

<template>
	<AAnimationTranslateFlip
		:isDesktop="isDesktop"
		:animationState="isAnimated"
		@handle-click-with-delay="handleClickWithDelay"
	>
		<ACard
			:imgAttrs="{
				width: imgWidth,
				height: imgHeight,
				src: image
			}"
			:width="type === 'dailyWheel' && isDesktop ? '648px' : '316px'"
			height="392px"
			:class="[
				`card-${type}`,
				{
					'card-disabled':
						(!enabled && type !== 'quest' && type !== 'invite') || (!magicBoxAvailable && type === 'magicBox')
				},
				{ 'card-popular': mostPopular },
				{ 'card-best': bestDeal },
				{ 'card-sub-type': subType }
			]"
			@click="handleClick"
		>
			<template v-if="!enabled && !['quest', 'invite', 'inviteQualification'].includes(type as string)" #default>
				<div class="lock">
					<ASvg name="80/lock" class="icon" />
					<AText variant="tanzay" class="text-tlalnepantla" :modifiers="['semibold']">
						{{ textTooltip }}
					</AText>
				</div>
			</template>
			<template #top>
				<AAnimationBlink v-if="type === 'dailyWheel'" />

				<div class="badge-wrapper">
					<ABadge
						v-if="bestDeal || mostPopular"
						:background="bestDeal ? 'var(--goeteborg)' : 'var(--greenburgh)'"
						variant="info"
						autosize
					>
						<AText variant="topeka" class="badge text-cannes" :modifiers="['semibold', 'uppercase']">
							{{ bestDeal ? t("Best deal") : t("Most popular") }}
						</AText>
					</ABadge>

					<ABadge v-if="subType" class="super" background="var(--guanare)" variant="info" autosize>
						<AText variant="topeka" class="badge text-cannes" :modifiers="['semibold', 'uppercase']">
							{{ t("Super offer ") }} {{ isAnimated }}
						</AText>
					</ABadge>

					<ABadge v-if="type === 'quest'" background="var(--goeteborg)" variant="info" autosize>
						<AText variant="topeka" class="text-carabanchel badge" :modifiers="['semibold', 'uppercase']">
							{{ t("Complete & win real prizes") }}
						</AText>
					</ABadge>

					<ABadge
						v-if="['invite', 'dailyWheel'].includes(type as keyof PromotionType)"
						class="free"
						background="var(--carabanchel)"
						variant="info"
						autosize
					>
						<AText variant="topeka" class="badge text-coro" :modifiers="['semibold', 'uppercase']">
							{{ t("Free activity") }}
						</AText>
					</ABadge>

					<ABadge
						v-if="showAvailable && enabled"
						class="label-time"
						background="var(--carabanchel)"
						variant="info"
						autosize
					>
						<AText variant="topeka" class="badge text-cannes" :modifiers="['semibold', 'uppercase']">
							{{ t("Ends in:") }} {{ promotionTime }}
						</AText>
					</ABadge>
				</div>
			</template>
			<template #bottom>
				<div class="box" :class="{ 'box--black': ['dailyWheel', 'invite'].includes(type as string) }">
					<div v-if="badge && badge.percent && badge.text" class="label">
						<NuxtImg
							v-if="imageBadge"
							:src="imageBadge"
							class="icon"
							width="56"
							height="56"
							alt="Rabbit coins prize"
							format="avif"
						/>

						<AText variant="tempe" :modifiers="['medium']"> {{ badge?.percent }} </AText>
						<AText variant="topeka" :modifiers="['normal']"> {{ badge?.text }} </AText>
					</div>

					<AText v-if="subTitle" class="sub-title" variant="toledo" :modifiers="['semibold']">
						{{ subTitle }}
					</AText>

					<AText v-if="type === 'invite'" variant="tempe" class="description" :modifiers="['normal']">
						{{ title }}
					</AText>

					<AText v-if="type === 'dailyWheel'" variant="tempe" class="description" :modifiers="['normal']">
						{{ t("Spin the Lucky Wheel everyday to collect your free") }}
						<NuxtImg
							src="/nuxt-img/prizes/rabbitcoins.png"
							class="icon"
							width="20"
							height="20"
							alt="Rabbit coins prize"
							format="avif"
						/>
						&
						<NuxtImg
							src="/nuxt-img/prizes/supercoins.png"
							class="icon"
							width="20"
							height="20"
							alt="Super coins prize"
							format="avif"
						/>
					</AText>

					<div>
						<AText v-if="coins" variant="tanzay" :modifiers="['bold', 'uppercase']" class="count">
							<AText
								v-if="type === 'quest'"
								variant="ternopil"
								class="count-before"
								:modifiers="['semibold', 'uppercase']"
							>
								{{ t("Prize of the day") }}
							</AText>
							<NuxtImg
								src="/nuxt-img/prizes/rabbitcoins.png"
								class="icon"
								width="26"
								height="26"
								alt="Rabbit coins prize"
								format="avif"
							/>
							{{ shortNumberFormatter(coins, { withPrefix: true }) }}
						</AText>

						<AText v-if="entries" variant="taipei" :modifiers="['semibold', 'uppercase']" class="count">
							<AText
								v-if="type === 'quest'"
								variant="ternopil"
								class="count-before"
								:modifiers="['semibold', 'uppercase']"
							>
								{{ t("Prize of the day") }}
							</AText>
							<span v-if="coins && entries">+</span>
							<div class="prize-wrapper">
								<NuxtImg
									src="/nuxt-img/prizes/supercoins.png"
									class="icon"
									width="26"
									height="26"
									alt="Super coins prize"
									format="avif"
								/>
								<template
									v-if="!promotionInfo || type === 'magicBox' || type === 'verification' || type === 'rankLeague'"
								>
									{{ shortNumberFormatter(entries, { fractionDigits: 2, ignoreDigitsForInteger: true }) }}
									<AText variant="ternopil" class="count-after" :modifiers="['semibold']">
										{{ t("Free Super Coins") }}
									</AText>
								</template>
								<template v-else>
									{{ shortNumberFormatter(entries, { fractionDigits: 2, ignoreDigitsForInteger: true }) }}
								</template>
							</div>
						</AText>

						<div v-if="piggyCoins" class="promo-money-box">
							<NuxtImg src="/nuxt-img/piggy-bank/piggy-prize-icon.png" format="webp" alt="piggy-prize-icon" />
							<AText :size="24" :modifiers="['semibold']">{{ piggyCoins }}</AText>
							<AText :size="10" :modifiers="['uppercase', 'left', 'semibold']">
								<i18n-t keypath="Coins {br} for Piggy bank">
									<template #br>
										<br />
									</template>
								</i18n-t>
							</AText>
						</div>
					</div>

					<AButton v-if="type === 'emailLottery'" variant="primary" class="promo">
						<AText :modifiers="['bold', 'uppercase']">
							{{ t("Get email") }}
						</AText>
					</AButton>

					<template v-if="type === 'verification'">
						<AButton variant="primary" class="promo" size="s">
							<AText :modifiers="['semibold']">
								{{ t("Secure now") }}
							</AText>
						</AButton>
					</template>

					<template v-if="type !== 'verification'">
						<AButton v-if="type === 'magicBox'" variant="primary" size="lg" class="promo">
							<AText :modifiers="['medium']" variant="toledo">
								{{ t("Open Now") }}
							</AText>
						</AButton>
						<AButton v-else-if="type === 'rankLeague'" variant="primary" size="lg" class="promo">
							<AText :modifiers="['medium']" variant="toledo">
								{{ vipClubEnabled ? t("Find Out More") : t("Activate") }}
							</AText>
						</AButton>
						<AButton v-else-if="type === 'rankLeagueReward'" variant="primary" size="lg" class="promo">
							<AText :modifiers="['medium']" variant="toledo">
								{{ vipSpinEnabled ? t("Spin the Wheel") : t("Play On & Unlock") }}
							</AText>
						</AButton>
						<AButton v-else-if="type === 'invite'" variant="primary" size="lg" class="promo">
							<AText :modifiers="['medium']" variant="toledo">
								{{ t("Copy Invite Link") }}
							</AText>
						</AButton>
						<AButton v-else-if="type === 'inviteQualification'" variant="primary" size="lg" class="promo">
							<AText :modifiers="['medium']" variant="toledo">
								{{ t("Qualify") }}
							</AText>
						</AButton>
						<AButton v-else-if="type === 'quest'" variant="primary" size="lg" class="promo">
							<AText :modifiers="['medium']" variant="toledo">
								{{ t("Go!") }}
							</AText>
						</AButton>
						<AButton v-else-if="type === 'dailyWheel'" variant="primary" size="lg" class="promo">
							<AText :modifiers="['medium']" variant="toledo">
								{{ t("Spin") }}
							</AText>
						</AButton>
						<AButton v-else variant="primary" size="lg" class="promo">
							<AText variant="toledo" :modifiers="['medium']">
								{{ t("Buy Now") }}
							</AText>

							<AText v-if="usualPrice" variant="toledo" :modifiers="['medium']" class="text-coyoacan">
								{{ currencySymbol }}<s>{{ usualPrice }}</s>
							</AText>

							<AText variant="toledo" :modifiers="['medium']">{{ currencySymbol }}{{ money }}</AText>
						</AButton>
					</template>
				</div>
			</template>
		</ACard>
	</AAnimationTranslateFlip>
</template>

<style scoped lang="scss">
.card {
	color: var(--coro);
	background: var(--graz);
	border: 2px solid var(--celaya);
	border-radius: 16px;
	overflow: visible;

	&:deep(> img) {
		margin: auto;
		border-radius: 14px;
	}

	.badge-wrapper {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		gap: 8px;
		white-space: nowrap;
		overflow: hidden;
	}

	.promo-money-box {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 4px;

		img {
			width: 24px;
			height: 24px;
			object-fit: cover;
		}
	}

	.badge {
		height: 26px;
		display: flex;
		align-items: center;
		padding-right: gutter(0.5);
		padding-left: gutter(0.5);
		box-shadow: none;

		&.free {
			border: 2px solid var(--cuautitlan);
			& > span {
				color: var(--cuautitlan);
			}
		}

		&.super {
			border: 2px solid var(--constanta);
		}

		&.label-time {
			border: 2px solid var(--chifeng);
		}
	}

	&.card-offerDeposit,
	&.card-offerDate {
		background: var(--ghent);
	}
	&.card-verification {
		background: var(--guiren);
	}
	&.card-popular {
		background: var(--guliston);
	}
	&.card-invite,
	&.card-inviteQualification {
		background: var(--goiania);
	}
	&.card-quest {
		background: var(--gadwal);
	}
	&.card-magicBox {
		background: var(--goerlitz);
	}
	&.card-sub-type {
		background: var(--gweru);
	}
	&.card-offerBirthday {
		background: var(--gonko);
	}
	&.card-best {
		background: var(--guaruja);
	}
	&.card-rankLeagueReward,
	&.card-rankLeague {
		background: var(--greenburgh);
	}

	&.card-dailyWheel {
		.badge-wrapper {
			@include media-breakpoint-up(md) {
				justify-content: center;
			}
		}
	}

	.lock {
		position: absolute;
		z-index: 2;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 0 gutter(4);
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 10, 63, 0.35);
		backdrop-filter: blur(6px);

		.icon {
			font-size: 90px;
		}
	}

	.title {
		padding: gutter(0.25) 0;
	}

	.disabled {
		gap: gutter(1);
		box-shadow: 0px 4px 10px 0px var(--chile);
		opacity: 1 !important;

		.icon {
			font-size: 24px;
		}
	}
}

.count {
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1.25;
	gap: gutter(0.5);

	&.single {
		margin-bottom: gutter(5.5);
	}

	.prize-wrapper {
		display: flex;
		align-items: center;
		gap: 4px;
		height: 26px;
		border-radius: 999px;
		background: linear-gradient(90deg, rgba(0, 239, 67, 0.5) 0%, rgba(0, 239, 67, 0) 100%);
	}
}

.count-before {
	text-align: left;
	max-width: 52px;
}

.count-after {
	text-align: left;
	max-width: 70px;
}

.box {
	position: absolute;
	left: calc(50% - 142px);
	bottom: -28px;
	border-radius: 16px;
	border: 2px solid var(--cordoba);
	background: var(--chlef);
	backdrop-filter: blur(45px);
	padding: 12px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
	width: 284px;
	height: auto;
	gap: 8px;
	&--black {
		border: 2px solid var(--cordoba);
		background: var(--czestochowa);
	}
}

.description {
	img {
		position: relative;
		top: 5px;
	}
}

.label {
	width: 56px;
	height: 56px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -15px;
	right: -15px;

	img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
}

.promo {
	display: flex;
	gap: 3px;
}

.info-txt {
	max-width: 180px;
	margin: gutter(1) auto 0;
}
</style>
