<script setup lang="ts">
import type { PromoData } from "@/types";

const { t } = useT();

const props = defineProps<{
	data: PromoData;
}>();

const emit = defineEmits<{ (event: "closePopper"): void }>();

const isBestDeal = computed(() => props.data?.promoOfferPreset?.bestDeal);
const imagePromo = computed(() => `/nuxt-img/rewards/${isBestDeal.value ? "best" : "most"}.png`);
const textBadge = computed(() => {
	const percent = parseJSON(props?.data?.promoOfferPreset?.badgeLabel || "")?.percent;

	return isBestDeal.value
		? t("rewardCenter.promoOffer.bestDeal", { percent })
		: t("rewardCenter.promoOffer.mostPopular", { percent });
});
const bgBadge = computed(() => (isBestDeal.value ? "var(--gradient-g-4)" : "var(--gradient-g-6)"));

const handleClick = () => {
	dispatchGAEvent({
		event: "click_button",
		offer: isBestDeal.value ? "best_deal" : "most_popular",
		location: "reward_cabinet"
	});

	window?.$store?.gaCash?.deposit?.({
		location: "reward_cabinet"
	});

	window?.$cash?.$router?.push?.(`/cash/deposit-by-money/${props.data?.promoOfferPreset?.id}/promoOffer/`);

	emit("closePopper");
};
</script>

<template>
	<MRewardsWrapper
		:class="['promo-reward', { 'best-deal': isBestDeal }]"
		:button-text="t('rewardCenter.promoOffer.btn.name', { price: data?.money })"
		:image="imagePromo"
		@click="handleClick"
	>
		<ABadge :background="bgBadge" variant="info" autosize class="badge-promo">
			<AText :size="10" class="text-cannes" :modifiers="['semibold', 'uppercase']">
				{{ textBadge }}
			</AText>
		</ABadge>
		<div class="prizes">
			<MPrizeFund variant="coins" img="/nuxt-img/prizes/rabbitcoins.png" :icon-size="20">
				<AText :modifiers="['semibold', 'uppercase']">
					{{ shortNumberFormatter(Number(data?.promoOfferPreset?.coins), { withPrefix: true }) }}
				</AText>
			</MPrizeFund>
			<MPrizeFund variant="entries" img="/nuxt-img/prizes/supercoins@2x.png" :icon-size="20">
				<AText :modifiers="['semibold', 'uppercase']">
					{{ shortNumberFormatter(Number(data?.promoOfferPreset?.entries), { fractionDigits: 2 }) }}
				</AText>
			</MPrizeFund>
		</div>
	</MRewardsWrapper>
</template>

<style lang="scss" scoped>
.promo-reward {
	background: radial-gradient(100% 141.42% at 100% 0%, #031da4 0%, rgba(3, 29, 164, 0) 100%);

	&.best-deal {
		background: radial-gradient(100% 141.42% at 100% 0%, #4a1b5b 0%, rgba(74, 27, 91, 0) 100%);
	}

	.prizes {
		display: flex;
		gap: 4px;
		align-items: center;

		&:deep(.prize) {
			gap: 4px;
		}
	}
}
</style>
