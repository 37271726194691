<script lang="ts" setup>
const { t } = useT();
const isGuest = useIsGuest();
const { data } = useAppInitData();
const { handleSupportButtonClick } = useSupportChat();

const hasDeposits = computed(() => data.value?.hasDeposits);
const isShowAffiliate = computed(() => !isGuest.value && data.value?.isAffiliate);

const filterLinksCallback = (slug: string) => {
	const rules = [
		{ slugs: ["alternative-method-of-entry"], condition: () => isGuest.value },
		{ slugs: ["affiliate-agreement", "partners-rules"], condition: () => !isShowAffiliate.value },
		{ slugs: ["promotional-games-rules", "refund-policy"], condition: () => !hasDeposits.value },
		{ slugs: ["promotional-games-rules2", "refund-policy2"], condition: () => !hasDeposits.value }
	];

	return !rules.some((rule) => rule.slugs.includes(slug) && rule.condition());
};

const termsLinks = computed(() =>
	data.value?.footer?.menu
		?.find((item) => item?.title === "second_column")
		?.items?.filter((link) => link?.slug && filterLinksCallback(link.slug))
);

const expandedItems = ref<Record<string, boolean>>({
	about: false,
	how: false,
	additional: false
});

const handleClick = (key: string) => {
	expandedItems.value = Object.keys(expandedItems.value).reduce(
		(acc, expandedItem) => {
			acc[expandedItem] = expandedItem === key ? !expandedItems.value[key] : false;
			return acc;
		},
		{} as Record<string, boolean>
	);
};

const handleClickOpenSupport = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "footer",
		button_name: "support"
	});
	handleSupportButtonClick();
};
</script>
<template>
	<footer class="footer">
		<div class="footer-top">
			<a class="logo" href="/">
				<NuxtImg src="/nuxt-img/logo-icon.svg" alt="logo" width="156" height="38" />
			</a>
			<div class="footer-social">
				<NuxtLink v-for="social in data?.socialNetworks" :key="social.id" :to="social.url" external target="_blank"
					><span class="icon" v-html="social.icon"
				/></NuxtLink>
			</div>
		</div>

		<div class="support">
			<div class="support-item">
				<AText variant="topeka" class="text-coro">{{ t("Need help?") }}</AText>
				<AText
					as="div"
					variant="toledo"
					:modifiers="['underline', 'semibold']"
					class="text-сirebon item"
					@click="handleClickOpenSupport"
				>
					{{ t("Online support") }}
					<NuxtIcon name="24/support" class="support-icon" filled />
				</AText>
			</div>

			<div class="support-item">
				<AText variant="topeka" class="text-coro">{{ t("Need help?") }}</AText>
				<AText
					as="a"
					variant="toledo"
					:modifiers="['underline', 'semibold']"
					href="mailto:support@jackpotrabbit.com"
					class="text-сirebon item"
				>
					support@jackpotrabbit.com
					<NuxtIcon name="24/mail" class="support-icon" filled />
				</AText>
			</div>

			<div class="support-item">
				<AText variant="topeka" class="text-coro">{{ t("Need help?") }}</AText>
				<AText
					as="a"
					variant="toledo"
					:modifiers="['underline', 'semibold']"
					href="tel:+12088444129"
					class="text-сirebon item"
				>
					+1 208 844 4129
					<NuxtIcon name="24/phone" class="support-icon" filled />
				</AText>
			</div>
		</div>

		<div class="info">
			<div class="about">
				<div :class="['about-item', { active: expandedItems.about }]">
					<ACollapse :expanded="expandedItems.about">
						<template #title>
							<AText
								as="p"
								variant="taipei"
								:class="['about-title', expandedItems.about ? 'text-сirebon' : 'text-coro']"
								:modifiers="['medium']"
								@click="handleClick('about')"
							>
								{{ t("About JackpotRabbit") }}
								<NuxtIcon name="32/arrow" filled :class="['about-icon', { expanded: expandedItems.about }]" />
							</AText>
						</template>

						<template #expanded>
							<AText as="p" variant="tempe" class="text-coro" :modifiers="['medium']">
								<p>
									Welcome to the world of JackpotRabbit, where gaming excitement meets endless possibilities, the thrill
									is always alive, and the rewards keep flowing!
								</p>
								<p>
									Immerse yourself in our realm of diverse games that cater to every preference, whether you're a fan of
									timeless classics or crave innovative experiences like Hold'n'Link, Rapid Link, or Fishing games.
								</p>
								<p>
									We invite you to discover your next gaming passion with Jack the Rabbit by your side! Our mascot will
									keep you updated on daily opportunities to win, hot releases, time-limited promotions, and upcoming
									events.
								</p>
							</AText>
						</template>
					</ACollapse>
				</div>

				<div :class="['about-item', { active: expandedItems.how }]">
					<ACollapse :expanded="expandedItems.how">
						<template #title>
							<AText
								as="p"
								variant="taipei"
								:class="['about-title', expandedItems.how ? 'text-сirebon' : 'text-coro']"
								:modifiers="['medium']"
								@click="handleClick('how')"
							>
								{{ t("How To Play") }}
								<NuxtIcon name="32/arrow" filled :class="['about-icon', { expanded: expandedItems.how }]" />
							</AText>
						</template>

						<template #expanded>
							<AText as="div" variant="tempe" class="text-coro" :modifiers="['medium']">
								{{
									t(
										"Jack the Rabbit has prepared entertainment for you to enjoy a wide variety of games. Here, you have fun playing with Game Coins, our virtual currency. Game Coins keep the excitement rolling, and you can collect some every day using the Wheel of Fortune feature, as well as take part in other activities at JackpotRabbit."
									)
								}}
							</AText>
						</template>
					</ACollapse>
				</div>

				<div :class="['about-item', { active: expandedItems.additional }]">
					<ACollapse :expanded="expandedItems.additional">
						<template #title>
							<AText
								as="p"
								variant="taipei"
								:class="['about-title', expandedItems.additional ? 'text-сirebon' : 'text-coro']"
								:modifiers="['medium']"
								@click="handleClick('additional')"
							>
								{{ t("Additional information") }}
								<NuxtIcon name="32/arrow" filled :class="['about-icon', { expanded: expandedItems.additional }]" />
							</AText>
						</template>

						<template #expanded>
							<AText as="p" variant="tempe" class="text-coro" :modifiers="['medium']">
								<p>
									Whether you’re here to compete, unwind, or simply indulge in entertainment, JackpotRabbit will always
									remain your free-to-play haven. It is our dedication to offering an unmatched gaming adventure that’s
									open to all, without any financial obligations, that lies at the core of the project. Here is why you
									can start exploring the fascinating gaming world of JackpotRabbit at no cost and right away!
								</p>

								<p>
									Need additional guidance? Jack the Rabbit and our dedicated support team are ready to assist you. Feel
									free to contact us anytime, and we’ll ensure your JackpotRabbit journey is smooth and filled with
									excitement!
								</p>
							</AText>
						</template>
					</ACollapse>
				</div>
			</div>
		</div>

		<div class="footer-bottom">
			<div class="terms-list">
				<NuxtLink
					v-for="(link, index) in termsLinks"
					:key="link?.slug"
					:data-tid="`footer-terms-${index}`"
					:to="`/page/${link?.slug}`"
				>
					<AText class="text-coro" :modifiers="['bold', 'underline']">{{ link?.title }}</AText>
				</NuxtLink>
			</div>

			<div class="copyright">
				<AText variant="topeka" class="text-conakry" :modifiers="['semibold']">
					{{ t("© 2024 JackpotRabbit Social Gaming Platform") }}
				</AText>
			</div>
		</div>
	</footer>
</template>
<style lang="scss" scoped>
.footer {
	display: flex;
	flex-direction: column;
	background-color: var(--carabanchel);
	border-top: 1px solid var(--chifeng);
	width: 100%;
	padding: 64px 64px 32px;
	gap: 26px;

	@media (max-width: 1365px) {
		padding: gutter(2.5) gutter(2.5) gutter(3);
	}

	@media (max-width: 768px) {
		padding: gutter(2.5) gutter(2) gutter(3);
	}

	&-top {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include media-breakpoint-down(md) {
			flex-direction: column;
			gap: gutter(1);
		}
	}

	&-social {
		display: flex;
		gap: gutter(2);
		&:deep(svg) {
			width: auto;
			height: 48px;
		}
	}

	&-bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include media-breakpoint-down(lg) {
			flex-direction: column;
			align-items: flex-start;
			padding-bottom: gutter(5);
		}
	}
}

.terms-list {
	display: flex;
	gap: gutter(1);

	@include media-breakpoint-down(lg) {
		flex-direction: column;
		gap: gutter(2);
	}

	a {
		&:hover {
			color: var(--cannes);
			span {
				text-decoration: none;
			}
		}
	}
}

.copyright {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include media-breakpoint-down(lg) {
		width: 100%;
		justify-content: center;
		padding-top: gutter(4);
	}
}

.info {
	display: flex;
	gap: gutter(7.5);
}

.support {
	display: flex;
	min-width: 216px;
	gap: gutter(2);
	position: relative;

	@include media-breakpoint-down(lg) {
		flex-direction: column;
	}

	.link {
		display: flex;
		align-items: center;
		gap: gutter(1);

		.nuxt-icon {
			font-size: 24px;

			&:deep(svg) {
				margin-bottom: gutter(0);
			}
		}
	}
}

.support-icon:deep(svg) {
	font-size: 24px;
	margin: 0;

	path {
		fill: var(--сirebon);
	}
}

.support-item {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
	height: 78px;
	padding: gutter(2) gutter(1.5);
	background: var(--carletonville);
	transition: linear 0.2s;
	cursor: pointer;
	border-radius: 20px;

	&:hover {
		border-color: var(--сirebon);
	}

	.item {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;

		&:hover {
			cursor: pointer;
		}
	}
}

.about {
	width: 100%;

	&-title {
		display: flex;
		justify-content: space-between;

		&:hover {
			cursor: pointer;
		}
	}

	&-icon.expanded:deep(svg path) {
		fill: var(--сirebon);
	}

	&-item {
		display: flex;
		width: 100%;
		flex-direction: column;
		justify-content: space-between;
		border-bottom: 2px solid var(--cuernavaca);
		padding: 16px 0;

		&.active {
			border-color: var(--сirebon);
		}

		&:deep(svg) {
			width: 32px;
			height: 32px;
			margin-bottom: 0;
		}

		p {
			padding: gutter(1) 0;
		}
	}
}

.expanded {
	transform: rotate(180deg);
}
</style>
