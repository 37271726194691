<script lang="ts" setup>
import type { RecentWinner } from "~/types";
const props = defineProps<{ item: RecentWinner }>();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const defaultTypesDictionary = new Map([
	["bigwin", "big win"],
	["jackpot", "jackpot"],
	["daily-wheel-accrual", "Wheel of Fortune"]
]);

const { t } = useT();
const isGuest = useIsGuest();
const { open } = useAppModals();
const isDailyWheel = computed(() => props.item.type === "daily-wheel-accrual");
const isJackpot = computed(() => props.item.type === "jackpot");
const elementType = computed(() => {
	if (props.item.type === "win") {
		return t("Win");
	}
	return defaultTypesDictionary.get(props.item.type);
});

const { handleOpenGame } = useOpenGame(open, !isDailyWheel.value);

const handleCheck = (winner: RecentWinner) => {
	if (isGuest.value) {
		open("LazyOModalSignup");
		return;
	}
	if (isDailyWheel.value) {
		open("LazyOModalDailyWheel");
		return;
	}

	if (winner.data?.slug) {
		handleOpenGame(winner.data?.slug);
	}
};
</script>

<template>
	<div class="feed-card" :class="'feed-card--' + item.type" @click="handleCheck(item)">
		<div class="feed-card__image" :class="{ 'with-pointer': isDailyWheel || isJackpot ? isGuest : true }">
			<NuxtImg
				v-if="defaultTypesDictionary.has(item.type)"
				:src="`/nuxt-img/activity-feed/${item.type}.jpg`"
				:alt="item.type"
				width="172"
				height="192"
				format="webp"
				loading="lazy"
			/>
			<NuxtImg
				v-else
				:src="`${baseImageUrl}${item.data?.logo}`"
				:alt="item.data?.slug"
				width="86"
				height="96"
				format="webp"
			/>
		</div>
		<div class="feed-card__data">
			<ABadge class="feed-card__badge" variant="info" autosize background="var(--gainesville)">
				<AText variant="ternopil" :modifiers="['semibold', 'uppercase']" class="text-coro">
					{{ elementType }}
				</AText>
			</ABadge>
			<div class="feed-card__text">
				<AText variant="tempe" class="app-text-clip text-constanta">
					{{ item.name || item.nickname }} {{ item.surname }}
				</AText>
			</div>
			<AText variant="toledo" :modifiers="['extrabold']" as="div" class="feed-card__values">
				<template v-if="item.type === 'win'">
					<div
						v-if="item.data?.winAmount"
						class="feed-card__value"
						:class="
							item.gameMode === 'SweepStakes' && !isGuest ? 'feed-card__value--entries' : 'feed-card__value--coins'
						"
					>
						<template v-if="item.gameMode === 'SweepStakes' && !isGuest">
							{{ shortNumberFormatter(item.data?.winAmount, { fractionDigits: 2 }) }}
						</template>
						<template v-else>
							{{ shortNumberFormatter(item.data?.winAmount, { withPrefix: true }) }}
						</template>
					</div>
				</template>
				<template v-if="item.type === 'bigwin'">
					<span class="feed-card__value feed-card__value--x">
						{{ numberFormat(item.data?.big_win_coefficient || 0) }}
					</span>
				</template>
				<template v-if="item.type === 'jackpot'">
					<div
						class="feed-card__value"
						:class="
							item.gameMode === 'SweepStakes' && !isGuest ? 'feed-card__value--entries' : 'feed-card__value--coins'
						"
					>
						<template v-if="item.gameMode === 'SweepStakes' && !isGuest">
							{{ shortNumberFormatter(item.data?.amount || 0, { fractionDigits: 2 }) }}
						</template>
						<template v-else>
							{{ shortNumberFormatter(item.data?.amount || 0, { withPrefix: true }) }}
						</template>
					</div>
				</template>
				<template v-if="item.type === 'daily-wheel-accrual'">
					<div v-if="item.data?.coins" class="feed-card__value feed-card__value--coins">
						{{ shortNumberFormatter(item.data?.coins, { withPrefix: true }) }}
					</div>
					<div
						v-if="Number(item.data?.entries) && (!isGuest || !item.data?.coins)"
						:class="['feed-card__value', isGuest ? 'feed-card__value--coins' : 'feed-card__value--entries']"
					>
						{{ shortNumberFormatter(Number(item.data?.entries), { fractionDigits: 2 }) }}
					</div>
				</template>
			</AText>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.feed-card {
	height: 120px;
	width: 100%;
	padding: 12px;
	border-radius: 16px;
	background: var(--carletonville);
	border: 2px solid var(--chifeng);
	display: flex;
	gap: 8px;
	position: relative;

	&:hover {
		cursor: pointer;
	}

	&--jackpot {
		background: var(--cusco);
		border: 2px solid var(--chas);
	}

	&__image {
		min-width: 86px;
		width: 86px;
		height: 100%;
		border-radius: 8px;
		overflow: hidden;
		background: var(--cuba);
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: center;

		&.with-pointer {
			cursor: pointer;
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
		}
	}

	&__game {
		position: relative;
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		text-align: center;
		text-transform: uppercase;
		color: var(--clara);
		text-shadow: 0 1px 1px var(--coronel);
		padding: 0 gutter(0.5) gutter(1.5);

		&:deep(span) {
			display: block;
			text-shadow: none;
			font-size: 14px;
			font-weight: 400;
			text-align: center;
		}
	}

	&__image-overlay {
		z-index: 1;
	}

	&__values {
		display: flex;
		flex-direction: column;
		gap: 4px;
		white-space: nowrap;
	}

	@at-root {
		%value-icon {
			align-self: center;
			content: "";
			width: 22px;
			height: 22px;
			margin: 0 gutter(0.25) 0 0;
			background-size: contain !important;
			background-repeat: no-repeat;
		}
	}

	&__value {
		display: inline-flex;
		gap: 4px;
		align-items: center;
		font-style: normal;
		color: var(--cannes);
		line-height: 1;
		font-weight: 600;
	}

	&__value--coins {
		color: var(--cheremushki);
		&::before {
			@extend %value-icon;
			background-image: url("/assets/icons/20/gold-coins.svg");
		}
	}

	&__value--entries {
		color: var(--curico);
		&::before {
			@extend %value-icon;
			background-image: url("/assets/icons/20/sweep-coins.svg");
		}
	}

	&__value--x::before {
		@extend %value-icon;
		background-image: url("/assets/icons/20/big-win.svg");
	}

	&__data {
		height: 100%;
		flex-grow: 2;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		overflow: hidden;

		@include media-breakpoint-down(md) {
			padding: 0;
		}
	}

	&__badge {
		padding: 0 7px !important;
		white-space: nowrap;
	}

	&__text {
		white-space: nowrap;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	&__name {
		color: var(--cannes);
		&:first-letter,
		&:first-line {
			text-transform: capitalize;
		}
	}

	&__location {
		display: flex;
		justify-content: center;
		font-size: 14px;
		color: var(--cancun);
		white-space: nowrap;
		margin-top: gutter(0.25);
	}

	&__city {
		text-transform: lowercase;
		&:first-letter,
		&:first-line {
			text-transform: capitalize;
		}
	}
}
</style>
