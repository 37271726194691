<script lang="ts" setup>
import type { Games } from "@/types";
const { t } = useT();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();

defineEmits<{ (event: "click"): void }>();

defineProps<{
	title?: string;
	logo?: string;
	games?: Games;
	bgColor?: string;
	slug?: string;
}>();
</script>

<template>
	<div class="collection" @click="$emit('click')">
		<div class="header">
			<NuxtImg class="image" :src="`${baseImageUrl}${logo}`" :alt="title" format="webp" width="316" height="358" />
			<AText as="h2" :size="24" class="title" :modifiers="['semibold']">{{ title }}</AText>
		</div>

		<OGames :games="games || []" show="grid" hide-favorite-btn skip-rate-badge skip-new-badge />

		<AButton variant="primary" class="btn" size="lg">
			<AText variant="toledo" :modifiers="['medium']">
				{{ t("See More") }}
			</AText>
		</AButton>
	</div>
</template>

<style lang="scss" scoped>
.collection {
	width: calc(100% / 3 - 11px);
	min-width: 440px;
	display: flex;
	flex-direction: column;
	padding: 148px 12px 16px;
	border: 2px solid var(--chifeng);
	cursor: pointer;
	border-radius: 15px;
	overflow: hidden;
	position: relative;

	@include media-breakpoint-down(lg) {
		width: calc(100% / 2 - 8px);
	}

	@include media-breakpoint-down(md) {
		width: 100%;
		min-width: unset;
	}

	:deep(.grid-cards) {
		min-width: 100%;
		grid-template-columns: repeat(4, 1fr);
		gap: 4px;
		margin-top: auto;

		.inside-title {
			display: none;
		}

		@include media-breakpoint-down(md) {
			margin-top: 0;
			padding-right: 12px;
		}

		.badge-exclusive .prize.entries {
			.ternopil {
				font-size: 7px;
			}

			@include media-breakpoint-down(md) {
				.nuxt-icon {
					font-size: 8px;
				}

				.ternopil {
					font-size: 6px;
				}
			}
		}

		.lock-exclusive-icon {
			width: 19px;
			height: 26.5px;
			top: 60%;
		}
	}

	.title {
		margin-bottom: 4px;
		position: relative;
	}

	.subtitle {
		margin-bottom: 12px;
	}

	.header {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}

	.btn {
		margin-top: 12px;
	}

	.image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
</style>
