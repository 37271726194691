const useHomePage = ({ immediate = true }: { immediate?: boolean } = {}) => {
	const { data: appInit } = useAppInitData();
	const { select } = useGamesState();

	const { data: pageData, pending: loading, refresh } = useHomeData({ immediate, cached: true, server: true });

	const { data: recentWinners } = useAsyncFetch({
		path: "/rest/players-activity/",
		method: "get",
		fetchOptions: {
			query: { limit: 6 }
		},
		options: {
			cached: true
		}
	});

	const { games: allGamesResponse } = useGetGameFiltersData();

	const makeTwoRowsTable = <T>(arr: T[]): T[][] =>
		arr.reduce((result, current, index, array) => {
			if (index % 2 === 0) {
				result.push(array.slice(index, index + 2) as T[]);
			}
			return result;
		}, [] as T[][]);

	const seoData = computed(() => pageData.value?.seo);

	const packages = computed(() => pageData.value?.packages);

	const lastGames = computed(() => select(appInit.value?.lastGames || []));

	const jackpotGames = computed(() => select(pageData?.value?.games?.jackpot || []));

	const holdNLinksGames = computed(() => select(pageData?.value?.games?.holdNLink || []));

	const promotions = computed(() => pageData.value?.promotions || []);

	const gameOfWeek = computed(() => select(pageData.value?.gameOfWeek || [])?.[0]);

	const popularGames = computed(() => select(pageData?.value?.games?.popularGames || []));

	const hotGames = computed(() => select(pageData?.value?.games?.hotGames || []));

	const favoriteGames = computed(() => select(appInit.value?.favoriteGames || []));

	const allGamesIssues = computed(() => allGamesResponse.value);

	const collections = computed(() => pageData.value?.collections || []);

	const favoritesSet = computed(() => new Set(appInit.value?.favoriteGames || []));

	const allGames = computed(() => {
		const gamesArray = allGamesResponse.value || [];
		return gamesArray.map((game) => ({
			...game,
			isFavorite: game.id ? favoritesSet.value.has(game.id) : false
		}));
	});

	const yourPick = computed(() => {
		const reversedFavGames = [...(appInit.value?.favoriteGames || "")].reverse();
		const uniqueGames = [...new Set([...reversedFavGames])];

		return uniqueGames.map((id) => allGames.value?.find((g: { id?: number }) => g.id === id)).filter(Boolean);
	});

	const holdNLinksGamesArrayable = computed(() => makeTwoRowsTable(holdNLinksGames.value));
	const yourPickGamesArrayable = computed(() => makeTwoRowsTable(yourPick.value));
	const jackpotGamesArrayable = computed(() => makeTwoRowsTable(jackpotGames.value));

	const questData = computed(() => pageData.value?.questData);

	const lastWinners = computed(() => pageData.value?.lastWinners || null);

	const banners = computed(() => {
		if (!appInit.value?.isGuest && appInit.value?.bannerPresetPackage) {
			return [
				{
					type: "bannerPresetPackage",
					...appInit.value.bannerPresetPackage
				},
				...(pageData.value?.banners || [])
			];
		}
		return pageData.value?.banners || [];
	});

	return {
		loading,
		pageData,
		packages,
		gameOfWeek,
		allGames,
		favoriteGames,
		allGamesIssues,
		collections,
		lastGames,
		refresh,
		jackpotGames,
		holdNLinksGames,
		holdNLinksGamesArrayable,
		yourPickGamesArrayable,
		jackpotGamesArrayable,
		promotions,
		popularGames,
		hotGames,
		yourPick,
		banners,
		recentWinners,
		questData,
		lastWinners,
		seoData
	};
};

export default useHomePage;
