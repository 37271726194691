<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		countSlide: number;
		count?: number | string;
		perspective?: number;
		display?: number;
		loop?: boolean;
		animationSpeed?: number;
		dir?: string;
		width?: number;
		height?: number;
		border?: number;
		space?: number | string;
		startIndex?: number;
		clickable?: boolean;
		disable3d?: boolean;
		minSwipeDistance?: number;
		inverseScaling?: number;
		controlsVisible?: boolean;
		controlsPrevHtml?: string;
		controlsNextHtml?: string;
		controlsWidth?: number | string;
		controlsHeight?: number | string;
		bias?: string;
		oneDirectional?: boolean;
		rotateCard?: boolean;
		paginationIcon?: string;
		isPagination?: boolean;
	}>(),
	{
		count: 0,
		perspective: 35,
		display: 5,
		loop: true,
		animationSpeed: 600,
		dir: "rtl",
		width: 360,
		height: 270,
		border: 1,
		space: "auto",
		startIndex: 0,
		clickable: true,
		disable3d: false,
		minSwipeDistance: 10,
		inverseScaling: 300,
		controlsVisible: false,
		controlsPrevHtml: "&lsaquo;",
		controlsNextHtml: "&rsaquo;",
		controlsWidth: 50,
		controlsHeight: 50,
		bias: "left",
		oneDirectional: false,
		rotateCard: false,
		paginationIcon: "16/arrow-down-small",
		isPagination: true
	}
);

const viewport = ref(0);
const currentIndex = ref(0);
const total = ref(0);
const dragOffsetX = ref(0);
const dragStartX = ref(0);
const dragOffsetY = ref(0);
const dragStartY = ref(0);
const mousedown = ref(false);

const carouselContainer = ref();
const targetIsVisible = ref(false);
let stopObserver: (() => void) | null = null;

const zIndexSlide = ref(999);

const isLastSlide = computed(() => currentIndex.value === total.value - 1);
const isFirstSlide = computed(() => currentIndex.value === 0);
const isNextPossible = computed(() => !(props.loop === false && isLastSlide.value));
const isPrevPossible = computed(() => !(props.loop === false && isFirstSlide.value));

const slideWidth = computed(() => {
	const vw = viewport.value;
	const sw = props.width + props.border * 2;

	return vw < sw && process.browser ? vw : sw;
});

const calculateAspectRatio = (width: number, height: number) => Math.min(width / height);

const isCurrent = (index: number) => index === currentIndex.value;

const matchIndex = (index: number, indexElement: number) =>
	index >= 0 ? index === indexElement : total.value + index === indexElement;

const getSideIndex = (array: number[], indexElement: number) => {
	let sideIndex = -1;

	array.forEach((pos, i) => {
		if (matchIndex(pos, indexElement)) {
			sideIndex = i;
		}
	});

	return sideIndex;
};

const visible = computed(() => {
	const v = props.display > total.value ? total.value : props.display;
	return v;
});

const leftIndices = computed(() => {
	let n = (visible.value - 1) / 2;
	n = props.bias.toLowerCase() === "left" ? Math.ceil(n) : Math.floor(n);

	const indices = [];
	for (let m = 1; m <= n; m++) {
		indices.push(props.dir === "ltr" ? (currentIndex.value + m) % total.value : (currentIndex.value - m) % total.value);
	}

	return indices;
});

const rightIndices = computed(() => {
	let n = (visible.value - 1) / 2;
	n = props.bias.toLowerCase() === "right" ? Math.ceil(n) : Math.floor(n);

	const indices = [];
	for (let m = 1; m <= n; m++) {
		indices.push(props.dir === "ltr" ? (currentIndex.value - m) % total.value : (currentIndex.value + m) % total.value);
	}

	return indices;
});

const leftIndex = (indexElement: number) => {
	if (props.oneDirectional && getSideIndex(leftIndices.value, indexElement) > -1) {
		return -1;
	}
	return getSideIndex(leftIndices.value, indexElement);
};

const rightIndex = (indexElement: number) => {
	if (props.oneDirectional && getSideIndex(rightIndices.value, indexElement) > total.value - currentIndex.value - 2) {
		return -1;
	}
	return getSideIndex(rightIndices.value, indexElement);
};

const calculatePosition = (i: number, positive: boolean, zIndex: number) => {
	const z = !props.disable3d ? props.inverseScaling + (i + 1) * 100 : 0;
	const y = !props.disable3d ? props.perspective : 0;
	const leftRemain =
		props.space === "auto"
			? (i + 1) * (props.width / 1.5)
			: (i + 1) * (typeof props.space === "string" ? 1 : props.space);
	const transform = positive
		? `translateX(${leftRemain}px) translateZ(-${z}px) rotateY(-${props.rotateCard ? y : 0}deg)`
		: `translateX(-${leftRemain}px) translateZ(-${z}px) rotateY(${props.rotateCard ? y : 0}deg)`;
	const top = props.space === "auto" ? 0 : (i + 1) * (typeof props.space === "string" ? 1 : props.space);

	return {
		transform,
		top,
		zIndex: zIndex - (Math.abs(i) + 1)
	};
};

const slideHeight = computed(() => {
	const sw = props.width + props.border * 2;
	const sh = props.height + props.border * 2;
	const ar = calculateAspectRatio(sw, sh);

	return slideWidth.value / ar;
});

const slideStyle = (indexElement: number) => {
	let styles: { [key: string]: string | number } = {};

	const lIndex = leftIndex(indexElement);
	const rIndex = rightIndex(indexElement);
	if (rIndex >= 0 || lIndex >= 0) {
		styles =
			rIndex >= 0
				? calculatePosition(rIndex, true, zIndexSlide.value)
				: calculatePosition(lIndex, false, zIndexSlide.value);
		styles.opacity = 1;
		styles.visibility = "visible";
	}

	return Object.assign(styles, {
		"border-width": `${props.border}px`,
		width: `${slideWidth.value}px`,
		height: `${slideHeight.value}px`,
		transition: `transform ${props.animationSpeed}ms, opacity ${slideHeight.value}ms, visibility ${slideHeight.value}ms`
	});
};

const skeletonSlideStyle = (indexElement: number) => {
	let styles: { [key: string]: string | number } = {};

	const lIndex = leftIndex(indexElement);
	const rIndex = rightIndex(indexElement);
	if (rIndex >= 0 || lIndex >= 0) {
		styles =
			rIndex >= 0
				? calculatePosition(rIndex, true, zIndexSlide.value)
				: calculatePosition(lIndex, false, zIndexSlide.value);
		styles.opacity = 1;
		styles.visibility = "visible";
	}

	return Object.assign(styles, {
		width: `${slideWidth.value}px`,
		height: `${slideHeight.value}px`
	});
};

const computedClasses = (indexElement: number) => ({
	[`left-${leftIndex(indexElement) + 1}`]: leftIndex(indexElement) >= 0,
	[`right-${rightIndex(indexElement) + 1}`]: rightIndex(indexElement) >= 0
});

const goSlide = (index: number) => {
	currentIndex.value = index < 0 || index > total.value - 1 ? 0 : index;
};

const goNext = () => {
	if (isNextPossible.value) {
		isLastSlide.value ? goSlide(0) : goSlide(currentIndex.value + 1);
	}
};

const goPrev = () => {
	if (isPrevPossible.value) {
		isFirstSlide.value ? goSlide(total.value - 1) : goSlide(currentIndex.value - 1);
	}
};

const handleMouseup = () => {
	mousedown.value = false;
	dragOffsetX.value = 0;
	dragOffsetY.value = 0;
};

const handleMousedown = (e: MouseEvent | TouchEvent) => {
	mousedown.value = true;
	const point = "touches" in e ? e.touches[0] : e;
	dragStartX.value = point.clientX;
	dragStartY.value = point.clientY;
};

const handleMousemove = (e: MouseEvent | TouchEvent) => {
	if (!mousedown.value) {
		return;
	}

	const point = "touches" in e ? e.touches[0] : e;
	dragOffsetX.value = dragStartX.value - point.clientX;
	dragOffsetY.value = dragStartY.value - point.clientY;

	if (Math.abs(dragOffsetY.value) > Math.abs(dragOffsetX.value)) {
		return;
	}

	if (dragOffsetX.value > props.minSwipeDistance) {
		handleMouseup();
		goNext();
	} else if (dragOffsetX.value < -props.minSwipeDistance) {
		handleMouseup();
		goPrev();
	}
};

const getSlideCount = () => props.countSlide;

const computeData = (firstRun?: boolean) => {
	total.value = getSlideCount();
	if (firstRun || currentIndex.value >= total.value) {
		currentIndex.value = props.startIndex > total.value - 1 ? total.value - 1 : props.startIndex;
	}

	viewport.value = carouselContainer.value?.clientWidth;
};

if (process.client) {
	watch(
		() => props.count,
		() => {
			computeData();
		}
	);

	useEventListener(carouselContainer, "touchstart", handleMousedown);
	useEventListener(carouselContainer, "touchend", handleMouseup);
	useEventListener(carouselContainer, "touchmove", handleMousemove);
	useEventListener(carouselContainer, "mousedown", handleMousedown);
	useEventListener(carouselContainer, "mouseup", handleMouseup);
	useEventListener(carouselContainer, "mousemove", handleMousemove);
}

onMounted(() => {
	const { stop } = useIntersectionObserver(
		carouselContainer,
		([entry]) => {
			targetIsVisible.value = entry?.isIntersecting || false;
		},
		{
			threshold: 0
		}
	);
	stopObserver = stop;
	computeData(true);
});

onUnmounted(() => {
	window.removeEventListener("touchstart", handleMousedown);
	window.removeEventListener("touchend", handleMouseup);
	window.removeEventListener("touchmove", handleMousemove);
	window.removeEventListener("mousedown", handleMousedown);
	window.removeEventListener("mouseup", handleMouseup);
	window.removeEventListener("mousemove", handleMousemove);
	stopObserver?.();
});

defineExpose({
	goNext,
	goPrev
});
</script>

<template>
	<div ref="carouselContainer" class="carousel-container">
		<div
			v-if="targetIsVisible"
			class="carousel-slider"
			:style="{ width: slideWidth + 'px', height: slideHeight + 'px' }"
		>
			<slot
				:isCurrent="isCurrent"
				:leftIndex="leftIndex"
				:rightIndex="rightIndex"
				:styles="slideStyle"
				:classes="computedClasses"
				:goSlide="goSlide"
			/>
		</div>
		<div v-else class="carousel-slider" :style="{ width: slideWidth + 'px', height: slideHeight + 'px' }">
			<ASkeleton
				v-for="(_, index) in countSlide"
				:key="index"
				:style="skeletonSlideStyle(index)"
				:class="['carousel-slide', computedClasses(index), { current: isCurrent(index) }]"
			/>
		</div>

		<div v-if="isPagination" class="carousel-pagination">
			<AButton class="prev" @click="goPrev">
				<NuxtIcon :name="paginationIcon" filled />
			</AButton>
			<AButton class="next" @click="goNext">
				<NuxtIcon :name="paginationIcon" filled />
			</AButton>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.carousel-container {
	width: 100%;
	position: relative;
	z-index: 0;
	overflow: hidden;
	margin: 20px auto;
	box-sizing: border-box;
	touch-action: pan-y;
}

.carousel-pagination {
	display: flex;
	justify-content: center;
	gap: 10px;
	margin-top: 20px;
}

.carousel-pagination .prev .nuxt-icon {
	transform: rotate(90deg);
}

.carousel-pagination .next .nuxt-icon {
	transform: rotate(-90deg);
}

.carousel-slider {
	position: relative;
	margin: 0 auto;
	transform-style: preserve-3d;
	-webkit-perspective: 1000px;
	-moz-perspective: 1000px;
	perspective: 1000px;
}

:deep(.carousel-slide) {
	position: absolute;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	top: 0;
	display: block;
	margin: 0;
	box-sizing: border-box;
	will-change: transform, opacity;
}

:deep(.carousel-slide.current) {
	opacity: 1 !important;
	visibility: visible !important;
	transform: none !important;
	z-index: 999;
}
</style>
