<script setup lang="ts">
import type { GameLastWinner, Game } from "~/types";

const props = defineProps<{
	image: string;
	imagePattern: string;
	game: Game;
	hideFavorite?: boolean;
	isNew?: boolean;
	grade?: string;
	isOnlyForApp: boolean | undefined;
	isFavorite: boolean | undefined;
	isGuest: boolean | undefined;
	winnerData?: GameLastWinner | undefined;
	title?: string;
	gameId: number;
	insideTitle?: string;
	isGameOfWeek?: boolean | undefined;
	skipGamePreview?: boolean;
	skipRateBadge?: boolean;
	skipNewBadge?: boolean;
	skeleton?: boolean;
}>();

const emit = defineEmits<{ (event: "toggleFavorite"): void; (event: "play"): void }>();

const { t } = useT();
const { data: appInit } = useAppInitData();
const { isOpen, open, close } = useAppModals();
const { handleOpenGame } = useOpenGame(open);
const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const formatRef = ref("avif");

const handleToggle = () => {
	emit("toggleFavorite");
};

const handleClick = () => {
	if (!props.isOnlyForApp) {
		const lastGames = appInit.value?.lastGames || [];
		if (lastGames.includes(props.gameId)) {
			appInit.value?.lastGames &&
				(appInit.value.lastGames = [props.gameId as number, ...lastGames.filter((el) => el !== props.gameId)]);
		} else {
			appInit?.value?.lastGames && (appInit.value.lastGames = [props.gameId as number, ...lastGames]);
		}

		if (props.isGuest) {
			open("LazyOModalSignup");

			return;
		}

		if (isOpen("LazyOModalGameSearch")) {
			close("LazyOModalGameSearch");
		}

		if (props.game.gamePlayInfo && !props.skipGamePreview) {
			open("LazyOModalGamePreview", {
				game: props.game
			});
			return;
		}
		handleOpenGame(props.game.slug);
	}
};
const alt = computed(() => removeHtmlTags(props.title || props.insideTitle || ""));

onMounted(() => {
	if (props.game?.logo?.includes(".gif") || props.game?.img?.includes(".gif")) {
		setTimeout(() => {
			formatRef.value = "gif";
		}, 300);
	}
});
</script>

<template>
	<div class="game-wrapper" :class="{ 'has-skeleton': skeleton }">
		<AAnimationSkeleton v-if="skeleton" class="skeleton-wrapper">
			<ASkeleton width="100%" height="100%" />
		</AAnimationSkeleton>
		<ACard
			v-else
			:imgAttrs="{
				width: 430,
				height: 476,
				src: `${baseImageUrl}${game?.logo || game?.img}`,
				format: formatRef,
				alt
			}"
			variant="game"
			width="auto"
			height="100%"
			stretch="112%"
			@click.stop="handleClick"
		>
			<MGameActivityFeedBadge :winner="winnerData" />
			<div v-if="isNew && !isOnlyForApp && !skipNewBadge" class="badge-wrap new">
				<AAnimationCustomByScroll animationClass="blink" transitionDelay="1s" transition="1s">
					<ABadge :size="30" variant="info" autosize background="var(--ganduras)">
						<AText variant="ternopil" :modifiers="['semibold', 'uppercase']">{{ t("New") }} </AText>
					</ABadge>
				</AAnimationCustomByScroll>
			</div>

			<div v-if="!skipRateBadge && grade" class="badge-wrap grade">
				<ABadge :size="30" variant="info" autosize background="var(--neutral-950)">
					<ASvg name="14/rate" class="icon" />
					<AText variant="ternopil" :modifiers="['semibold', 'uppercase']">{{ grade }}</AText>
				</ABadge>
			</div>

			<div v-if="isOnlyForApp" class="lock-overlay">
				<AOverlay :modifiers="['blur', 'flex-center']" bgColor="var(--chandigarh)">
					<NuxtIcon name="40/locked" filled />
				</AOverlay>
			</div>
			<AAnimationFadeIn v-else :duration="0.6">
				<AOverlay :modifiers="['auto']">
					<div class="game-content">
						<div class="center-block">
							<AButton
								v-if="!isGuest && !hideFavorite"
								class="favorite"
								:class="{ 'favorite--active': isFavorite }"
								variant="toolbar"
								:modifiers="['circle']"
								@click.stop="handleToggle"
							>
								<ASvg :name="isFavorite ? '48/fav-active' : '48/fav'" class="icon" />
							</AButton>
							<AButton variant="ghost" :modifiers="['circle']" class="play">
								<NuxtIcon name="48/play" filled />
							</AButton>
						</div>
					</div>
				</AOverlay>
			</AAnimationFadeIn>
			<AText v-if="title" variant="turin" as="p" :modifiers="['bold', 'uppercase']"
				><span class="text-coro game-name" v-html="title"
			/></AText>
		</ACard>

		<div v-if="insideTitle" class="title-wrap">
			<AText variant="tlalpan" :modifiers="['extrabold']">
				<div class="inside-title" v-html="insideTitle" />
			</AText>
		</div>
	</div>
</template>

<style lang="scss">
.icon-favorite.add {
	background-image: url(/assets/icons/24/fav-add.svg);
}

.icon-favorite.remove {
	background-image: url(/assets/icons/24/fav-remove.svg);
}

.inside-title {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
</style>

<style scoped lang="scss">
.has-skeleton {
	position: relative;
	--a-skeleton-background-color: var(--cuba);
	padding-top: 112%;
}
.skeleton-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.game-wrapper {
	display: inline-flex;
	flex-direction: column;
	position: relative;

	&:deep(img) {
		transition: 0.2s;
	}

	@media (hover: hover) {
		&:hover .card {
			& > div {
				opacity: 1;
			}

			&:deep(> img) {
				transform: scale(1.2);
			}
		}
	}

	:deep(.card) {
		border-radius: 16px;
		border: 2px solid var(--celaya);
		.game:before {
			content: "";
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: v-bind(imagePattern);
			position: absolute;
			z-index: -1;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: 50% 50%;
		}
		&:hover > div {
			opacity: 1;
		}
	}
}
.lock-overlay {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 56px;
	height: 56px;
	position: absolute;
	border-radius: 16px;
	overflow: hidden;
	font-size: 40px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.game-content {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.center-block {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: gutter(1.2);
		transform: translateY(-50%);
		top: 50%;
		left: 0;
		right: 0;

		@include media-breakpoint-down(lg) {
			gap: gutter(0.5);
		}

		.play {
			width: 58px;
			height: 58px;
			background: var(--сirebon);

			&:deep(.nuxt-icon) {
				display: inline-flex;

				svg {
					width: 28px;
					height: 28px;
					margin-bottom: 0;

					@include media-breakpoint-down(lg) {
						width: 18px;
						height: 18px;
					}
				}
			}

			@include media-breakpoint-down(lg) {
				min-width: 38px;
				min-height: 38px;
				width: 38px;
				height: 38px;
			}
		}

		.favorite {
			width: 38px;
			height: 38px;
			border-radius: 50%;
			border: 1px solid var(--celaya);
			background: var(--chifeng);
			position: absolute;
			left: calc(50% - 76px);

			&:deep(svg) {
				width: 22px;
				height: 22px;
			}

			@include media-breakpoint-down(lg) {
				min-width: 28px;
				min-height: 28px;
				width: 28px;
				height: 28px;
				left: calc(50% - 52px);

				&:deep(svg) {
					width: 14px;
					height: 14px;
				}
			}

			&--active {
				border: 2px solid var(--cuernavaca);
				background: var(--chitungwiza);
			}
		}

		button {
			transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

			&:deep(.nuxt-icon) {
				transition: all 0.3s ease-in;
			}
		}

		button.ghost {
			font-size: 55px;
			box-shadow: none;

			@include media-breakpoint-down(lg) {
				font-size: 30px;
			}
		}
	}
}

.game-name {
	padding: gutter(1) gutter(0.625) 0 gutter(0.625);
	pointer-events: none;
	margin-bottom: 0;
	position: absolute;
	bottom: gutter(1.25);
	width: 100%;
	text-align: center;
	z-index: 1;

	& span {
		display: flex;
		flex-direction: column;

		@include media-breakpoint-down(lg) {
			font-size: 14px;
		}

		&:deep(*) {
			font-weight: 400;
			font-size: 11px;
			padding-top: gutter(0.375);
			line-height: 1;

			@include media-breakpoint-down(lg) {
				font-size: 9px;
			}
		}
	}
}

.games-list__item--week-game {
	.badge-wrap {
		top: 40px;
	}
}

.badge-wrap {
	position: absolute;
	top: 8px;
	left: 8px;
	z-index: 0;
	height: 22px;

	span {
		color: var(--clara);
	}

	.badge {
		padding: 1px 8px;
	}

	&.grade {
		left: auto;
		right: 8px;

		.badge {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 4px;
			border: 2px solid var(--neutral-800);

			.icon {
				font-size: 14px;
			}
		}

		span {
			color: var(--coro);
		}
	}

	&.new {
		overflow: hidden;
		height: auto;
		border-radius: 999px;

		.info {
			display: flex;
			background: var(--gloucester);
			padding: 2px 8px;
		}

		.blink {
			:deep(> *) {
				&::after {
					width: 20px;
					height: calc(100% + 20px);
				}
			}
		}
	}
}

.title-wrap {
	position: absolute;
	width: 100%;
	bottom: -25px;
	padding: 0;

	@include media-breakpoint-down(lg) {
		bottom: -20px;
	}

	p {
		margin: 0;
		color: var(--clara);
	}
}
</style>
