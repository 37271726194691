import type { AsyncDataOptions } from "#app";

import type { v1, ExtractFromAPI } from "@netgame/openapi";
import { promiseTimeout } from "@vueuse/core";

import useAppInitData from "./useAppInitData";
import useAsyncFetch from "./useAsyncFetch";
import useGamesState from "./useGamesState";
import useIsGuest from "./useIsGuest";

type PiggyResponse = Required<ExtractFromAPI<v1.paths, "/rest/money-box/info/", "get">>;
type ModalOpen<K extends string> = (name: K) => void;

const usePiggy = <K extends string>({
	immediate = true,
	cached = true,
	open = () => {}
}: AsyncDataOptions<PiggyResponse> & {
	cached?: true;
	open?: ModalOpen<K>;
} = {}) => {
	const isGuest = useIsGuest();
	const { select } = useGamesState();
	const { data: appInitData } = useAppInitData();

	const state = useState("piggyState", () => ({
		betAnimation: false,
		prizeAmount: 0
	}));

	const { data: piggyData, refresh: refreshPiggyData } = useAsyncFetch({
		path: "/rest/money-box/info/",
		method: "get",
		options: {
			immediate: immediate && !isGuest.value,
			cached: !cached ? undefined : true,
			watch: [() => !isGuest.value]
		}
	});

	const isFullStatus = computed(() => piggyData.value?.status === "full");
	const isActiveStatus = computed(
		() => piggyData && piggyData.value?.success && piggyData.value?.status !== "inactive"
	);
	const isActivePiggyBank = computed(() => isActiveStatus.value && appInitData.value?.moneyBox?.isActive);
	const isSemiFullStatus = computed(
		() => Number(piggyData.value?.moneyBoxAmount ?? 0) >= Number(piggyData.value?.minAmount ?? 0)
	);
	const readyToBreak = computed(() => isFullStatus.value || isSemiFullStatus.value);

	const piggyGames = computed(() => {
		const gamesIds = (piggyData.value?.games || []).map((id) => Number(id));
		return select(gamesIds);
	});

	const handlePiggyClick = () => {
		if (readyToBreak.value) {
			window?.$cash?.$router?.push?.(
				`/cash/deposit-by-money/${piggyData.value?.promoOffersPresets?.promoOfferPreset?.id ?? 0}/promoOffer/`
			);
			return;
		}
		open("LazyOModalMoneyBox" as K);
	};

	const handleUpdateState = async (balanceVal?: number) => {
		if (state.value.betAnimation) {
			return;
		}
		if (balanceVal) {
			state.value.prizeAmount = balanceVal;
		}
		state.value.betAnimation = true;
		await promiseTimeout(3000);
		state.value.betAnimation = false;
		state.value.prizeAmount = 0;
	};

	const updateMoneyBox = (balance: number) => {
		if (piggyData.value?.moneyBoxAmount !== undefined) {
			piggyData.value.moneyBoxAmount = balance;
		}
	};

	const updateBalanceByBet = (balance: number) => {
		const prevBalance = Number(piggyData.value?.moneyBoxAmount ?? 0);
		const newBalance = Number(balance);
		if (newBalance >= prevBalance) {
			const balance = Number(newBalance - prevBalance).toFixed(2);
			handleUpdateState(balance);
			updateMoneyBox(newBalance);
		}
	};

	const updateBalanceByCollect = (balance: number) => {
		const moneyBoxLimit = Number(piggyData.value?.maxAmount ?? 0);
		const prevBalance = Number(piggyData.value?.moneyBoxAmount ?? 0);
		const newBalance = Number(balance);
		const balanceSummary = prevBalance + newBalance;
		if (balanceSummary >= moneyBoxLimit) {
			updateMoneyBox(moneyBoxLimit);
		} else {
			updateMoneyBox(prevBalance + newBalance);
		}
		handleUpdateState(newBalance);
	};

	const updateStatus = (status?: string) => {
		if (piggyData.value?.status && status) {
			piggyData.value.status = status;
		}
	};

	const updateTimerLimit = (timer?: string) => {
		if (piggyData.value?.availableTimeToBreak && timer) {
			piggyData.value.availableTimeToBreak = timer;
		}
	};

	const resetState = () => {
		state.value.betAnimation = false;
		state.value.prizeAmount = 0;
	};

	return {
		state,
		isFullStatus,
		isActiveStatus,
		isSemiFullStatus,
		piggyData,
		piggyGames,
		readyToBreak,
		isActivePiggyBank,
		refreshPiggyData,
		handlePiggyClick,
		updateMoneyBox,
		updateStatus,
		updateBalanceByBet,
		updateBalanceByCollect,
		updateTimerLimit,
		resetState
	};
};

export default usePiggy;
