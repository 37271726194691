<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		indexGame?: number;
	}>(),
	{ indexGame: 0 }
);

const numberGame = computed(() => props.indexGame + 1);
</script>

<template>
	<div class="game-image-wrapper">
		<AText class="big-number" :size="{ full: 164, lg: 184 }" :modifiers="['bold', 'condensed']">
			{{ numberGame }}
		</AText>
		<slot name="game-card" />
	</div>
</template>

<style scoped lang="scss">
.game-image-wrapper {
	position: relative;
	display: flex;
	justify-content: flex-end;
	width: 237px;
	height: 310px;

	@include media-breakpoint-down(sm) {
		width: 260px;
		height: 326px;
	}

	.big-number {
		color: rgba(11, 8, 12, 0.8);
		-webkit-text-stroke-width: 2px;
		-webkit-text-stroke-color: var(--primary-400);
		font-family: Rubik, sans-serif;
		position: absolute;
		left: 20px;
		bottom: -24px;
		z-index: 9;

		@include media-breakpoint-down(lg) {
			@media (orientation: landscape) {
				left: -10px;
				bottom: -27px;
			}
		}

		@include media-breakpoint-down(sm) {
			bottom: -27px;
			left: 15px;
		}
	}
}
:deep(.game-card) {
	.image-block.h-lg {
		@include media-breakpoint-down(lg) {
			@media (orientation: landscape) {
				height: 306px;
			}
		}
	}
}
</style>
