import useAppInitData from "./useAppInitData";
import useGlobalInfoData from "./useGlobalInfoData";
import useSwitchMode from "./useSwitchMode";

const useFeaturesIsActive = () => {
	const { data } = useGlobalInfoData();
	const { isSweepStakes } = useSwitchMode();
	const { data: appInitData } = useAppInitData();

	const pathDecorator = (locizeKey: string, imgPath: string, isSkin: boolean = false) => ({
		locize: `${locizeKey}${isSkin ? ".skin" : ""}`,
		img: `/nuxt-img/${imgPath}${isSkin ? "/skin" : ""}`,
		isSkin
	});

	const seasonTowerIsActive = computed(() => appInitData.value?.season?.isEnabled);
	const isActiveSeasonTowerFWL = true; // "New Year"
	const locizeSeasonTowerFWL = computed(() => `seasons${isActiveSeasonTowerFWL ? ".skin" : ""}`);
	const imgSeasonTowerFWL = computed(() => `/nuxt-img/seasons${isActiveSeasonTowerFWL ? "/skin" : ""}`);

	const depositStreakIsActive = computed(() => appInitData.value?.depositStreak?.isActive);
	const depositStreakIsActiveSkin = computed(
		() => depositStreakIsActive.value && appInitData.value?.depositStreak?.skin === "skin_wheel_of_wins"
	);
	const depositStreakLocize = computed(() => `depositStreak${depositStreakIsActiveSkin.value ? ".skin" : ""}`);
	const depositStreakImg = computed(() => `/nuxt-img/deposit-streak${depositStreakIsActiveSkin.value ? "/skin" : ""}`);

	const prizeVaultIsActive = computed(
		() => appInitData.value?.depositStreak?.isActive && appInitData.value?.depositStreak?.skin === "prize_vault"
	);
	const prizeVaultIsActiveSkin = computed(
		() => appInitData.value?.depositStreak?.isActive && appInitData.value?.depositStreak?.skin === "skin_prize_vault"
	);

	const bingoLotteryIsActive = computed(() => appInitData.value?.bingo?.isActive);

	const scratchCardsIsActive = computed(() => appInitData.value?.scratchCardLottery?.isActive);
	const scratchCardsNLC = computed(() => ({
		isActive: appInitData.value?.scratchCardLottery?.isActive ?? false,
		...pathDecorator("scratchCards", "scratch-cards", true)
	}));
	const isActiveSkinScratchCardsTAO = "BlackFriday"; // "BlackFriday" || null
	const locizeScratchCardsTAO = computed(() => `scratchCards${isActiveSkinScratchCardsTAO ? ".skin" : ""}`);
	const imgScratchCardsTAO = computed(() => `/nuxt-img/scratch-cards${isActiveSkinScratchCardsTAO ? "/skin" : ""}`);
	const isActiveSkinScratchCardsFWL = true; // "Black Friday"
	const locizeScratchCardsFWL = computed(() => `scratchCards${isActiveSkinScratchCardsFWL ? ".skin" : ""}`);
	const imgScratchCardsFWL = computed(() => `/nuxt-img/scratch-cards${isActiveSkinScratchCardsFWL ? "/skin" : ""}`);

	const cosmicBoxesIsActive = computed(() => appInitData.value?.magicBox?.isActive);
	const isActiveSkinCosmicBoxes = "Lucky Buckets"; // "Lucky Buckets" || null
	const locizeCosmicBoxes = computed(() => `cosmicBoxes${isActiveSkinCosmicBoxes ? ".skin" : ""}`);
	const imgCosmicBoxes = computed(() => `/nuxt-img/cosmic-boxes${isActiveSkinCosmicBoxes ? "/skin" : ""}`);

	const magicBoxesIsActive = computed(() => appInitData.value?.magicBox?.isActive);

	const prizeDropsIsActive = computed(() => appInitData.value?.prizeDrops?.isActive);
	const prizeDropsIsActiveSkinNLC = true; // "X-mas treasure"
	const locizePrizeDropsNLC = computed(() => `prizeDrops${prizeDropsIsActiveSkinNLC ? ".skin" : ""}`);
	const imgPrizeDropsNLC = computed(() => `/nuxt-img/coin-drops${prizeDropsIsActiveSkinNLC ? "/skin" : ""}`);
	const prizeDropsTao = computed(() => ({
		isActive: appInitData.value?.prizeDrops?.isActive ?? false,
		...pathDecorator("prizeDrops", "prize-drops", true)
	}));

	const dailyLoginIsActive = computed(() => appInitData.value?.dailyLogin?.isActive);

	const rankLeagueIsActive = computed(() => appInitData.value?.rankLeague?.enabled);

	const affiliatePartnerIsActive = computed(() => appInitData.value?.isAffiliate);

	const topEmailNotificationIsActive = computed(
		() => appInitData.value?.primaryContact === "email" && !appInitData.value?.emailConfirmed
	);

	const moneyBoxIsActive = computed(() => appInitData.value?.moneyBox?.isActive);
	const redeemIsActive = computed(() => data.value?.status?.redeem?.isActive && isSweepStakes.value);

	return {
		seasonTowerIsActive,
		isActiveSeasonTowerFWL,
		locizeSeasonTowerFWL,
		imgSeasonTowerFWL,
		depositStreakIsActive,
		depositStreakIsActiveSkin,
		depositStreakLocize,
		depositStreakImg,
		prizeVaultIsActive,
		prizeVaultIsActiveSkin,
		bingoLotteryIsActive,
		scratchCardsIsActive,
		scratchCardsNLC,
		isActiveSkinScratchCardsTAO,
		locizeScratchCardsTAO,
		imgScratchCardsTAO,
		magicBoxesIsActive,
		prizeDropsIsActive,
		prizeDropsIsActiveSkinNLC,
		prizeDropsTao,
		locizePrizeDropsNLC,
		imgPrizeDropsNLC,
		rankLeagueIsActive,
		cosmicBoxesIsActive,
		isActiveSkinCosmicBoxes,
		locizeCosmicBoxes,
		imgCosmicBoxes,
		dailyLoginIsActive,
		affiliatePartnerIsActive,
		topEmailNotificationIsActive,
		moneyBoxIsActive,
		redeemIsActive,
		isActiveSkinScratchCardsFWL,
		locizeScratchCardsFWL,
		imgScratchCardsFWL
	};
};

export default useFeaturesIsActive;
