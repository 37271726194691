<script setup lang="ts">
import { camelCase } from "#imports";

import type { Game } from "~/types";

const { t } = useT();
const { isLgDesktopClient: isDesktop } = useWindowClient();
const { pageData, lastGames } = useHomePage({ immediate: false });
const isGuest = useIsGuest();
const { select } = useGamesState();
const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark", toastPosition: "bottom-center" });

const props = defineProps<{
	itemSection: number;
}>();

const games = computed(() => pageData.value?.schemeSections?.[props.itemSection] || []);

const countSlidesVideo = computed(() => {
	if (isDesktop.value) {
		return 9;
	}
	return 7;
});

const isQuickBuy = (game: Game) => game?.slug === "quickbuy" && !isGuest.value;
</script>

<template>
	<template v-for="(game, index) in games" :key="index">
		<OLazyComponent
			v-if="game?.type_component === 'slider_carousel_view'"
			:id="`home-game-carousel-${index}`"
			class="category-section carousel-view"
		>
			<div class="category-head">
				<AText :modifiers="['bold', 'condensed']" :size="{ full: 72, md: 48 }">
					{{ pageData?.gamesInfo[camelCase(game?.slug ?? "")]?.title || "" }}
				</AText>
			</div>
			<ClientOnly>
				<MCarousel
					class="carousel-container"
					:width="isDesktop ? 318 : 198"
					:height="isDesktop ? 356 : 218"
					:space="isDesktop ? 230 : 130"
					paginationIcon="26/arrow-down"
					:display="isDesktop ? 9 : 7"
					:rotateCard="true"
					:isPagination="isDesktop ? true : false"
					:countSlide="select(pageData?.games[camelCase(game?.slug ?? '')])?.length"
				>
					<template #default="{ isCurrent, styles, classes, goSlide }">
						<div
							v-for="(item, gameIndex) in select(pageData?.games[camelCase(game?.slug ?? '')])"
							:key="`slide-${item.id}`"
							:data-index="gameIndex"
							:style="styles(gameIndex)"
							:class="['carousel-slide', classes(gameIndex), { current: isCurrent(gameIndex) }]"
							@click="goSlide(gameIndex)"
						>
							<MGameType
								:game="item"
								:type="game?.type_component"
								:gameIndex="gameIndex"
								@toggle-favorite="handleToggleToFavoriteClick"
							/>
						</div>
					</template>
				</MCarousel>
			</ClientOnly>
		</OLazyComponent>

		<OLazyComponent
			v-if="game?.type_component === 'slider_carousel_vertical_video'"
			:id="`home-game-carousel-video-${index}`"
			class="category-section carousel-view"
		>
			<div class="category-head">
				<AText :modifiers="['bold', 'condensed']" :size="{ full: 72, md: 48 }">
					{{ pageData?.gamesInfo[camelCase(game?.slug ?? "")]?.title || "" }}
				</AText>
			</div>
			<ClientOnly>
				<MCarousel
					class="carousel-container"
					:width="328"
					:height="490"
					:space="isDesktop ? 230 : 130"
					paginationIcon="26/arrow-down"
					:display="countSlidesVideo"
					:rotateCard="true"
					:countSlide="select(pageData?.games[camelCase(game?.slug ?? '')])?.length"
				>
					<template #default="{ isCurrent, styles, classes, goSlide }">
						<div
							v-for="(item, indexGame) in select(pageData?.games[camelCase(game?.slug ?? '')])"
							:key="`slide-${indexGame}`"
							:style="styles(indexGame)"
							:class="['carousel-slide', classes(indexGame), { current: isCurrent(indexGame) }]"
							@click="goSlide(indexGame)"
						>
							<MGameType
								:game="item"
								:type="game?.type_component"
								:gameIndex="indexGame"
								:isActiveCard="isCurrent(indexGame)"
								@toggle-favorite="handleToggleToFavoriteClick"
							/>
						</div>
					</template>
				</MCarousel>
			</ClientOnly>
		</OLazyComponent>
		<OLazyComponent
			v-else-if="game?.slug === 'last' && !isGuest && lastGames?.length"
			id="home-game-last"
			class="category-section"
		>
			<MHomeSectionWrapper :title="t('sectionGames.title.name')" data-tid="recent" path="/issues/all-games/">
				<MGameType
					v-for="(item, num) in lastGames"
					:key="num"
					:game="item"
					:gameIndex="num"
					@toggle-favorite="handleToggleToFavoriteClick"
				/>
			</MHomeSectionWrapper>
		</OLazyComponent>
		<OLazyComponent
			v-else-if="
				game?.type_component !== 'slider_carousel_view' && !!pageData?.games?.[camelCase(game?.slug ?? '')]?.length
			"
			:id="`home-game-${game?.slug}`"
			class="category-section"
		>
			<MHomeSectionWrapper
				:sectionName="game?.slug ?? ''"
				:row="game?.row_count ?? 1"
				:path="`/issues/${game?.slug}/`"
				:title="pageData?.gamesInfo[camelCase(game?.slug ?? '')]?.title"
			>
				<MGameType
					v-for="(item, num) in select(pageData?.games[camelCase(game?.slug ?? '')])"
					:key="num"
					:type="game?.type_component"
					:game="item"
					:gameIndex="num"
					@toggle-favorite="handleToggleToFavoriteClick"
				/>
			</MHomeSectionWrapper>
		</OLazyComponent>
		<OLazyComponent v-if="isQuickBuy(game) && !isGuest" id="quickbuy" class="category-section" :height="700">
			<LazyOHomeQuickBuy />
		</OLazyComponent>
	</template>
</template>

<style lang="scss" scoped>
#quickbuy {
	margin: 32px auto 64px;
}

.category-section {
	display: flex;
	gap: 32px;
	flex-direction: column;
	max-width: calc(100% - 32px);
	width: 1312px;
	margin: 64px auto;

	@include media-breakpoint-down(md) {
		gap: 24px;
		margin: 32px auto;
	}

	&:deep(section) {
		overflow: hidden;
		padding: 0 1px;
	}

	.category-head {
		display: flex;
		flex-direction: column;
		gap: 4px;
		align-items: center;
	}

	&.carousel-view {
		gap: 8px;
		margin: 32px auto;

		@include media-breakpoint-down(sm) {
			gap: 16px;
		}
	}
}

.carousel-container {
	user-select: none;
	min-height: 356px;

	@include media-breakpoint-down(xl) {
		width: calc(100% + 32px);
		margin: 0 -16px;
	}

	@include media-breakpoint-down(lg) {
		@media (orientation: landscape) {
			min-height: 220px;
		}
	}

	@include media-breakpoint-down(sm) {
		min-height: 220px;
	}
}

:deep(.carousel-pagination) {
	.prev,
	.next {
		min-width: 74px;
		min-height: 58px;
		border-radius: 16px;
		border: 2px solid var(--gray-800);
		background: var(--neutral-800);
		box-shadow: 0px 1px 2px 0px var(--cukai);

		svg {
			font-size: 26px;
			margin: 0;
		}

		&:hover {
			opacity: 0.7;
		}
	}
}

.carousel-slide {
	cursor: pointer;
}

.carousel-slide:not(.current) {
	&::before {
		content: "";
		z-index: 999;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		will-change: transform, background;
		transition:
			background 0.3s ease,
			transform 0.3s ease;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)) !important;
		border-radius: 16px;
		pointer-events: none;
	}
	&:deep(.game-card) {
		pointer-events: none;
	}
}
</style>
