<script lang="ts" setup>
const { t } = useT();
const { open } = useAppModals();
const prepareImgUrl = useImage();
const { rewardsAmount } = useCheckRewards();

const bgDesktop = `url(${prepareImgUrl(`/nuxt-img/rewards/onboarding/banner-desc.png`, {
	width: 1312,
	height: 162,
	loading: "lazy"
})})`;

const bgMobile = `url(${prepareImgUrl(`/nuxt-img/rewards/onboarding/banner-mob.webp`, {
	width: 656,
	height: 916,
	loading: "lazy"
})})`;

const handleClick = () => {
	open("LazyOModalOnboardingRewards");
};
</script>

<template>
	<div v-if="rewardsAmount?.activeCount" class="wrapper">
		<div class="content">
			<AText :size="{ full: 14, md: 16 }" class="title" as="div">
				{{ t("banner.rewards.title", { step: rewardsAmount?.activeCount }) }}
			</AText>
			<AText :size="{ full: 48, md: 32 }" class="rewards" as="div" :modifiers="['semibold']">
				<i18n-t keypath="banner.rewards.subtitle">
					<template #coins>
						<MPrizeFund v-if="rewardsAmount?.coins" variant="coins" icon="12/coins" :icon-size="32" :offset="0.5">
							<AText :size="{ full: 48, md: 32 }" :modifiers="['semibold']"> {{ rewardsAmount?.coins }} GC </AText>
						</MPrizeFund>
					</template>
					<template v-if="rewardsAmount?.coins && rewardsAmount?.entries" #and>{{ t("and") }}</template>
					<template #entries>
						<MPrizeFund
							v-if="rewardsAmount?.entries"
							variant="entries"
							icon="12/secret-coins"
							:icon-size="32"
							:offset="0.5"
						>
							<AText :size="{ full: 48, md: 32 }" :modifiers="['semibold']"> {{ rewardsAmount?.entries }} SC</AText>
						</MPrizeFund>
					</template>
				</i18n-t>
			</AText>
			<AAnimationCustomByScroll animationClass="blink" transitionDelay="2s" class="btn-wrapper">
				<AButton variant="primary" class="btn" size="lg" @click="handleClick">
					<AText :size="16" :modifiers="['medium']">
						{{ t("welcome.rewards.btn.getMore") }}
					</AText>
				</AButton>
			</AAnimationCustomByScroll>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.wrapper {
	width: 100%;
	height: 50%;
	border-radius: 24px;
	padding: 2px;
	margin: 0 auto;
	display: flex;
	position: relative;
	overflow: hidden;
	background: linear-gradient(to right, var(--primary-500) -20%, var(--neutral-900));

	@include media-breakpoint-down(md) {
		margin-top: 60px;
	}
}

.content {
	width: 100%;
	padding: 16px 24px;
	border-radius: 23px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
	z-index: 1;
	background: v-bind(bgDesktop) no-repeat center right / cover;

	@include media-breakpoint-down(md) {
		background: v-bind(bgMobile) no-repeat center center / cover;
		padding: 24px;
		height: 458px;
		justify-content: flex-end;
	}
}

.title {
	@include media-breakpoint-down(md) {
		padding-right: 82px;
	}
}

.btn-wrapper {
	margin-top: 24px;

	@include media-breakpoint-down(md) {
		width: 100%;
	}
}

.btn {
	width: 165px;

	@include media-breakpoint-down(md) {
		width: 100%;
	}
}

.rewards {
	&:deep(.prize) {
		@include media-breakpoint-down(md) {
			transform: translateY(7px);
		}
	}

	@include media-breakpoint-down(md) {
		padding-right: 46px;
	}
}
</style>
