<script lang="ts" setup>
import type { HomeBanner } from "~/types";

defineProps<{ banner: HomeBanner }>();

const { open } = useAppModals();
const { questData } = useHomePage();
const { onJoinQuest } = useGameQuest();

const { data: appInit } = useAppInitData();

const loggedIn = computed(() => !appInit.value?.isGuest);

const questInfo = computed(() => {
	if (!questData?.value?.quest && questData.value?.futureQuest) {
		return {
			grandPrize: questData.value?.futureQuest?.grandPrize
		};
	} else {
		return questData?.value?.quest?.questInfo;
	}
});

const handleClick = async () => {
	if (!loggedIn.value) {
		open("LazyOModalSignup");
		return;
	}
	if (!questData?.value?.quest?.isSubscribed && questData?.value?.quest?.questInfo?.activeQuestId) {
		await onJoinQuest(questData?.value?.quest?.questInfo?.activeQuestId);
	}
	open("LazyOModalQuests");
};
</script>

<template>
	<MBanner
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:bg-color="banner.background"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		:type="banner.type"
		@click="handleClick"
	>
		<template #default>
			<AText class="banner-title" variant="tuzla" :modifiers="['uppercase']">
				<p v-html="banner.title" />
			</AText>
		</template>
		<template #description>
			<div v-if="questInfo" class="app-banner__desc">
				<div
					v-if="questInfo.grandPrize && questInfo.grandPrize.coins"
					class="app-banner__desc-money app-banner__desc-money--coins"
				>
					<NuxtImg class="app-banner__desc-ico app-banner__desc-ico--coins" src="/nuxt-img/banners/coins.png" />
					<div class="app-banner__sum app-banner__sum--coins">
						<AText variant="torreon" :modifiers="['bold']">{{
							shortNumberFormatter(questInfo.grandPrize.coins as number, { withPrefix: true })
						}}</AText>
					</div>
				</div>
				<div
					v-if="questInfo.grandPrize && questInfo.grandPrize.entries"
					class="app-banner__desc-money app-banner__desc-money--entries"
				>
					<NuxtImg class="app-banner__desc-ico app-banner__desc-ico--entries" src="/nuxt-img/banners/entries.png" />
					<div class="app-banner__sum app-banner__sum--entries">
						<AText variant="torreon" :modifiers="['bold', 'uppercase']">
							{{ shortNumberFormatter(questInfo.grandPrize.entries as number, { fractionDigits: 2 }) }}
						</AText>
					</div>
				</div>
			</div>
		</template>

		<template #actions>
			<AButton variant="primary" class="app-banner__btn">
				<AText variant="tempe" :modifiers="['bold', 'uppercase']">{{ banner.buttonText }}</AText>
			</AButton>
		</template>
	</MBanner>
</template>
