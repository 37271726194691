<script setup lang="ts">
const emit = defineEmits<{ (event: "closePopper"): void }>();

const { t } = useT();
const { open } = useAppModals();
const { handleSubscribe } = useSubTournament();
const { tournamentData, isSubscribed, entriesSum, coinsSum, durationLeft } = useRace();

const btnText = computed(() =>
	isSubscribed.value ? t("rewardCenter.tournament.btn.name") : t("rewardCenter.tournament.btn.name2")
);

const handleClick = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "race",
		button_name: isSubscribed.value ? "Play now" : "Join",
		form_name: "Right now"
	});

	if (!isSubscribed.value) {
		handleSubscribe({
			id: tournamentData.value?.data?.id ?? 0,
			isSub: isSubscribed.value
		});
	}

	open("LazyOModalRacesGames", { isRaceMode: true });

	emit("closePopper");
};
</script>

<template>
	<MRewardsWrapper
		:button-text="btnText"
		:title="tournamentData?.data?.title ?? ''"
		:intro="!isSubscribed ? t('rewardCenter.tournament.intro') : ''"
		image="/nuxt-img/rewards/tournament.png"
		@click="handleClick"
	>
		<template v-if="isSubscribed">
			<AText :size="12" class="text-cadiz">
				<i18n-t keypath="rewardCenter.tournament.text">
					<template #key>
						<AText :size="12" class="text-coro">{{
							`${tournamentData?.playerData?.roundsPlayed}/${tournamentData?.data?.betLimit}`
						}}</AText>
					</template>
				</i18n-t>
			</AText>
			<AText :size="12" class="text-cadiz">
				<i18n-t keypath="rewardCenter.tournament.text2">
					<template #key>
						<AText :size="12" class="text-coro">{{ tournamentData?.data?.betMin }}</AText>
					</template>
				</i18n-t>
			</AText>
		</template>
		<template v-else>
			<div class="prizes">
				<MPrizeFund v-if="coinsSum" variant="coins" img="/nuxt-img/prizes/rabbitcoins.png" :icon-size="20">
					<AText :modifiers="['semibold', 'uppercase']">
						{{ shortNumberFormatter(coinsSum, { withPrefix: true }) }}
					</AText>
				</MPrizeFund>
				<MPrizeFund v-if="entriesSum" variant="entries" img="/nuxt-img/prizes/supercoins@2x.png" :icon-size="20">
					<AText :modifiers="['semibold', 'uppercase']">
						{{ shortNumberFormatter(entriesSum, { fractionDigits: 2 }) }}
					</AText>
				</MPrizeFund>
			</div>
		</template>
		<template #timeBox>
			<i18n-t keypath="rewardCenter.tournament.timer">
				<template #timer>
					{{ durationLeft }}
				</template>
			</i18n-t>
		</template>
	</MRewardsWrapper>
</template>

<style lang="scss" scoped>
.prizes {
	display: flex;
	gap: 4px;
	align-items: center;

	&:deep(.prize) {
		gap: 4px;
	}
}
</style>
