<script setup lang="ts">
import { toast } from "vue3-toastify";

import type { DetailInvite, RewardNotice } from "@/types";
useGA({});
const { isDesktop } = useDevice();
const { add } = useSockets();
const route = useRoute();
const { t } = useT();
const { data } = useAppInitData();
const { open } = useAppModals();
const { onMessage, onUpdateToken } = useFirebaseNotification();
const { logoutUser } = useLogout();
const { refresh: refreshRewardsData } = useCheckRewardsData({ immediate: false });
const loginGuard = useLoginGuard();
const { checkPendingBonus } = useWithoutDepositBonus(open, t);

const isLoggedIn = computed(() => data.value?.isGuest === false);

useEvent(["SHOW_VERIFICATION_PHONE"], (event: Event) => {
	const { detail } = event as CustomEvent;
	open("LazyOModalPhoneConfirmation", { location: detail?.location });
});
useEvent(["COMPLETE_PROFILE_REWARD"], (event: Event) => {
	const { detail } = event as CustomEvent<{ rewardEntry: boolean; showRewardsModal: boolean }>;
	if (detail?.rewardEntry) {
		window?.$cash?.$router?.push("/");
	}

	if (detail?.showRewardsModal) {
		open("LazyOModalPhoneConfirmation", { confirmPhoneDeposit: !data.value?.phone });
	}

	// TODO here should be reward complete toast
});
useEvent(["LOGOUT_AND_OPEN_AUTH_MODAL"], () => {
	if (window?.$cash?.$store?.getters?.["cash/isOpen"]) {
		window?.$cash?.$router?.push("/");
	}
	logoutUser();
	open("LazyOModalLogin");
});

const showToast = (titleText: string, detail: DetailInvite) => {
	toast.success(
		`
			<div class="toast-invite-title text-cannes">${titleText}</div>
			<div class="toast-invite-content text-cadiz">${t("You earned")}
				${detail.coins ? shortNumberFormatter(Number(detail.coins), { withPrefix: true }) : "0"} ${t("GAME Coins")}
				${t("and")} ${detail.entries ? shortNumberFormatter(Number(detail.entries), { fractionDigits: 2 }) : "0"} ${t("SUPER Coins.")}
			</div>
		`,
		{
			toastId: detail.action,
			icon: h("i", { class: "toast-icon icon-goal" }),
			closeButton: () => h("i", { class: "toast-icon icon-close" }),
			dangerouslyHTMLString: true,
			theme: toast.THEME.DARK,
			position: isDesktop ? toast.POSITION.TOP_RIGHT : toast.POSITION.TOP_CENTER,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 5000
		}
	);
};

add("invite", (detail: DetailInvite) => {
	if (detail.action === "friend-qualified") {
		setTimeout(() => {
			showToast(t("Your friend get qualified!"), detail);
		}, 500);
	}
	if (detail.action === "player-qualified") {
		setTimeout(() => {
			showToast(t("You get qualified!"), detail);
		}, 500);
	}
});

add("contacts", (detail: EventData<RewardNotice>) => {
	if (["confirmPhone", "confirmEmail"].includes(detail.action)) {
		refreshRewardsData();
	}
});

add("profile", (detail: EventData<RewardNotice>) => {
	if (detail.action === "profileComplete") {
		refreshRewardsData();
	}
});

watch(isLoggedIn, (value) => {
	if (value && !data?.value?.emailConfirmed) {
		open("LazyOModalEmailConfirm");
	}
});

onMounted(() => {
	if (route.query?.invited_by) {
		dispatchGAEvent({
			event: "promocode",
			location: "deeplink",
			step: "success"
		});
	}

	loginGuard({
		success: () => {
			if (route.query?.freeEntriesBonus) {
				checkPendingBonus(route.query?.freeEntriesBonus as string);
			}
		}
	});

	pushNotificationsAddEventListener("message", onMessage);
	pushNotificationsAddEventListener("updateToken", onUpdateToken);
});

onUnmounted(() => {
	pushNotificationsRemoveEventListener("message", onMessage);
	pushNotificationsRemoveEventListener("updateToken", onUpdateToken);
});
</script>
<template>
	<div class="layout">
		<ClientOnly>
			<Cash v-if="data?.isGuest === false" />
			<LazyOCookieControl />
			<LazyORewards v-if="data?.isGuest === false" />
		</ClientOnly>
		<div class="header-wrapper">
			<OHeader />
		</div>
		<main>
			<div class="page-content page-content--offset" :class="{ 'is-guest': !isLoggedIn }">
				<div class="wrapper">
					<slot />
				</div>
			</div>
		</main>
		<OFooter :class="{ 'is-logged': isLoggedIn }" />
		<ASvgCollection />
	</div>
</template>

<style lang="scss">
.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.Toastify {
	#friend-qualified,
	#player-qualified {
		width: 328px;
		padding: 12px;
		max-width: 100%;
		margin: auto;

		.toast-invite-title {
			font-size: 14px;
			font-weight: 600;
			line-height: 21px;
		}
		.toast-invite-content {
			font-size: 14px;
			font-weight: 400;
			line-height: 1.6;
		}
		&.Toastify__toast {
			border-radius: 12px;
			border: 2px solid var(--cracow);
			background-color: var(--carletonville);
			padding: 12px;
			min-height: 65px;
		}
		.Toastify__toast-body {
			align-items: flex-start;
		}
		.Toastify__toast-icon {
			width: 40px;
			height: 40px;
			flex-shrink: 0;
			margin: 0 5px 0 0;
		}
		.icon-goal {
			width: 100%;
			height: 100%;
			background-image: url(/public/nuxt-img/referral/friends-qualified.png);
			background-size: contain;
		}
	}
}
</style>

<style lang="scss" scoped>
.layout {
	min-height: 100dvh;
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(lg) {
		footer.is-logged {
			margin-bottom: 56px;
		}
	}
}
.wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0 auto;
}
.header-wrapper {
	width: 100%;
	margin-left: auto;
	position: relative;
	z-index: 20;

	&:has(.email-notify) {
		@include media-breakpoint-up(lg) {
			margin-top: gutter(6.875);
		}

		~ main {
			@include media-breakpoint-down(lg) {
				margin-top: gutter(12);
			}
			@include media-breakpoint-down(md) {
				margin-top: gutter(14.5);
			}

			@media (max-width: 400px) {
				margin-top: gutter(17);
			}
		}
	}
}

main {
	flex: 1;
}

.page-content {
	position: relative;
	display: block;

	&--offset {
		margin-top: 102px;

		@include media-breakpoint-down(lg) {
			margin-top: gutter(7);

			&.is-guest {
				margin-top: 0;
			}
		}
	}
}
</style>
