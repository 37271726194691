<script lang="ts" setup>
import type { HomeBanners } from "~/types";

const { t } = useT();
defineProps<{ banner: HomeBanners[number] }>();
const { open } = useAppModals();
const { handleOpenGame } = useOpenGame(open);
</script>

<template>
	<client-only>
		<MBanner
			class="week-game-banner"
			:bg-img="banner.backgroundImage"
			:bg-img2x="banner.backgroundImage2x"
			:bg-color="banner.background"
			:image="banner.image"
			:image-mobile="banner.imageMobile"
			:second-image="banner.secondImage"
			:second-image-mobile="banner.secondImageMobile"
			:type="banner.type"
			@click="handleOpenGame(banner.buttonLink)"
		>
			<template #default>
				<AText class="title" variant="taylor">
					<p v-html="banner.title" />
				</AText>
			</template>

			<template #description>
				<AText variant="tunja" :modifiers="['bold']" class="text-tlalnepantla subtitle">
					<p v-html="banner.smallTitle" />
				</AText>
			</template>

			<template #actions>
				<AAnimationCustomByScroll animationClass="blink" transitionDelay="1s">
					<AButton variant="primary" size="xl" class="button">
						<AText variant="turin" :modifiers="['medium']" class="text-tlalnepantla">
							{{ banner.buttonText }}
						</AText>
						<NuxtIcon name="48/play" filled />
					</AButton>
				</AAnimationCustomByScroll>
				<div class="rate">
					<AText variant="toledo">{{ t("Our champions love us") }}</AText>
					<OBannersHeroRate />
				</div>
			</template>
		</MBanner>
	</client-only>
</template>

<style lang="scss" scoped>
.week-game-banner {
	padding: 32px 24px;
	align-items: stretch;

	@include media-breakpoint-down(sm) {
		padding: 0;
	}

	&:deep(.app-banner__content) {
		@include media-breakpoint-down(sm) {
			top: 200px;
			padding: 24px 12px;
			align-items: flex-start;
			width: calc(100% - 32px);
			margin: 0 auto;
			border-radius: 16px;
			border: 2px solid var(--chifeng);
			background: var(--czestochowa);
			backdrop-filter: blur(10px);
		}
	}

	&:deep(.app-banner__actions) {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-top: 32px;

		button {
			width: 100%;
			max-width: 194px;
		}

		@include media-breakpoint-down(sm) {
			margin-top: 24px;

			button {
				width: 100%;
				max-width: initial;
			}
		}
	}

	&:deep(.app-banner__desc) {
		margin: 0;
	}

	.subtitle {
		@include media-breakpoint-down(sm) {
			font-size: 32px;
		}
	}

	.rate {
		display: flex;
		flex-direction: column;

		img {
			margin: 12px 0 0 -12px;
			width: 250px;
			height: 36px;
			object-fit: cover;
		}
	}
}
</style>
