<script setup lang="ts">
import type { Game } from "@/types";

const { t } = useT();

defineProps<{
	game: Game;
}>();
</script>

<template>
	<div v-if="game?.isNew || game?.grade" class="badges-row">
		<div v-if="game.isNew" class="badges-wrap new">
			<AAnimationCustomByScroll animationClass="blink" transitionDelay="1s" transition="1s">
				<ABadge :size="30" variant="info" autosize background="linear-gradient(113deg, #00FE37 0.1%, #024358 101.59%)">
					<AText class="text-coro" :size="10" :modifiers="['semibold', 'uppercase']">{{ t("New") }} </AText>
				</ABadge>
			</AAnimationCustomByScroll>
		</div>
		<div v-if="game.grade" class="badges-wrap grade">
			<ABadge :size="30" variant="info" autosize background="var(--neutral-950)">
				<ASvg name="14/rate" class="icon" />
				<AText class="text-coro" :size="10" :modifiers="['semibold', 'uppercase']">{{ game.grade }}</AText>
			</ABadge>
		</div>
	</div>
</template>

<style scoped lang="scss">
.badges {
	&-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 4px;
	}

	&-wrap {
		position: relative;
		height: 22px;

		.badge {
			padding: 1px 8px;
		}

		&.grade {
			margin-left: auto;
			.badge {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 4px;
				border: 2px solid var(--neutral-800);

				.icon {
					font-size: 14px;
				}
			}
		}

		&.new {
			overflow: hidden;
			height: auto;
			border-radius: 999px;

			.info {
				display: flex;
				background: var(--gloucester);
				padding: 2px 8px;
			}

			.blink {
				:deep(> *) {
					&::after {
						width: 20px;
						height: calc(100% + 20px);
					}
				}
			}
		}
	}
}
</style>
