<script setup lang="ts">
const { t } = useT();

const props = withDefaults(
	defineProps<{
		goal?: number;
		complete?: number;
	}>(),
	{
		goal: 7,
		complete: 0
	}
);

const pregressValue = computed(() => {
	if (props?.goal === props?.complete) {
		return "100%";
	}
	if (props?.complete < props?.goal) {
		return `${(100 / props?.goal) * props?.complete}%`;
	}

	return "8px";
});
</script>

<template>
	<div class="progress">
		<div class="header">
			<AText class="text-constanta">
				{{ t("dailyLogin.promo.txt.days") }}
			</AText>
			<AText as="div" class="progress-value" :modifiers="['semibold']">
				{{ `${numberFormat(complete)}/${numberFormat(goal)}` }}
			</AText>
		</div>
		<div class="progress-wrapper">
			<div class="progress-thumb" :style="{ width: pregressValue }" />
			<NuxtImg
				src="/nuxt-img/daily-login/prize.png"
				class="prize"
				height="80"
				width="80"
				format="avif"
				loading="lazy"
				alt="daily-nav-mob"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.progress {
	position: relative;
	display: flex;
	gap: 4px;
	flex-direction: column;
}

.header {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}

.progress-wrapper {
	width: 100%;
	height: 12px;
	border-radius: 10px;
	border: 1px solid var(--cordoba);
	background: var(--neutral-950);
	padding: 2px;
	margin-top: 4px;
	position: relative;

	.prize {
		position: absolute;
		right: -11px;
		top: -17px;
		width: 40px;
		height: 40px;
	}
}

.progress-value {
	text-align: center;
	width: max-content;
}

.progress-thumb {
	transition: 0.3s;
	height: 100%;
	min-width: 8px;
	background: var(--gradient-g-1);
	border-radius: 10px;
}
</style>
