import { apiClient, type ExtractFromAPI, v1 } from "@netgame/openapi";
import { toast, type ToastTheme, type ToastPosition } from "vue3-toastify";

// @ts-ignore
import AAnimationSlide from "../../ui/atoms/animations/AAnimationSlide.vue";

import useAppInitData from "./useAppInitData";

type AllGamesResponse = ExtractFromAPI<v1.paths, "/rest/page/issues/all-games/", "get">;
type GamesPayload = NonNullable<AllGamesResponse["payload"]>;
type Game = NonNullable<GamesPayload["games"]>[number];

const useAddGameToFavorite = <T extends (key: string) => string>({
	t,
	toastTheme = toast.THEME.LIGHT,
	toastPosition = toast.POSITION.BOTTOM_RIGHT
}: {
	t: T;
	toastTheme?: ToastTheme;
	toastPosition?: ToastPosition;
}) => {
	const { isDesktop } = useDevice();
	const { data: appInit } = useAppInitData();
	const { theme } = useTheme();

	const activeIds = ref<{
		remove: { id: number | string | null; count: number; active: boolean };
		add: { id: number | string | null; count: number; active: boolean };
	}>({ remove: { id: null, count: 0, active: false }, add: { id: null, count: 0, active: false } });

	const isBadge = computed(() => theme.value === "jackpotRabbit");

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getRightText = (text: string, counter: number, isActive: boolean): any =>
		h("div", [
			h(
				AAnimationSlide,
				{
					active: isActive,
					mode: "left-to-right",
					iterationCount: 3,
					distance: 5,
					duration: 0.2,
					class: isBadge || counter ? "badge" : "badge--hidden"
				},
				() => h("span", { class: "badge-text" }, counter)
			),
			text
		]);

	const showToast = ({
		text,
		icon,
		type,
		countOfGames
	}: {
		text: string;
		icon: string;
		type: keyof typeof activeIds.value;
		countOfGames: number;
	}) => {
		activeIds.value[type].count = countOfGames;
		// if (activeIds.value[type].count > 1) {
		toast.success(getRightText(text, activeIds.value[type].count, activeIds.value[type].active), {
			toastId: type + countOfGames,
			theme: toastTheme,
			position: isDesktop ? toastPosition : "top-center",
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 1500,
			containerId: "favorite-game-notify",
			icon: h("i", { class: `toast-icon ${icon}` }),
			onClose: () => {
				activeIds.value[type] = { id: null, count: activeIds.value[type].count, active: false };
			}
		});
	};

	const handleToggleToFavoriteClick = async (game: Game) => {
		if (!game || !game.id || !appInit.value) {
			return;
		}

		const favoritesSet = new Set(appInit.value?.favoriteGames);
		const favLastGames = appInit.value?.favLast ?? [];
		const favLastIndex = favLastGames.indexOf(game.id);

		if (game.isFavorite) {
			favoritesSet.delete(game.id);

			if (!appInit.value?.lastGames?.includes(game.id)) {
				favLastGames.splice(favLastIndex, 1);
			}

			showToast({
				text: t("Game removed from your favorites"),
				icon: "icon-favorite remove",
				type: "remove",
				countOfGames: favoritesSet.size
			});
		} else {
			if (favLastIndex > -1) {
				favLastGames.splice(favLastIndex, 1);
			}

			favoritesSet.add(game.id);
			favLastGames.splice(0, 0, game.id);
			showToast({
				text: t("Game added to your favorites"),
				icon: "icon-favorite add",
				type: "add",
				countOfGames: favoritesSet.size
			});
		}

		appInit.value.favoriteGames = [...favoritesSet];
		game.isFavorite = !game.isFavorite;

		await apiClient({
			path: "/rest/favorite-game/{id}/",
			method: "get",
			parameters: { path: { id: game.id } }
		});
	};

	if (process.client) {
		watch(
			() => activeIds.value.remove.count,
			() => {
				activeIds.value.remove.active = true;
				setTimeout(() => {
					activeIds.value.remove.active = false;
				}, 300);
			},
			{ deep: true }
		);

		watch(
			() => activeIds.value.add.count,
			() => {
				activeIds.value.add.active = true;
				setTimeout(() => {
					activeIds.value.add.active = false;
				}, 300);
			},
			{ deep: true }
		);
	}

	return { handleToggleToFavoriteClick };
};
export default useAddGameToFavorite;
