<script setup lang="ts">
import type { Promotion } from "~/types";

import dayjs, { Dayjs } from "dayjs";

const { t } = useT();

const props = defineProps<{
	image: string;
	title: string;
	subTitle: string;
	coins: number;
	entries: number;
	dateStart?: string | number | Dayjs;
	type?: Promotion["type"];
	dateFinish?: string | number | Dayjs;
	isActive?: boolean;
	isSubscribed?: boolean;
}>();

const textBtn = computed(() => {
	if (!props.isActive) {
		return t("More Info");
	}
	return props.isSubscribed ? t("Play now") : t("Join Now");
});
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 316,
			height: 392,
			src: image,
			format: 'avif',
			loading: 'lazy',
			alt: 'race-promotion-img'
		}"
		width="316px"
		height="392px"
		class="card-race"
	>
		<template #top>
			<div class="badge-wrapper">
				<ABadge class="free" background="var(--carabanchel)" variant="info" autosize>
					<AText variant="topeka" class="badge text-coro" :modifiers="['semibold', 'uppercase']">
						{{ t("Free activity") }}
					</AText>
				</ABadge>

				<ABadge class="label-time" background="var(--carabanchel)" variant="info" autosize>
					<AText variant="topeka" class="badge text-cannes" :modifiers="['semibold', 'uppercase']">
						{{ isActive ? t("Ends in:") : t("Starts at") }}
						{{ isActive ? dateFinish : dayjs(dateStart).format("h:mm A") }}
					</AText>
				</ABadge>
			</div>
		</template>
		<template #bottom>
			<div class="box">
				<AText variant="toledo" :modifiers="['semibold']">{{ title }} {{ t("Tournament") }} </AText>
				<AText variant="tempe" :modifiers="['normal']" class="subtitle">
					{{ subTitle }}
				</AText>
				<MPrizeFund v-if="coins" variant="coins" img="/nuxt-img/prizes/rabbitcoins.png" :icon-size="26" :offset="0.5">
					<AText variant="tanzay" class="text-count" :modifiers="['bold', 'uppercase']">
						{{ shortNumberFormatter(coins, { withPrefix: true }) }}
					</AText>
				</MPrizeFund>
				<MPrizeFund
					v-if="entries"
					variant="entries"
					:class="{ 'multi-prize': coins && entries }"
					img="/nuxt-img/prizes/supercoins.png"
					:icon-size="26"
					:offset="0.5"
				>
					<AText variant="taipei" :modifiers="['semibold', 'uppercase']" class="text-count">
						{{ shortNumberFormatter(entries, { fractionDigits: 2, ignoreDigitsForInteger: true }) }}
					</AText>
					<AText variant="ternopil" class="count-after" :modifiers="['semibold', 'uppercase']">
						{{ t("Free Super Coins") }}
					</AText>
				</MPrizeFund>
				<AButton size="lg" variant="primary" class="button">
					<AText variant="toledo" :modifiers="['medium']">
						{{ textBtn }}
					</AText>
				</AButton>
			</div>
		</template>
	</ACard>
</template>

<style lang="scss" scoped>
.badge-wrapper {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	gap: 8px;
	white-space: nowrap;
	overflow: hidden;
}
.label-time {
	border: 2px solid var(--chifeng);
}
.card-race {
	border: 2px solid var(--celaya);
	border-radius: 16px;
	justify-content: center;
	gap: gutter(1);
	background: var(--grimsby);
	overflow: visible;

	&:deep(> img) {
		margin: auto;
		border-radius: 14px;
	}

	&:deep(.prize) {
		gap: gutter(0.5);
		justify-content: center;

		&.multi-prize {
			&::before {
				content: "+";
				font-size: 24px;
				font-weight: 600;
			}
		}

		&.entries {
			width: max-content;
			margin: 0 auto;
			height: 26px;
			border-radius: 999px;
			background: linear-gradient(90deg, rgba(0, 239, 67, 0.5) 0%, rgba(0, 239, 67, 0) 100%);
		}
	}

	.promo-timer {
		display: flex;
		align-items: center;
		gap: gutter(0.5);
	}

	.text-count {
		text-shadow: 0 4px 4px var(--chuncheon);
	}

	.count-after {
		text-align: left;
		max-width: 70px;
	}

	.box {
		position: absolute;
		left: calc(50% - 142px);
		bottom: -28px;
		border-radius: 16px;
		border: 2px solid var(--cordoba);
		background: var(--czestochowa);
		backdrop-filter: blur(45px);
		padding: 12px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: center;
		width: 284px;
		height: auto;
		gap: 8px;
	}

	.badge {
		height: 26px;
		display: flex;
		align-items: center;
		padding-right: gutter(0.5);
		padding-left: gutter(0.5);
		box-shadow: none;

		&.free {
			border: 2px solid var(--cuautitlan);
			& > span {
				color: var(--cuautitlan);
			}
		}
	}
}
</style>
