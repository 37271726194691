<script lang="ts" setup>
const { t } = useT();
const { open } = useAppModals();
const { select } = useGamesState();
const loginGuard = useLoginGuard();
const { pageData, gameOfWeek, favoriteGames } = useHomePage({ immediate: false });
const prepareImgUrl = useImage();
const activeTab = ref("");

const goToPage = (link: string) => {
	loginGuard({
		success: () => {
			navigateTo(link);
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};

const toKebabCase = (str: string) => str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();

const handleClick = (key: string) => {
	activeTab.value = key;
};

const gamesList = computed(() => {
	if (activeTab.value === "favorites") {
		return favoriteGames.value;
	}
	const activeGame = Object.entries(pageData.value?.gamesInfo || {}).find(([_, el]) => el?.slug === activeTab.value);

	return select(pageData.value?.games?.[activeGame ? activeGame[0] : ""] || []);
});

const bgDesktop = `url(${prepareImgUrl(`/nuxt-img/promotions/howItWorks.png`, {
	format: "webp"
})})`;

const menu = computed(() => [
	...Object.entries(pageData.value?.gamesInfo || {}).map(([slug, value]) => ({
		slug,
		img: value.logo,
		...value
	})),
	{
		slug: "collections",
		title: t("Collections"),
		img: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.875 3H4C3.44772 3 3 3.44772 3 4V9.875C3 10.4273 3.44772 10.875 4 10.875H10.875V3ZM13.125 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H13.125V3ZM4 13.125H10.875V21H4C3.44772 21 3 20.5523 3 20V14.125C3 13.5727 3.44772 13.125 4 13.125Z" fill="white"/>
          </svg>`,
		url: "/issues/collections/"
	}
]);

onMounted(() => {
	if (process.client) {
		setTimeout(() => handleClick(menu?.value?.[0]?.slug), 1000);

		return;
	}
	handleClick(menu?.value?.[0]?.slug);
});
</script>

<template>
	<div class="wrapper">
		<div class="top">
			<AText variant="tianshui" class="text-cannes title" :modifiers="['bold']">
				{{ t("Games for every taste") }}
			</AText>
			<div class="hiw">
				<AText variant="tempe" class="title" :modifiers="['normal']">
					{{ t("How it works") }}
				</AText>
				<AText variant="taipei" class="desc" :modifiers="['semibold']">
					{{ t("Learn how to play & win at JackpotRabbit") }}
				</AText>
				<AButton variant="primary" class="btn" size="lg" @click="goToPage('/how-it-works/')">
					<AText variant="toledo" :modifiers="['medium']">
						{{ t("Find Out More") }}
					</AText>
				</AButton>
			</div>
		</div>

		<OGamesNavigation
			v-if="!!(menu && menu.length)"
			class="game-navigation"
			:menu="menu"
			:activeTab="activeTab"
			isTabs
			@click-tab="handleClick"
		/>

		<OGames
			:isAllGamesView="activeTab === 'all-games'"
			:isGameOfWeek="activeTab === 'all-games'"
			:gameOfWeek="gameOfWeek"
			:games="gamesList || []"
		/>

		<div class="action">
			<AButton variant="secondary" class="btn" size="lg" @click="goToPage(`/issues/${toKebabCase(activeTab)}/`)">
				<AText variant="toledo" :modifiers="['medium']">
					{{ t("See All Games") }}
				</AText>
			</AButton>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.wrapper {
	width: 100%;
}
.top {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.hiw {
	width: 648px;
	height: 194px;
	padding: 16px 24px;
	border-radius: 24px;
	border: 2px solid var(--cordoba);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background: v-bind(bgDesktop) no-repeat center center / cover;

	@include media-breakpoint-down(lg) {
		@media (orientation: landscape) {
			display: none;
		}
	}

	@include media-breakpoint-down(md) {
		display: none;
	}

	&:hover {
		cursor: pointer;
	}

	.desc {
		max-width: 290px;
		margin-top: 4px;
	}

	.btn {
		margin-top: auto;
	}
}

.title {
	max-width: 400px;
	line-height: 1.14;

	@include media-breakpoint-down(md) {
		font-size: 48px;
	}
}

.game-navigation {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	margin: 32px 0;
	width: 100%;

	@include media-breakpoint-down(sm) {
		margin: 22px 0;
	}

	@keyframes pulse {
		0% {
			width: 0;
			height: 0;
		}
		100% {
			width: 100%;
			height: 48px;
		}
	}
	@keyframes scaleDown {
		0% {
			transform: scale(1.2);
		}
		50% {
			transform: scale(0.8);
		}
		100% {
			transform: scale(1.2);
		}
	}
	button {
		min-width: max-content;
		height: 48px;
		border-width: 2px;
		flex-direction: row;
		gap: 8px;
		padding: 0 16px;
		border-radius: 999px;

		& > * {
			position: relative;
			z-index: 2;
		}

		&::before {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			border-radius: inherit;
			background: var(--carletonville);
		}

		&.active {
			background: var(--celaya);
			position: relative;
			&:hover {
				opacity: 1;
			}

			&::before {
				opacity: 1 !important;
				animation: pulse 0.25s ease-out forwards;
			}
			&:deep(svg) {
				animation: scaleDown 2s ease-out infinite;
				path {
					fill: var(--сirebon);
				}
			}
		}

		:deep(svg) {
			font-size: 24px;
			margin: 0;
		}
	}

	&::-webkit-scrollbar,
	&::-webkit-scrollbar-thumb {
		width: 0;
		height: 0;
	}
}

.action {
	width: 100%;
	position: relative;
	margin-top: gutter(4);

	button {
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}
}
</style>
