<script setup lang="ts">
import type { WidgetFeature } from "~/types";

import type { KeenSliderInstance, KeenSliderOptions } from "keen-slider";

const emit = defineEmits<{ (event: "toggleAsidebar"): void }>();

const { data: appInitData } = useAppInitData();
const isGuest = useIsGuest();
const { moneyBoxIsActive, bingoLotteryIsActive, depositStreakIsActive, prizeDropsIsActive } = useFeaturesIsActive();
const { data: bonusesData } = useBonusesData();
const { tournamentExist } = useTournamentData();
const { entriesSum, coinsSum } = useRace();
const { isAvailable, isActiveDailyLogin } = useDailyLogin();

const featuresPriority = computed(() => appInitData.value?.featuresPriority?.cardsInMainMenu);
const bannerPromo = computed(() => appInitData.value?.popupPresetPackage);
const showBingoPromo = computed(() => !isGuest.value && bingoLotteryIsActive.value);
const isShowWheel = computed(
	() => bonusesData.value?.dailyWheel?.available && bonusesData.value?.dailyWheel?.reasonType !== "time"
);

const widgetList = computed<Record<string, WidgetFeature>>(() => ({
	dailyWheel: { component: "MWheelSidebarWidget", isActive: isShowWheel.value },
	bingo: { component: "OBingoSidebarWidget", isActive: showBingoPromo.value },
	moneyBox: { component: "OPiggyBankSidebarWidget", isActive: moneyBoxIsActive.value },
	depositStreak: { component: "ODepositStreakSidebarWidget", isActive: depositStreakIsActive.value },
	prizeDrops: { component: "OPrizeDropsSidebarWidget", isActive: prizeDropsIsActive.value },
	dailyLogin: { component: "ODailyLoginSidebarWidget", isActive: isActiveDailyLogin.value && isAvailable.value },
	bestDeal: {
		component: "OHomeBannerPromotionItem",
		isActive: !!bannerPromo.value && bannerPromo.value.promoOfferPreset?.bestDeal,
		props: { offer: bannerPromo.value }
	},
	mostPopular: {
		component: "OHomeBannerPromotionItem",
		isActive: !!bannerPromo.value && bannerPromo.value.promoOfferPreset?.mostPopular,
		props: { offer: bannerPromo.value }
	},
	tournament: {
		component: "MTournamentsSidebarWidget",
		isActive: tournamentExist.value,
		props: { entries: entriesSum.value, coins: coinsSum.value }
	}
}));

const activeFeatures = computed(() =>
	featuresPriority.value?.filter((item) => !!widgetList.value[item] && widgetList.value[item].isActive)
);

const interval = ref();
const sliderActiveSlide = ref(0);
const sliderAsidebarRef = ref();

const autoplay = (slider: KeenSliderInstance) => {
	clearInterval(interval.value);
	interval.value = setInterval(() => {
		if (slider.track.details) {
			slider.track.details.maxIdx === slider.track.details.abs ? slider.moveToIdx(0) : slider.next();
		}
	}, 5000);
};

const sliderOptions: KeenSliderOptions = {
	loop: false,
	mode: "snap",
	slides: { perView: 1, spacing: 8 },
	created(slider) {
		autoplay(slider);
	},
	slideChanged(slider: KeenSliderInstance) {
		autoplay(slider);
		sliderActiveSlide.value = slider.track.details.abs;
	}
};

if (process.client) {
	watch(activeFeatures, () => {
		sliderAsidebarRef.value?.slider?.update();
	});
}

onBeforeUnmount(() => {
	clearInterval(interval.value);
});
</script>

<template>
	<div>
		<ASlider ref="sliderAsidebarRef" :options="sliderOptions">
			<div v-for="(feature, index) in activeFeatures" :key="index" class="keen-slider__slide">
				<component
					:is="widgetList[feature].component"
					v-bind="widgetList[feature]?.props"
					:class="`widget-sidebar ${feature}`"
					@toggle-sidebar="emit('toggleAsidebar')"
				/>
			</div>

			<template v-if="activeFeatures?.length && activeFeatures?.length > 1" #dots="{ slider }">
				<div class="pagination">
					<div
						v-for="(_, index) in activeFeatures?.length"
						:key="index"
						:class="['pagination-item', { 'is-active': sliderActiveSlide === index }]"
						@click="slider?.moveToIdx(index)"
					/>
				</div>
			</template>
		</ASlider>
	</div>
</template>

<style lang="scss" scoped>
.keen-slider__slide {
	padding-top: 10px;
}

.widget-sidebar {
	width: calc(100% - 4px);
	max-height: 112px;

	&.mostPopular,
	&.bestDeal {
		max-height: 140px;
	}
}

.pagination {
	display: flex;
	justify-content: center;
	gap: 4px;
	margin-top: 8px;
	padding: 0 16px;

	&-item {
		flex-grow: 1;
		max-width: 32px;
		height: 4px;
		border-radius: 12px;
		background: var(--gray-800);
		cursor: pointer;

		&.is-active {
			background: var(--primary-500);
		}
	}
}
</style>
