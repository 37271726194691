<script setup lang="ts">
const isGuest = useIsGuest();
const { open } = useAppModals();
const loginGuard = useLoginGuard();
const { t } = useT();
const { isCanPlay } = useBingoData({ immediate: false });

const subTitle = computed(() => (isGuest.value ? t("bingo.promo.subtitle.name1") : t("bingo.promo.subtitle.name2")));
const btnText = computed(() => {
	if (isGuest.value) {
		return t("bingo.promo.btn.name1");
	}
	if (isCanPlay.value) {
		return t("bingo.promo.btn.name2");
	}
	return t("bingo.promo.btn.name3");
});

const handleClick = () => {
	loginGuard({
		success: () => {
			if (isCanPlay.value) {
				open("LazyOModalBingoPlay");
				return;
			}
			open("LazyOModalRacesGames");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 316,
			height: 392,
			src: '/nuxt-img/bingo/promo-bg.webp'
		}"
		width="316px"
		height="392px"
		class="card-bingo"
		@click="handleClick"
	>
		<template #top>
			<div class="badge-wrapper">
				<ABadge class="free" background="var(--carabanchel)" variant="info" autosize>
					<AText :size="12" class="badge text-coro" :modifiers="['semibold', 'uppercase']">
						{{ t("bingo.promo.badge.name") }}
					</AText>
				</ABadge>
			</div>
		</template>
		<template #bottom>
			<div class="box">
				<AText class="title" :size="16" :modifiers="['semibold']">
					{{ t("bingo.promo.title") }}
				</AText>
				<AText class="info-txt" as="p" :modifiers="['normal']">
					{{ subTitle }}
				</AText>
				<div class="bingo-prizes">
					<MPrizeFund variant="coins" img="/nuxt-img/prizes/rabbitcoins.png" :icon-size="26" :offset="0.5">
						<AText :size="32" :modifiers="['bold']">
							{{ t("bingo.promo.prize.coins") }}
						</AText>
					</MPrizeFund>
					<MPrizeFund variant="entries" img="/nuxt-img/prizes/supercoins@2x.png" :icon-size="22" :offset="0.5">
						<template #before>
							<AText :size="24" :modifiers="['semibold']">+</AText>
						</template>
						<template #default>
							<AText :size="24" :modifiers="['semibold']">
								{{ t("bingo.promo.prize.entries") }}
							</AText>
						</template>
						<template #after>
							<AText :size="10" :modifiers="['semibold', 'uppercase']">
								<i18n-t keypath="bingo.promo.text">
									<template #br>
										<br />
									</template>
								</i18n-t>
							</AText>
						</template>
					</MPrizeFund>
				</div>
				<MBingoProgress small />
				<AButton size="lg" variant="primary" class="promo">
					<AText :size="16" :modifiers="['medium']">
						{{ btnText }}
					</AText>
				</AButton>
			</div>
		</template>
	</ACard>
</template>

<style lang="scss" scoped>
.card {
	color: var(--coro);
	background: var(--graz);
	border: 2px solid var(--celaya);
	border-radius: 16px;
	overflow: visible;

	&:deep(> img) {
		margin: auto;
		border-radius: 14px;
	}

	.badge-wrapper {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		gap: 8px;
	}

	.bingo-prizes {
		display: flex;
		flex-direction: column;
		align-items: center;

		&:deep(.prize) {
			gap: 4px;
		}
	}

	.badge {
		height: 26px;
		display: flex;
		align-items: center;
		padding-right: 4px;
		padding-left: 4px;
		box-shadow: none;
		white-space: nowrap;
		overflow: hidden;

		&.free {
			border: 2px solid var(--cuautitlan);

			& > span {
				color: var(--cuautitlan);
			}
		}
	}

	&:deep(.prize) {
		justify-content: center;
	}

	.title {
		padding: 2px 0;
	}
}

.box {
	position: absolute;
	left: calc(50% - 142px);
	bottom: -28px;
	border-radius: 16px;
	border: 2px solid var(--cordoba);
	background: var(--clovis);
	backdrop-filter: blur(45px);
	padding: 12px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
	width: 284px;
	height: auto;
	gap: 8px;

	&:deep(.progress-wrapper.small) {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 8px;

		.progress {
			max-width: 142px;
			margin: 0;
		}
	}
}
</style>
