<script lang="ts" setup>
import type { PageCollection, Games } from "~/types";
const { t } = useT();
const { open } = useAppModals();
const { data: collections } = useGetPageCollectionsData();

const openGamesModal = (collection: PageCollection) => {
	open("LazyOModalCollectionsGames", { title: collection.title, collectionSlug: collection.slug });
};

const handleNavigateToPage = () => {
	navigateTo("/issues/collections/");
};
</script>
<template>
	<div v-if="collections && collections.length">
		<MSliderWrapper
			:list="collections ?? []"
			title="Rabbit's Collections"
			:spacing="16"
			showAll
			@view-all-action="handleNavigateToPage"
		>
			<template #default="{ item }">
				<MCollectionCard
					:class="`keen-slider__slide ${item.slug}`"
					:slug="item.slug"
					:title="item.title"
					:logo="item.logo"
					:bgColor="item.bgColor"
					:games="item.games as Games"
					@click="openGamesModal(item)"
				/>
			</template>
		</MSliderWrapper>

		<AButton variant="secondary" class="btn" size="lg" @click="handleNavigateToPage">
			<AText variant="toledo" :modifiers="['medium']">
				{{ t("See All Collections") }}
			</AText>
		</AButton>
	</div>
</template>
<style lang="scss" scoped>
:deep(.cards-feed__container) > div {
	width: 316px;
	flex-shrink: 0;

	.collection {
		height: 348px;
		width: 100%;
		min-width: unset;

		@include media-breakpoint-down(md) {
			height: auto;
			padding-bottom: 20px;
		}
		.badge-exclusive {
			display: none;
		}
	}
}

.keen-slider__slide {
	@include media-breakpoint-down(md) {
		transform: none !important;
	}
}

.btn {
	margin-top: 30px;

	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}
</style>
