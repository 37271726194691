<script setup lang="ts">
const { t } = useT();
const loginGuard = useLoginGuard();
const { open } = useAppModals();

defineProps<{
	image: string;
	levels: number;
	activeLevel: number;
	durationLeft: string;
	isAvailable: boolean;
	isCompleted?: boolean;
}>();

const onChooseOffer = () => {
	loginGuard({
		success: () => {
			open("LazyOModalDailyLogin");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 632,
			height: 784,
			src: image,
			format: 'webp',
			loading: 'lazy'
		}"
		width="632px"
		height="784px"
		class="card-daily-login"
		@click="onChooseOffer"
	>
		<template #top>
			<div class="badge-wrapper">
				<ABadge class="free" background="var(--neutral-950)" variant="info" autosize>
					<AText class="badge text-coro" :size="12" :modifiers="['semibold', 'uppercase']">
						{{ t("dailyLogin.promo.txt.activity") }}
					</AText>
				</ABadge>
			</div>
		</template>
		<template #bottom>
			<div class="box">
				<AText class="title" :size="16" :modifiers="['semibold']">
					{{ t("dailyLogin.promo.title") }}
				</AText>
				<AText class="info-txt" :modifiers="['normal', 'center']">
					{{ isAvailable || isCompleted ? t("dailyLogin.promo.txt.awarded") : t("dailyLogin.promo.txt.comeBack") }}
				</AText>

				<ODailyLoginProgressBar class="progress" :complete="activeLevel ?? 0" :goal="levels ?? 0" />

				<AText v-if="isCompleted" class="congrats" :modifiers="['normal', 'center']">
					{{ t("dailyLogin.promo.txt.congrats") }}
				</AText>
				<template v-else>
					<AText class="timer" :modifiers="['normal', 'center']">
						{{ isAvailable ? t("dailyLogin.promo.timer.expires") : t("dailyLogin.promo.timer.next") }}
						<b class="text-cannes"> {{ durationLeft }}</b>
					</AText>
					<AButton v-if="isAvailable" size="lg" variant="primary" class="btn">
						<AText variant="toledo" :modifiers="['medium']">
							{{ t("dailyLogin.promo.btn.claim") }}
						</AText>
					</AButton>
				</template>
			</div>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.badge-wrapper {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	gap: 8px;
}

.card-daily-login {
	color: var(--neutral-50);
	background: var(--gray-800);
	border: 2px solid var(--cordoba);
	border-radius: 16px;
	overflow: visible;
	width: 316px;
	height: 392px;

	&:deep(> img) {
		margin: auto;
		border-radius: 14px;
	}

	.badge {
		height: 26px;
		display: flex;
		align-items: center;
		padding-right: 4px;
		padding-left: 4px;
		box-shadow: none;
		white-space: nowrap;
		overflow: hidden;

		&.free {
			border: 2px solid var(--success-500);

			& > span {
				color: var(--success-500);
			}
		}
	}

	.title {
		margin-bottom: 2px;
	}

	.info-txt {
		color: var(--neutral-100);
		margin-bottom: 8px;
	}

	.timer {
		color: var(--neutral-100);
		max-width: 200px;
		margin: 12px 0;

		b {
			color: var(--neutral-50);
		}
	}

	.congrats {
		color: var(--neutral-100);
		margin-top: 8px;
	}
}

.progress {
	width: 100%;
}

.box {
	position: absolute;
	left: calc(50% - 142px);
	bottom: -28px;
	border-radius: 16px;
	border: 2px solid var(--cordoba);
	background: var(--overlay-300);
	backdrop-filter: blur(45px);
	padding: 12px 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 284px;
	height: auto;
}

.btn {
	width: 100%;
}
</style>
