<script setup lang="ts">
import { toast } from "vue3-toastify";

import type { Promo } from "@/types";

const props = defineProps<{
	data?: Promo;
}>();

const { t } = useT();
const { isDesktop } = useDevice();
const { copy } = useClipboard();

const handleClick = () => {
	copy(props.data?.data?.referralUrl || "");

	toast.success(t("rewardCenter.invite.toast.name"), {
		containerId: "referralModal",
		icon: h("i", { class: "toast-icon icon-checked" }),
		closeButton: () => h("i", { class: "toast-icon icon-close" }),
		theme: toast.THEME.DARK,
		position: isDesktop ? toast.POSITION.TOP_RIGHT : toast.POSITION.TOP_CENTER,
		transition: toast.TRANSITIONS.SLIDE,
		autoClose: 5000
	});
};
</script>

<template>
	<MRewardsWrapper
		:button-text="t('rewardCenter.invite.btn.name')"
		class="invite-rewards"
		:title="t('rewardCenter.invite.title')"
		buttonIcon="22/copy"
		image="/nuxt-img/rewards/invite.png"
		@click="handleClick"
	>
		<AText :size="12" class="text-cadiz prize-info" :modifiers="['nowrap']" as="div">
			{{ t("rewardCenter.invite.text") }}
			<MPrizeFund v-if="data?.data?.prizeCoins" variant="coins" img="/nuxt-img/prizes/rabbitcoins.png" :icon-size="20">
				<AText :modifiers="['semibold', 'uppercase']">
					{{ shortNumberFormatter(Number(data.data.prizeCoins), { withPrefix: true }) }}
				</AText>
			</MPrizeFund>
			<MPrizeFund
				v-if="data?.data?.prizeEntries"
				variant="entries"
				img="/nuxt-img/prizes/supercoins@2x.png"
				:icon-size="20"
			>
				<AText :modifiers="['semibold', 'uppercase']">
					{{ shortNumberFormatter(Number(data.data.prizeEntries), { fractionDigits: 2 }) }}
				</AText>
			</MPrizeFund>
		</AText>
	</MRewardsWrapper>
</template>

<style lang="scss" scoped>
.invite-rewards {
	.prize-info {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 4px;
	}

	&:deep(.prize) {
		gap: 4px;
	}
}
</style>
