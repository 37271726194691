<script setup lang="ts">
const emit = defineEmits<{ (event: "closePopper"): void }>();

const { open } = useAppModals();

const { isAvailable, durationLeft } = useDailyLogin({ formatTimer: "HH[h] mm[m] ss[s]" });

const introText = computed(() => (isAvailable.value ? "Get awarded every day!" : "Next reward unlocks in:"));

const handleClickReward = () => {
	if (!isAvailable.value) {
		return;
	}
	open("LazyOModalDailyLogin");
	emit("closePopper");
};
</script>

<template>
	<MRewardsWrapper
		:button-text="'Claim'"
		:title="'Daily Login Rewards'"
		:buttonDisabled="!isAvailable"
		:intro="introText"
		image="/nuxt-img/rewards/daily-login.png"
		@click="handleClickReward"
	>
		<template #timeBox>
			<i18n-t v-if="isAvailable" keypath="rewardCenter.dailyLogin.timer1">
				<template #timer>
					{{ durationLeft }}
				</template>
			</i18n-t>
			<span v-else>
				{{ durationLeft }}
			</span>
		</template>
	</MRewardsWrapper>
</template>

<style lang="scss" scoped></style>
