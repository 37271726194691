import type { ExtractFromAPI, v1 } from "@netgame/openapi";

import useEvent from "./useEvent";

type ProfilePageResponse = Required<ExtractFromAPI<v1.paths, "/rest/page/profile/", "get">>;

const useAccountVerification = () => {
	const route = useRoute();
	const { data: pageProfileData } = useNuxtData<ProfilePageResponse>("/rest/page/profile/");

	const isCloseVerify = ref(false);
	const showSumsubVerifyWidget = ref(false);
	const showSumsubInfo = ref(true);
	const verificationStatus = ref("");

	const isAvailableSumsubVerify = computed(() => {
		if (!pageProfileData.value?.payload?.autoVerificationData) {
			return false;
		}

		return (
			pageProfileData.value?.payload?.autoVerificationData.isEnabled &&
			pageProfileData.value?.payload?.autoVerificationData.isAvailable &&
			pageProfileData.value?.payload?.autoVerificationData.showOn?.includes("profile")
		);
	});
	const isTestMode = computed(() => pageProfileData.value?.payload?.autoVerificationData?.isTest);

	const updateVerificationStatus = (status: "rejected" | "approved") => {
		verificationStatus.value = status;
		dispatchQuasarEvent("quasar:setPlayerVerificationStatus", { detail: { status } });
		if (pageProfileData.value) {
			pageProfileData.value.payload.documentConfirmStatus = status === "approved" ? "verified" : status;
		}
	};

	useEvent(["SHOW_FAILED_VERIFY"], () => {
		updateVerificationStatus("rejected");
	});
	useEvent(["SHOW_SUCCESS_VERIFY"], () => {
		updateVerificationStatus("approved");
	});

	watch(
		() => route.hash,
		() => {
			if (route.hash.includes("verify")) {
				showSumsubVerifyWidget.value = true;
			}
		},
		{ immediate: true }
	);

	return {
		isCloseVerify,
		showSumsubVerifyWidget,
		showSumsubInfo,
		verificationStatus,
		isTestMode,
		isAvailableSumsubVerify,
		pageProfileData,
		updateVerificationStatus
	};
};

export default useAccountVerification;
